import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "global/context";
import { Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import toast from "react-hot-toast";
import { getDateSectionConfigFromFormatToken } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { Popup } from "layout/Popup";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  fontWeight: "bold",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
}));

const Invitation = () => {
  const { api } = useGlobalContext();
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      email: params.get("email"),
      department_id: params.get("department_id"),
    };
  };

  const { email, department_id } = getQueryParams(location.search);

  useEffect(() => {
    if (!email || !department_id) {
      navigate("/"); // Redirect to home page or any other page
    }
  }, [email, department_id, navigate]);
  const handleAccept = async () => {
    try {
      const response = await api.get(`/accept-invite`, {
        params: {
          email: email,
          department_id: department_id,
          token: token,
        },
      });
      const id = response.data.userId;
      navigate(`/profile/`, { state: { id } });
      Popup("success", response.data.message);
    } catch (err) {
      if (err.response.status == 403) {
        console.log(err);
        Popup("error", err.response.data.message);
        return;
      }
      Popup("error", "Error accepting invitation");
    }
  };

  const handleReject = async () => {
    try {
      const response = await api.get(`/reject-invite`, {
        params: {
          email: email,
          department_id: department_id,
          token: token,
        },
      });
      navigate('/')
      Popup("success", response.data);
    } catch (error) {
      if (error.response.status == 503) {
        Popup("error", error.response.data);
        return;
      }
      Popup("error", "Failed to reject invitation.");
    }
  };

  return (
    <CenteredBox>
      <Typography variant="h4" gutterBottom>
        Accept or Reject Invitation
      </Typography>
      <Typography variant="body1" paragraph>
        Do you accept this invitation?
      </Typography>
      <ActionButton variant="contained" color="primary" onClick={handleAccept}>
        Accept
      </ActionButton>
      <ActionButton variant="contained" color="error" onClick={handleReject}>
        Reject
      </ActionButton>
    </CenteredBox>
  );
};

export default Invitation;
