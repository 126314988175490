import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import JobCard from '../../Careers/components/JobCard'
import { useGlobalContext } from 'global/context';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';



const JobsSection = ({id}) => {
    const { api } = useGlobalContext();
    const [jobs, setJobs] = useState([]);

    const sliderLeft = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft -400
    }

    const sliderRight = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft + 400
    }

    const getJobs = async () => {
        try {
            const res = await api.get(`/app/jobs/totalJobs`);
            if (res?.status === 200) {
                // console.log("Job was successfully Fetchech: ", res?.data?.results);
                setJobs(res?.data?.results);
            }
        } catch (err) {
            console.log("Error getting jobs", err);
        }
    }

    useEffect(() => {
        getJobs();
    }, [api]);

   

    return (
        <>
            <section className='container mb-5 py-5'>
                <div className='fw-bold mb-4 d-flex flex-wrap justify-content-between'>
                    <div>
                        <h3 className='fw-bold mb-2'>Jobs</h3>
                        <h5>Premium jobs on your fingertips</h5>
                    </div>
                    <Link to='/careers'>View All Jobs</Link>
                </div>
                {/* <div className="col relative flex items-center">   */}
                <div className="relative flex items-center">  
                <MdChevronLeft className="" onClick={sliderLeft} size={40}/>
                <div id="slider" className="w-full h-full overflow-x-scroll overflow-y-hidden scroll whitespace-nowrap scroll-smooth scrollbar-hide">
                 {jobs?.map((job, index) => (
                <div className="w-[280px] inline-block p-2 hover:scale-105 ease-in-out duration-300 " key={job.id}>
                  <JobCard jd={job} />
                </div>
                ))}
                </div>
                <MdChevronRight onClick={sliderRight} size={40} />
                </div>    
            </section>
            
        </>
    )
}

export default JobsSection;