import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Button,
  InputBase,
  Paper,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { useGlobalContext } from "global/context";
import JobsData from "./Components/JobsData";
import Debounce from "pages/utilities/Debouncing";

const fontFamily = "'Quicksand', sans-serif";

const Jobs = React.memo(() => {
  const { api, userData } = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [search, setSearch] = useState("");
  const getJobs = async () => {
    setJobLoading(true);
    try {
      const res = await api.get(`/app/jobs/post?instituteId=${userData?.id}`);
      if (res?.status === 200) {
        setJobs(res?.data?.results);
        setFilteredJobs(res?.data?.results); // Initially, filteredJobs is the same as jobs
      }
    } catch (err) {
      console.log("Error getting jobs", err);
    } finally {
      setJobLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  const handleSearch = useCallback(
    async (searchValue) => {
      setJobLoading(true);
      searchValue = searchValue.toLowerCase(); // Convert to lowercase once

      if (searchValue === "") {
        setFilteredJobs(jobs);
        return;
      }
      try {
        // Step 1: Fetch data from API
        const res = await api.get(
          `/app/jobs/institute/applications/${userData?.id}`
        );

        // Step 2: Filter job applications from API response based on search value
        const userFiltered = res.data.jobApplication.filter((job) => {
          const jobTitle = job?.jobTitle?.toLowerCase() || "";
          const firstName = job?.user?.first_name?.toLowerCase() || "";
          const lastName = job?.user?.last_name?.toLowerCase() || "";

          // Check if searchValue is included in job title, first name, or last name
          return (
            jobTitle.includes(searchValue) ||
            firstName.includes(searchValue) ||
            lastName.includes(searchValue)
          );
        });

        // Step 3: Combine filtered results
        const combinedFiltered = jobs
          .filter((job) => {
            const jobTitle = job.job_title.toLowerCase();
            return jobTitle.includes(searchValue);
          })
          .concat(userFiltered);
        // Step 4: Set filtered jobs
        setFilteredJobs(combinedFiltered);
      } catch (err) {
        console.log("Error filtering jobs", err);
      } finally {
        setJobLoading(false);
      }
    },
    [jobs, api, userData]
  );

  const debouncedSearch = useCallback(Debounce(handleSearch, 1000), [
    handleSearch,
  ]);

  useEffect(() => {
    if (search) {
      debouncedSearch(search);
    } else {
      setFilteredJobs(jobs); // Reset to original jobs if search is empty
    }
  }, [search, debouncedSearch, jobs]);

  const handleInputChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mb: 3,
          background: "linear-gradient(45deg, #472fcb, #00a4a5)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            color="white"
            fontFamily={fontFamily}
          >
            Jobs
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search jobs or name"
              sx={{
                flex: 1,
                p: 1,
                pl: 2,
                borderRadius: 2,
                backgroundColor: "#F5F5F5",
                border: "1px solid #E0E0E0",
                fontFamily: fontFamily,
              }}
              onChange={handleInputChange}
              value={search}
            />
          </Box>
          <Link to="/institutes/jobspost" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: 2,
                whiteSpace: "nowrap",
                backgroundColor: "#272932",
                fontFamily: fontFamily,
              }}
            >
              Add Jobs
            </Button>
          </Link>
        </Box>
      </Paper>
      {jobLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <JobsData
          jobs={filteredJobs}
          getJobs={getJobs}
          fontFamily={fontFamily}
          setJobLoading={setJobLoading}
        />
      )}
      {!jobLoading && filteredJobs.length === 0 && (
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          marginY={2}
          fontFamily={fontFamily}
        >
          No jobs found
        </Typography>
      )}
    </Box>
  );
});

export default Jobs;
