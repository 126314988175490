function Social({URL}) {

  const socialLinks = URL?.linkedin || URL?.facebook || URL?.github

    return (
      <>
      {
        socialLinks ? (<div>
      <div className="mb-2">
          {" "}
          <div className="p-3" style={{ width: "100%" }}>
            <h4 className="font-weight-bold">Social Links</h4>
            <div className="">
            {
              URL?.linkedin?(<div className="mb-2">
                <p>LinkedIn</p>
                <h6 style={{ wordWrap: "break-word" }}>
                  {URL?.linkedin}
                </h6>
              </div>):(null)
            }
              {
                URL?.facebook ? (
                  <div className="mb-2">
                <p>YouTube</p>
                <h6 style={{ wordWrap: "break-word" }}>
                 {URL?.youtube}
                </h6>
              </div>
                ) : (null)
              }
              {URL?.github ? (
                <div className="mb-2">
                <p>GitHub</p>
                <h6 style={{ wordWrap: "break-word" }}> 
                  {URL?.github}
                </h6>
              </div>
              ) : (null)}
            </div>
          </div>
        </div>
        <div className="border"></div>
        </div>):null}
      </>
    );
  }
  export default Social