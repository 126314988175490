import React, { useState } from "react";
import { Avatar } from "@mui/joy";
import { useGlobalContext } from "global/context";

const Chat = ({
  user,
  isOnline,
  onUserClick,
  messageCounts,
  markMessageAsRead,
  messagesHai,
}) => {
  const { userData } = useGlobalContext();
  const [isClicked, setIsClicked] = useState(false);

  const filteredMessages =
    messagesHai?.filter((message) => message.sender_id !== userData?.id) || [];

  const circleStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "15px",
    height: "15px",
    backgroundColor: "red",
    fontSize: "12px",
    marginBottom: "5px",
    color: "white",
  };

  const handleClick = () => {
    setIsClicked(true);
    onUserClick(user);

    if (filteredMessages.length > 0) {
      filteredMessages.forEach((message) => {
        if (!user.message) {
          user.message = {};
        }
        user.message.id = message.id;
        markMessageAsRead(message.id, userData.id + user.id);
      });
    }
  };
  // console.log(isOnline)
  return (
    <div
      className={`d-flex justify-content-between mb-1 p-2 ${isClicked ? "clicked" : ""
        }`}
      onClick={handleClick}
      style={{ transition: "background-color 0.3s ease", cursor: "pointer" }}
    >
      <div className="d-flex align-items-center overflow-hidden text-nowrap">
        <Avatar
          alt={user?.first_name + " " + user?.last_name}
          src={user?.avatar_url}
          className="mr-1"
          sx={{
            width: "45px",
            height: "45px",
          }}
        />
        <div className="p-1">
          <h6 className="fs-15">
            {user?.first_name + " " + user?.last_name} ({user?.id})
          </h6>
          <p className="m-0 fs-13">{user?.location}</p>
          <p className="overflow-hidden text-nowrap fs-12 text-secondary">
            {user?.first_name} <h6>{isOnline ? "Online" : "Offline"}</h6>
          </p>
        </div>
      </div>

      <div>
        <h6 className="fs-12 text-secondary text-nowrap mb-2">10:03 PM</h6>
        <div className="d-flex align-items-center">
          {messageCounts > 0 && (
            <span style={circleStyle}>{messageCounts}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
