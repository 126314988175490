import React, { useEffect } from "react";
import { Edit } from "@mui/icons-material";
import {
  AspectRatio,
  Box,
  Divider,
  IconButton,
  ListDivider,
  Typography,
} from "@mui/joy";
import moment from "moment";
import AddAwards from "pages/user/profile/components/Modals/AddAwards";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "global/context";

const AwardCard = ({ award,getAward }) => {
  const location = useLocation();
  const { userData } = useGlobalContext();
  const [openAddAward, setOpenAddAward] = useState(false);

  return (
    <>
      <div className="job-item  d-flex justify-content-between">
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            gap: 1,
            py: 1,
            overflow: "auto",
            width: "100%",
            scrollSnapType: "x mandatory",
            "& > *": {
              scrollSnapAlign: "center",
            },
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <AspectRatio ratio="1" sx={{ minWidth: 60 }}>
            <img
              src={`https://images.unsplash.com/photo-1685549925654-e86dcebdd21a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2081&q=80`}
              alt="img"
              className="rounded-3"
            />
          </AspectRatio>
          <Box sx={{ whiteSpace: "nowrap" }} className="w-100 ml-2">
            <div className="d-flex justify-content-between w-100">
              <div>
                <Typography fontWeight="lg">
                  {award?.award_name}
                </Typography>
                <Typography fontWeight="sm">
                  {award?.issuing_organization} <span> By </span>
                  {award?.issuer}
                </Typography>
              </div>
              
                <IconButton
                  variant="plain"
                  // sx={{position: 'absolute', right: '2.4rem'}}
                  sx={{
                    height: 30,
                    width: 30,
                  }}
                  onClick={() => {
                    setOpenAddAward(true);
                  }}
                >
                  <Edit />
                </IconButton>
             
            </div>
            <Typography level="body2">
              On {moment(award?.issue_date).format("MMM YYYY")}{" "}
            </Typography>
          </Box>
        </Box>
      </div>
      {/* <Divider/> */}
      <AddAwards
        open={openAddAward}
        setOpen={setOpenAddAward}
        edit={true}
        data={award}
        getAward={getAward}
      />
    </>
  );
};

export default AwardCard;