import React, { useState, useEffect } from "react";
import { useGlobalContext } from "global/context";
import { prepareCssVars } from "@mui/system";
import Chat from "./Chat";

function AllChats({ onUserClick, messageCounts, messagesHai }) {
  const { userData, api, socket, onlineUsers } = useGlobalContext();
  // const [users, setUsers] = useState([]);
  // const [filter, setFilter] = useState("");
  // const [filteredUsers, setFilteredUsers] = useState([]);
  const [connections, setConnections] = useState([]);
  const [messageNotifications, setMessageNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [currUser, setCurrUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [state, setState] = useState(true); //

  // console.log("Online : ", onlineUsers);
  // console.log("Connections : ", connections);

  const ye = connections.forEach((connection) => {
    const matchingUser = onlineUsers.find(
      (user) => user.username === connection.id
    );
    if (matchingUser) {
      matchingUser.isOnline = true;
    }
  });

  // console.log("Ye hain Online Users ", ye);

  const getConnections = async () => {
    try {
      const res = await api.get(`/app/connections?userId=${userData?.id}`);
      if (res?.status === 200) {
        // console.log("My Connections : ", res?.data);
        setConnections(res?.data?.connections);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const getUsers = async () => {
  //   try {
  //     const res = await api.get(`app/user?sortBy=name`);
  //     if (res?.status === 200) {
  //       console.log('User successfully fetched: ', res);
  //       setUsers(res?.data?.users);
  //       setFilteredUsers(res?.data?.users);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const getMessageNotifications = async (room) => {
    try {
      const res = await api.get(`/app/chat/history?room=${room}`);
      if (res?.status === 200) {
        const filteredMessages =
          res?.data?.history?.filter(
            (message) => message.sender_id !== userData?.id
          ) || [];
        //  console.log("Filter Message : ",filteredMessages)
        setMessageNotifications((prevState) => ({
          ...prevState,
          [room]: filteredMessages,
        }));
        setUnreadCount(res?.data?.unread);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const markMessageAsRead = async (messageId, roomId) => {
    try {
      const response = await api.post(`/app/chat/history/${messageId}`, {
        roomId,
      });
      // console.log('API Response:', response);
      setMessageNotifications((prevState) => {
        const updatedRoomMessages = prevState[roomId].map((msg) =>
          msg.id === messageId ? { ...msg, isRead: true } : msg
        );
        // console.log("Updated Room Messages:", updatedRoomMessages);
        return { ...prevState, [roomId]: updatedRoomMessages };
      });
    } catch (error) {
      console.error("Error marking message as read", error);
    }
  };

  // useEffect(() => {
  //   getUsers();
  // }, [])

  // useEffect(() => {
  //   if (filter) {
  //     setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(filter.toLowerCase())));
  //   } else {
  //     setFilteredUsers(users);
  //   }
  // }, [filter, users]);

  useEffect(() => {
    if (userData?.id) {
      getConnections();
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.id !== undefined) {
      connections.forEach((conn) => {
        const room = `${userData.id + conn.id}`;
        getMessageNotifications(room);
      });
    }
  }, [userData?.id, connections]);

  useEffect(() => {
    if (socket) {
      socket.on("get-notification", (data) => {
        const room = `${userData.id + data.senderId}`;
        getMessageNotifications(room);
      });
    }
    return () => {
      socket?.off("get-notification");
    };
  }, [socket, userData.id]);

  useEffect(() => {
    socket?.on("receive-message", (data) => {
      // console.log("Received message11: ", data);
      setCurrUser(data?.sender_id);
      setMessages((prevMessages) => [...prevMessages, data]);
      setMessageNotifications((prevState) => ({
        ...prevState, // Spread the previous state
        [data?.room]: prevState[data?.room]
          ? [...prevState[data.room], data]
          : [data], // Ensure the room exists
      }));
      // console.log("Message : ", messages);
      if (!showMessage) {
        setShowMessage(true);
        setState(false);
      }
    });
    return () => {
      socket?.off("receive-message");
    };
  }, [socket, messages, showMessage, messageCounts]);

  const updateMessageNotification = (data) => {
    setMessageNotifications((prevState) => ({
      ...prevState, // Spread the previous state
      [data?.room]: prevState[data?.room]
        ? [...prevState[data.room], data]
        : [data], // Ensure the room exists
    }));
  };

  // const isOnline = onlineUsers.some(onlineUser => onlineUser.username === user.id);

  return (
    <div>
      <div
        className="p-2 scroll-minibar"
        style={{ overflow: "auto", overflowX: "hidden", maxHeight: "70vh" }}
      >
        {connections?.map((user) => {
          const room = `${userData.id + user.id}`;
          const messages = messageNotifications[room] || [];
          const messageCount = messages.filter((msg) => !msg.isRead).length;

          // Check if the user is online
          const isOnline = onlineUsers.some(
            (onlineUser) => onlineUser.username === user.id
          );
          return (
            <Chat
              key={user?.id}
              user={user}
              isOnline={isOnline}
              messagesHai={messagesHai}
              onUserClick={onUserClick}
              messageCounts={messageCount}
              markMessageAsRead={markMessageAsRead}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AllChats;