import React, { useEffect, useState } from "react";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReplyModal from "./ReplyModal";

export const UserDetails = () => {
  const navigate = useNavigate();
  const locate = useLocation();
  const { api } = useGlobalContext();
  const id = locate?.state?.id;
  const name = locate?.state?.user?.firstname + locate?.state?.user?.lastname;
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/app/institutes/alluserdetails/${id}`);
        console.log(res.data);
        setData(res.data);
      } catch (err) {
        toast.error("Error getting information");
      }
    };

    fetchData();
  }, [id, api]);
  const handleUserModal = (user) => {
    setOpenModal(true);
    setCurrentUser(user);
  };

  return (
    <Box
      p={2}
      sx={{
        height: "650px",
        overflowY: "scroll",
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Box display="flex" justifyContent="start" alignItems="center" m={1}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: 1,
            fontSize: 22,
            color: "black",
          }}
        >
          {name}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#09dbd4" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Subject</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                Description & Reply
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>CreatedAt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((user) => (
                <TableRow key={user.id}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {user.title}
                  </TableCell>
                  <TableCell>
                    <Chip label="view" onClick={() => handleUserModal(user)} />
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {user.createdAt?.slice(0,10)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ReplyModal
        openModal={OpenModal}
        setOpenModal={setOpenModal}
        user={currentUser}
      />
    </Box>
  );
};
