import React, { useState } from "react";
import {
  Modal,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast"; // Added the toast import
import { useGlobalContext } from "global/context"; // Added context import

const ReplyModal = ({ openModal, setOpenModal, user }) => {
  const [reply, setReply] = useState("");
  const { api,userData } = useGlobalContext(); // Get API from global context

  const handleReplyChange = (event) => {
    setReply(event.target.value);
  };

  const handleReply = async () => {
    try {
      await api.post("/app/institutes/reply", {
        userId: user?.user_id,
        description: user?.description,
        instituteId:userData?.id,
        institute:userData?.name,
        reply,
      });
      toast.success("Reply sent successfully");
      setReply("");
      setOpenModal(false); // Close the modal after successful reply
    } catch (error) {
      toast.error("Failed to send reply");
    }
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "80%", sm: "50%", md: "40%" },
          margin: "auto",
          marginTop: "10%",
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ mb: 2, textAlign: "center" }}
        >
          Reply to {user?.name || "User"}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 2, textAlign: "center" }}
        >
          {user?.description}
        </Typography>
        <TextField
          value={reply}
          onChange={handleReplyChange}
          label="Your Reply"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          sx={{
            mb: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3f51b5",
              },
              "&:hover fieldset": {
                borderColor: "#3f51b5",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
              },
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleReply}
          sx={{
            backgroundColor: "#3f51b5",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          Send Reply
        </Button>
      </Box>
    </Modal>
  );
};

export default ReplyModal;
