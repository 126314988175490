import React from "react";
import CareersDashboard from "./CareersDashboard";
import { Divider } from "@mui/joy";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";


function CareersParent() {
  const ScrollerStyle = {
    maxHeight: "840px",
    width: "100%",
    overflowY: "scroll",
    justifyContent: "flex-end",
    // marginTop: "140px",
  };

  const ScrollHiddenDiv = styled.div`
    .scroll-bar-hidden::-webkit-scrollbar {
      display: none;
    }
  `;

  const ScrollMinibarDiv = styled.div`
    .scroll-minibar::-webkit-scrollbar {
      width: 3px;
      background-color: black;
    }

    .scroll-minibar::-webkit-scrollbar-thumb {
      background-color: rgb(185, 182, 182) !important;
    }
  `;
  return (
    <div className="container-fluid">
      <div className="d-flex  ">
        <div className="col col-md-9  col-12 scroll-minibar" style={ScrollerStyle}>
          <Outlet />
        </div>
        {/* <Divider orientation="vertical" variant="middle" color="primary" /> */}
        <div className="col  col-lg-4 h-100">
          <div className=" d-none d-lg-block">
            <CareersDashboard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareersParent;
