import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Avatar,
  TablePagination,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import { useGlobalContext } from "global/context";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { Block, BusinessCenter, CheckCircle, HourglassEmpty, MoreVert, Verified, VerifiedUser } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";

const fontFamily = "'poppins', sans-serif";

const StyledTableContainer = styled(TableContainer)`
  border-radius: 8px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  font-family: ${fontFamily};
`;

const StyledTableHead = styled(TableHead)`
  background: #bdbdbd;
  font-family: ${fontFamily};
`;

const StyledTableRow = styled(TableRow)`
  transition: all 0.5s ease;
  font-weight: semi-bold;
  font-family: ${fontFamily};
  &:nth-of-type(odd) {
    background-color: #rgba(0, 0, 0, 0.1);
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 0.4rem;
  font-family: ${fontFamily};
`;

const StyledLoader = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const InstituteData = () => {
  const { api } = useGlobalContext();
  const navigate=useNavigate()
  const [institutes, setInstitutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentInstitute, setCurrentInstitute] = useState(null);
  const [formState, setFormState] = useState({
    id: "",
    name: "",
    location: "",
    status: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [instituteToDelete, setInstituteToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    try {
      const res = await api.get("/admin/institutes");
      setInstitutes(res.data.result);
    } catch (error) {
      if (error.response.status === 409) {
        Popup("error", "Access denied");
        navigate("/");
        return;
      }
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const handleOptions = (event,institute) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  const handleOpenDialog = (institute = null) => {
    setCurrentInstitute(institute);
    if (institute) {
      setFormState(institute);
    } else {
      setFormState({ id: "", name: "", location: "", status: "" });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentInstitute(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleDeleteConfirmation = (institute) => {
    setInstituteToDelete(institute);
    setDeleteModalOpen(true);
  };

  const handleFormSubmit = async () => {
    try {
      if (currentInstitute) {
        // Update institute
        await api.put(
          `/admin/institutes/edit/${currentInstitute.id}`,
          formState
        );
        toast.success("Institute updated successfully");
      } else {
        // Add new institute
        await api.post("/admin/institutes", formState);
        toast.success("Institute added successfully");
      }
      fetchInstitutes();
      handleCloseDialog();
    } catch (error) {
      toast.error("Error saving institute");
    }
  };

  const handleDeleteInstitute = async (id) => {
    try {
      await api.delete(`/admin/institutes/delete/${id}`);
      fetchInstitutes();
      toast.success("Institute deleted successfully");
    } catch (error) {
      toast.error("Error deleting institute");
    }
  };

  // Pagination Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function getStatusColor(status) {
    switch (status) {
      case "Active":
        return "primary";
      case "Verification":
        return "info";
      case "Onboarding":
        return "warning";
      default:
        return "default";
    }
  }

  function getStatusIcon(status) {
    switch (status) {
      case "Active":
        return <CheckCircle />;
      case "Verification":
        return <VerifiedUser />;
      case "Onboarding":
        return <HourglassEmpty />;
      default:
        return null;
    }
  }

  return (
    <Box sx={{ backgroundColor: "#fff", padding: 2, borderRadius: 1.5 }}>
      <Box display="flex" justifyContent="end">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{ fontFamily: `${fontFamily}` }}
        >
          Add Institute
        </Button>
      </Box>
      {loading ? (
        <StyledLoader>
          <CircularProgress />
        </StyledLoader>
      ) : (
        <StyledTableContainer component={Paper}>
          <Table aria-label="institutes table">
            <StyledTableHead>
              <TableRow>
                <StyledTableCell
                  style={{ color: "#fff", padding: 26 }}
                ></StyledTableCell>
                <StyledTableCell style={{ color: "#000", fontWeight: "800" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell style={{ color: "#000", fontWeight: "800" }}>
                  Email
                </StyledTableCell>
                <StyledTableCell style={{ color: "#000", fontWeight: "800" }}>
                  Phone
                </StyledTableCell>
                <StyledTableCell style={{ color: "#000", fontWeight: "800" }}>
                  Status
                </StyledTableCell>
                <StyledTableCell style={{ color: "#000", fontWeight: "800" }}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {institutes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((institute, index) => (
                  <StyledTableRow key={institute?.id}>
                    <StyledTableCell>
                      <Avatar src={institute?.logo} sx={{ ml: 2 }} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/institutes/institute-profile/${institute?.id}`}
                      >
                        {institute?.name}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>{institute?.email}</StyledTableCell>
                    <StyledTableCell>{institute?.mobile}</StyledTableCell>
                    <StyledTableCell>
                      <Chip
                        label={institute?.status}
                        color={getStatusColor(institute?.status)}
                        icon={getStatusIcon(institute?.status)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleOptions(e,institute)}>
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenDialog(institute)}
                          >
                            <EditIcon />
                          </IconButton>
                        </MenuItem>
                        <MenuItem>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteConfirmation(institute)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={institutes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {currentInstitute ? "Edit Institute" : "Add Institute"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formState.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            value={formState.location}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="status"
            label="Status"
            type="text"
            fullWidth
            value={formState.status}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {currentInstitute ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this institute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteModalOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteInstitute(instituteToDelete.id);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InstituteData;
