import React, {useState} from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Swal from 'sweetalert2';
import Select from 'react-select'
import styled from 'styled-components';
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import {
  Checkbox,
  Divider,
  FormHelperText,
  ModalClose,
  educations,
  Option,
  Textarea
} from "@mui/joy";
import "./Modal.css";
// import Select, { selectClasses } from "@mui/joy/Select";
import { useFormik } from "formik";
import { FormGroup } from "@mui/material";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import * as Yup from "yup";


const MINIMUM_EDUCATION_GAP_MONTHS = 3;

const validationSchema = Yup.object().shape({
  institution_name: Yup.string()
    .required("School is required"),
  degree: Yup.string()
    .required("Degree is required"),
  field_of_study: Yup.string()
    .required("Field Of Study is required"),
  description: Yup.string()
    .required("Describe it."),
  grade: Yup.string()
    .required("Grade is required"),
  start_date: Yup.date()
    .required("Start date is required"),
    // .max(Yup.ref("end_date"), "Start date must be Before End date"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date must be after the start date")
    .max(new Date(), "End date must be today or before")
    .test(
      'min-gap',
      `The education period must be at least ${MINIMUM_EDUCATION_GAP_MONTHS} months long`,
      function (value) {
        const { start_date } = this.parent;
        if (!start_date || !value) {
          return true; // Skip this test if there are no dates set yet
        }
        const startDate = new Date(start_date);
        const endDate = new Date(value);

        // Calculate the difference in months
        const monthDifference =
          endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear());

        return monthDifference >= MINIMUM_EDUCATION_GAP_MONTHS;
      }
    ),
});

export default function AddEducation({
  open = false,
  setOpen,
  action,
  edit = false,
  data,
  educations,
  getEducations
}) {
  const [isTillPresent, setIsTillPresent] = React.useState(false);
  const [updatedData, setUpdatedData] = useState()
  const [filteredEducations, setFilteredEducations] = useState(educations);

  // console.log(edit)

  const { userData, api } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      institution_name: data?.institution_name || "",
      description: data?.description || "",
      degree: data?.degree || "",
      field_of_study: data?.field_of_study || "",
      grade: data?.grade || "",
      start_date: data?.start_date || new Date().getDate(),
      end_date: data?.end_date || new Date().getDate(),
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("Clicked")
      values = { ...values, user_id: userData?.id };
      let notify = toast.loading("Adding education...");
      console.log("Edit mode:", edit); // Log to check edit mode

      try {
        if (edit) {
          console.log("Attempting to update education");
          const res = await api.put(
            `/app/candidates/educations/${data?.id}`,
            values
          );
          console.log("PUT response:", res);
          if (res?.status === 200) {
            console.log("Update successful");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Updated Successfully");
            console.log("Calling getEducations after update");
            getEducations();
          }
        } else {
          console.log("Attempting to add new education");
          const res = await api.post(
            `/app/candidates/educations/${userData?.id}`,
            values
          );
          console.log("POST response:", res); // Log to check POST response
          if (res?.status === 201) {
            console.log("Successfully Added");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Successfully Added");
            console.log("Error is find here");
            formik.resetForm(); // Reset form values
            getEducations();
          }
        }
      } catch (error) {
        console.log("Error is find here");

        console.log("Error related ================> : ",error);

        toast.dismiss(notify);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    },
    
  });
  
  const handleDelete = async (id, institutionName) => {
    setOpen(false)

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then ( async(result) => {
        if (result.isConfirmed) {
          try {

            const res = await api.delete(`/app/candidates/educations/${id}`);
            if (res?.status === 200) {

              toast.success(`${institutionName} successfully deleted!`);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              getEducations();
        }
          } catch (error) {
            console.error("Error In Deletion : ",error)
          }
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to delete");
    }
  };

  useEffect(() => {
    setFilteredEducations(educations);
  }, [educations]);

  const selectDegree = [
    { value : 'under_graduate' , label : 'Under Graduate' },
    { value : 'graduate' , label : 'Graduate' },
    { value : 'post_graduate' , label : 'Post Graduate' },
    { value : 'certificate_course' , label : 'Certificate Course' },
    { value : 'deploma_course' , label : 'Deploma Course' },
  ]

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',  // IE and Edge
      'scrollbar-width': 'none',  // Firefox
    }),
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-institution_name"
          aria-describedby="basic-modal-dialog-description"
          layout="center"
          // sx={{ maxWidth: 500 }}
          // variant="plain"
        >
          <ModalClose />
          <div className="px-3 my-3">
            <Typography
              id="basic-modal-dialog-institution_name"
              component="h2"
              fontWeight={"lg"}
            >
              {edit ? "Edit education" : "Add new education"}
            </Typography>
            <Typography
              id="basic-modal-dialog-description"
              textColor="text.tertiary"
              level="body3"
            >
              Share your education on this profile
            </Typography>
          </div>
          <FormGroup onSubmit={formik.handleSubmit}
          className="scroll-bar-hidden"
            sx={{
              overflow: "scroll",
              mx: "calc(-1 * var(--ModalDialog-padding))",
              px: "var(--ModalDialog-padding)",
            }}>
            <Stack spacing={2}>
              <div className="row px-3">
                <FormControl className="col">
                  <FormLabel>School*</FormLabel>
                  <Input
                    autoFocus
                    required
                    placeholder="Eg - ABC University"
                    name="institution_name"
                    value={formik.values.institution_name}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.institution_name && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.institution_name &&
                        formik.errors.institution_name}
                  </FormHelperText>
                )}
                </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
              <FormControl className="col">
                <FormLabel>Degree*</FormLabel>
                <Select
                  name="degree"
                  options={selectDegree}
                  value={selectDegree.find(option => option.value === formik.values.degree)}
                  onChange={option => formik.setFieldValue("degree", option.value)}
                  onBlur={formik.handleBlur}
                  styles={customStyles}
                />
                {formik.errors.degree && formik.touched.degree && (
                  <FormHelperText error>{formik.errors.degree}</FormHelperText>
                )}
                </FormControl>
                </div>
                <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Field of study*</FormLabel>
                  <Input
                    required
                    placeholder="Eg - Computer Science"
                    name="field_of_study"
                    value={formik.values.field_of_study}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.field_of_study && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.field_of_study &&
                          formik.errors.field_of_study}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>

              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Start date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                  />
                  <div className="ml-3">
                  {formik.errors.start_date && (
                    <FormHelperText error className="text-danger">
                      {formik.touched.start_date && formik.errors.start_date}
                    </FormHelperText>
                  )}
                  </div>
                </FormControl>
                <FormControl className="col">
                  <FormLabel>End date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                  />
                  <div className="ml-3">
                  {formik.errors.end_date && (
                    <FormHelperText error className="text-danger">
                      {formik.touched.end_date && formik.errors.end_date}
                    </FormHelperText>
                  )}
                  </div>
                </FormControl>
              </div>

              <FormControl className="row">
                <div className="col">
                  <FormLabel>Description*</FormLabel>
                  <Textarea
                    minRows={3}
                    placeholder="Describe your responsibilities in concise statements led by strong verbs"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.description && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.description &&
                          formik.errors.description}
                      </FormHelperText>
                    )}
                  </div>
                  {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                </div>
              </FormControl>

              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Grade*</FormLabel>
                  <Input
                    required
                    placeholder="A+"
                    name="grade"
                    value={formik.values.grade}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.grade && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.grade &&
                          formik.errors.grade}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              </Stack>
          </FormGroup>
              <div className="row g-2 d-flex p-3 justify-content-between">
              {/* {edit && ( <Button > Delete </Button> )} */}
              {edit && (
                        <Button onClick={() => handleDelete(data?.id, data?.institution_name)}>Delete</Button>
                    )}
                <Button
                  type="submit"
                  onClick={()=>{
                  formik.handleSubmit()
                  console.log("clicked", formik.errors)
                  }
                  }
                >
                  {edit ? "Save changes" : "Add Education"}
                </Button>
              </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
