import React, { useState, createContext } from "react";
import { Outlet } from "react-router-dom";
import Adminsidebar from "pages/static/Admin/Components/AdminSidebar";

const AdminContext = createContext();

const fontFamily = "'Quicksand', sans-serif";
const Admin = () => {
  return (
      <AdminContext.Provider value={{fontFamily}}>
        <div className="admin-container d-flex">
          <div className="admin-layout">
            <Adminsidebar />
          </div>
            <div className="admin-content flex-grow-1 p-md-4" style={{backgroundColor:"#edede9",borderRadius:2}}>
              <Outlet />
            </div>
        </div>
      </AdminContext.Provider>
  );
};

export default Admin;
export { AdminContext };
