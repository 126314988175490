import React from "react";
import { useEffect } from  "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <>
      <div className="container">
      <div className="p-2">
        <h1 className="mb-4 font-weight-bold">Privacy Policy</h1>
        <h6>Last updated: July 1, 2023</h6>
       <p className="mb-2">
          Welcome to Eksathi, a question-and-answer website similar to Quora. At
          Eksathi, we are committed to protecting your privacy and safeguarding
          your personal information. This Privacy Policy explains how we
          collect, use, and disclose information when you use our website. By
          using Eksathi, you agree to the terms described in this Privacy
          Policy.
        </p>

        <h3 className="mb-2 font-weight-bold">Information We Collect:</h3>
       <p className="mb-2">
          <strong className="text-dark"> 1.1. Personal Information: </strong> <br /> When you create
          an account on Eksathi, we may collect personal information such as
          your name, email address, and profile picture. This information is
          necessary to provide you with access to our services and to customize
          your experience on the website.
        </p>
       <p className="mb-2">
          <strong className="text-dark">1.2. User Content: </strong> <br /> As a Q&A platform, Eksathi
          collects the questions, answers, comments, and other content you
          contribute to the website. This user-generated content is publicly
          available on our platform, and it may be viewed and used by other
          users.
        </p>
       <p className="mb-3">
          <strong className="text-dark">1.3. Usage Information:</strong> <br /> We automatically
          collect certain information about your use of Eksathi, including your
          IP address, browser type, operating system, and device information.
          This information helps us understand how you interact with our
          website, improve our services, and detect any misuse or abuse.
        </p>
        <h3 className="mb-2 font-weight-bold">Use of Information:</h3>
       <p className="mb-2">
          <strong className="text-dark">2.1. Providing and Improving Services:</strong> <br /> We use
          your personal information to provide you with access to Eksathi and
          its features. This includes facilitating communication between users,
          delivering personalized content, and enhancing the overall user
          experience. We may also analyze user behavior and feedback to improve
          our services and develop new features.
        </p>
       <p className="mb-2">
          <strong className="text-dark">2.2. User Content:</strong> <br /> The questions, answers, and
          comments you contribute to Eksathi are publicly visible and may be
          shared with other users. Please be cautious and avoid sharing any
          personal, sensitive, or confidential information that you do not want
          to make publicly available.
        </p>
       <p className="mb-2">
          <strong className="text-dark">2.3. Communications:</strong> <br /> We may use your email
          address to send you important notifications, updates, and
          announcements related to Eksathi. These communications are necessary
          to inform you about changes to our services, address user inquiries,
          and provide you with relevant information.
        </p>
       <p className="mb-3">
          <strong className="text-dark">2.4. Aggregated Data:</strong> <br />
          We may anonymize and aggregate your information with other users' data
          to generate statistical insights and analyze trends. This aggregated
          data is used for research, analytics, and reporting purposes while
          ensuring individual users cannot be identified.
        </p>
        <h3 className="mb-2 font-weight-bold">Data Sharing and Disclosure:</h3>
       <p className="mb-2">
          <strong className="text-dark">3.1. Service Providers:</strong> <br />
          We may share your personal information with trusted third-party
          service providers who assist us in operating Eksathi, including
          hosting services, data analysis, customer support, and marketing.
          These service providers are contractually bound to handle your
          information securely and in accordance with this Privacy Policy.
        </p>
       <p className="mb-2">
          <strong className="text-dark">3.2. Legal Compliance:</strong> <br />
          We may disclose your information if required by law, regulation, or
          legal process, or if we believe that such disclosure is necessary to
          protect our rights, enforce our Terms of Service, or ensure the safety
          of our users.
        </p>
       <p className="mb-3">
          <strong className="text-dark">3.3. Change of Ownership:</strong> <br />
          In the event of a merger, acquisition, or sale of all or a portion of
          our assets, your information may be transferred to the acquiring
          entity. We will notify you of any change in ownership and any choices
          you may have regarding your personal information.
        </p>
        <h3 className="mb-2 font-weight-bold">Data Security:</h3>
       <p className="mb-3">
          We take appropriate technical and organizational measures to protect
          your personal information against unauthorized access, loss, or
          alteration. However, please note that no method of transmission over
          the internet or electronic storage is completely secure. While we
          strive to protect your data, we cannot guarantee its absolute
          security.
        </p>
        <h3 className="mb-2 font-weight-bold">Your Choices:</h3>
       <p className="mb-2">
          <strong className="text-dark">5.1. Account Information:</strong> <br />
          You can access and update your account information by logging into
          your Eksathi account. You may also delete your account, which will
          permanently remove your personal information and user-generated
          content from our system, subject to certain legal obligations and
          technical constraints.
        </p>
       <p className="mb-3">
          <strong className="text-dark">5.2. Cookies:</strong> <br />
          Eksathi uses cookies and similar technologies to enhance your browsing
          experience. You have the option to disable cookies through your
          browser settings; however, this may limit certain features and
          functionality of our website.
        </p>
        <h3 className="mb-2 font-weight-bold">Children's Privacy:</h3>
       <p className="mb-3">
          Eksathi is not intended for children under the age of 13. We do not
          knowingly collect personal information from individuals under 13 years
          of age. If you believe we have inadvertently collected information
          from a child, please contact us, and we will take steps to remove the
          information promptly.
        </p>
        <h3 className="mb-2 font-weight-bold">Changes to this Privacy Policy:</h3>
       <p className="mb-3">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal obligations. We will notify you of any
          material changes by posting the revised policy on our website or
          through other communication channels. Your continued use of Eksathi
          after such changes constitutes your acceptance of the updated Privacy
          Policy.
        </p>
        <h3 className="mb-2 font-weight-bold">Contact Us:</h3>
       <p className="mb-3">
          If you have any questions, concerns, or suggestions regarding this
          Privacy Policy or our data practices, please contact us at [insert
          contact information]. Thank you for trusting Eksathi. We are committed
          to protecting your privacy and providing a safe and valuable platform
          for knowledge sharing and engagement.
        </p>
      </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
