import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import Checkbox from "@mui/joy/Checkbox";
import PaymentsIcon from "@mui/icons-material/Payments";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import RadioGroup from "@mui/joy/RadioGroup";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Apartment from "@mui/icons-material/Apartment";
import Radio from "@mui/joy/Radio";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/joy/Box";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as Yup from "yup";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { selectClasses } from "@mui/joy/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Typography,
  createFilterOptions,
} from "@mui/joy";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";
import { Link, useNavigate } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { FormHelperText, MenuItem } from "@mui/material";
import { formatISO, parseISO, set } from "date-fns";

const validationSchema = Yup.object({
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date should be greater than start date"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
});
function Updatejob() {
  const locate = useLocation();
  const [jobData, setJobData] = useState(locate?.state);
  const { api, userData } = useGlobalContext();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [textQ, setTextQ] = useState("");
  const [chips, setChips] = useState([]);
  const [Experience, setExperience] = useState(jobData?.experience || "");
  const [qualifications, setQualifications] = useState({
    degree: "",
    subject: "",
    year: "",
  });

  const [employmentType, setEmploymentType] = useState([]);

  const [jobDescription, setJobDescription] = useState();

  const [jobCategory, setJobCategory] = useState(null);

  const [open, toggleOpen] = React.useState(false);
  const filter = createFilterOptions();
  useEffect(() => {
    if (locate.state) {
      setJobData(locate.state);
    }
  }, [locate.state]);
  //Autocomplete Box Component methods start
  const handleClose = () => {
    setDialogValue({
      name: "",
      description: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    description: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };
  const handleClick = () => {
    if (text.trim() !== "") {
      if (chips.includes(text)) {
        toast.error("same subject not allowed");
      } else {
        setChips((prevChips) => [...prevChips, text]);
        setText("");
      }
    }
  };
  useEffect(() => {
    if (jobData) {
      try {
        if (jobData?.subjects) {
          const parsedItems = JSON.parse(jobData.subjects); // Parse the JSON string into an array
          setChips(parsedItems);
        }
      } catch (error) {
        console.error("Error parsing jobData.subjects:", error);
      }

      try {
        if (jobData.minimum_qualification) {
          const min_qualify = JSON.parse(jobData.minimum_qualification);
          setQualifications({
            degree: min_qualify.degree,
            subject: min_qualify.subject,
            year: min_qualify.year,
          });
        }
      } catch (error) {
        console.error("Error parsing jobData.minimum_qualification:", error);
      }
    }
  }, [jobData]);
  console.log("This is jobData",jobData)
  const handleDelete = (chipToDelete) => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.slice(0, 10).split("-");
    return `${year}-${month}-${day}`;
  };
  // createddate format
  const formattedCreateDate = formatDate(jobData?.createdAt);
  // expirydate format
  const formattedExpiryDate = formatDate(jobData?.expiry_date);
  const formik = useFormik({
    initialValues: {
      job_title: jobData?.job_title || "",
      job_description: jobData?.job_description || "",
      employment_type: jobData?.employment_type || [],
      start_time: jobData?.work_schedule.start_time,
      end_time: jobData?.work_schedule.end_time,
      salary_range: jobData?.salary_range || "",
      is_negotiable: true,
      subjects: chips,
      minimum_qualification: qualifications,
      experience: jobData?.experience,
      job_location: jobData?.job_location || "",
      designation: jobData?.designation || "",
      vacancies: jobData?.vacancies || "",
      name: jobData?.name,
      phone: jobData?.phone,
      email: jobData?.email,
      start_date: formattedCreateDate,
      end_date: formattedExpiryDate,
      special_note: jobData?.special_note || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const requiredFields = [
        "job_title",
        "job_description",
        "employment_type",
        "start_time",
        "end_time",
        "salary_range",
        "minimum_qualification",
        "experience",
        "job_location",
        "designation",
        "vacancies",
        "name",
        "phone",
        "email",
        "start_date",
        "end_date",
      ];

      const emptyFields = requiredFields.filter(
        (field) =>
          !values[field] ||
          (Array.isArray(values[field]) && values[field].length === 0)
      );
      if (emptyFields.length > 0) {
        toast.error(
          `The following fields are required: ${emptyFields.join(", ")}`
        );
        return;
      }

      const adjustDate = (dateStr) => {
        const date = parseISO(dateStr);
        const adjustedDate = set(date, {
          hours: 12,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        });
        return formatISO(adjustedDate);
      };

      const updatedValues = {
        ...values,
        work_schedule: {
          start_time: values.start_time,
          end_time: values.end_time,
        },
        minimum_qualification: qualifications,
        subjects: chips,
        employment_type: values.employment_type,
        job_description: values.job_description,
        institute_id: userData?.id,
        category_id: jobCategory?.id || null,
        start_date: adjustDate(values.start_date),
        end_date: adjustDate(values.end_date),
      };
      try {
        const res = await api.put(`/app/jobs/put/${jobData.id}`, updatedValues);
        if (res?.status === 200) {
          console.log("Success: ", res);
          navigate("/institute/jobs/Applications/ApplicationDetail");
          toast.success("Job Posted Successfully");
        }
      } catch (err) {
        console.error(err);
        toast.error("Error while posting job");
      }
    },
  });
  return (
    <>
      <div>
        <div
          className="container mt-lg-2 mb-5 p-5 position-relative bg-light shadow rounded rounded-sm-0 "
          style={{
            backgroundImage: "linear-gradient(45deg, #472fcb, #00a4a5)",
          }}
        ></div>
        <Typography
          variant="h4"
          align="center"
          color="primary"
          fontWeight="bold"
        >
          Update a job
        </Typography>
        <div className="d-flex flex-column align-items-center justify-content-between flex-wrap mb-5">
          <div className="d-flex align-items-center">
            <Modal open={open} onClose={handleClose}>
              <ModalDialog>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <FormControl id="name">
                      <FormLabel>Name</FormLabel>
                      <Input
                        autoFocus
                        type="text"
                        value={dialogValue.name}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            name: event.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl id="description">
                      <FormLabel>Description</FormLabel>
                      <Input
                        type="text"
                        value={dialogValue.description}
                        onChange={(event) =>
                          setDialogValue({
                            ...dialogValue,
                            description: event.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button type="submit">Add</Button>
                    </Stack>
                  </Stack>
                </form>
              </ModalDialog>
            </Modal>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row row-cols-1 row-cols-lg-2 ">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Job Title</h5>
              <p className="font-weight-bold">
                A job title must describe one position only
              </p>
            </div>
            <div className="col">
              <Input
                placeholder="e.g. 'Software Developer'"
                name="job_title"
                value={formik.values.job_title}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Job description</h5>
              <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p>
            </div>
            <div className="col">
              {/* <Textarea minRows={8} placeholder="Description" /> */}
              <ReactQuill
                className="rounded shadow"
                placeholder="Write description"
                name="job_description"
                value={formik.values.job_description}
                onChange={(content) =>
                  formik.setFieldValue("job_description", content)
                }
              />
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Employment Type</h5>
            </div>
            <div className="col">
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1"
                  label="Part-Time"
                  name="employment_type"
                  checked={formik.values.employment_type.includes("Part Time")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue("employment_type", [
                        ...formik.values.employment_type,
                        "Part Time",
                      ]);
                    } else {
                      formik.setFieldValue(
                        "employment_type",
                        formik.values.employment_type.filter(
                          (type) => type !== "Part Time"
                        )
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1"
                  label="Full-Time"
                  name="employment_type"
                  checked={formik.values.employment_type.includes("Full-Time")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue("employment_type", [
                        ...formik.values.employment_type,
                        "Full-Time",
                      ]);
                    } else {
                      formik.setFieldValue(
                        "employment_type",
                        formik.values.employment_type.filter(
                          (type) => type !== "Full-Time"
                        )
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1"
                  label="Guest Lecturer"
                  name="employment_type"
                  checked={formik.values.employment_type.includes(
                    "Guest Lecturer"
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue("employment_type", [
                        ...formik.values.employment_type,
                        "Guest Lecturer",
                      ]);
                    } else {
                      formik.setFieldValue(
                        "employment_type",
                        formik.values.employment_type.filter(
                          (type) => type !== "Guest Lecturer"
                        )
                      );
                    }
                  }}
                />
              </div>
              <div
                className="shadow rounded"
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  className="mt-1"
                  label="Online"
                  name="employment_type"
                  checked={formik.values.employment_type.includes("Online")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue("employment_type", [
                        ...formik.values.employment_type,
                        "Online",
                      ]);
                    } else {
                      formik.setFieldValue(
                        "employment_type",
                        formik.values.employment_type.filter(
                          (type) => type !== "Online"
                        )
                      );
                    }
                  }}
                />
              </div>
              {/* <div
                style={{
                  border: "1px solid #d6d6d6",
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox className="mt-1 " label="Negotiable" />
              </div> */}
            </div>
          </div>
        </div>
        <hr></hr>
        {/* <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Job Requirment</h5>
              <p className="font-weight-bold">
                Provide a short description about the Requirment. Keep <br></br>
                it short and to the point.
              </p>
            </div>
            <div className="col">
              <Textarea minRows={8} placeholder="Description" />
            </div>
          </div>
        </div>
        <hr></hr> */}

        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Working Schedule</h5>
              <p className="font-weight-bold">
                You can pick Time For work schedule
              </p>
            </div>
            <div className="col">
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker', 'DateTimePicker']}>
                  <div className="row m-2">
                    <div className="col ">

                      <DemoItem label="Time Start" >
                        <TimePicker defaultValue={endTime} minTime={nineAM} />
                      </DemoItem>  </div>
                    <div className="col">


                      <DemoItem label="Time End">
                        <TimePicker defaultValue={StartTime} minTime={nineAM} />
                      </DemoItem></div>
                  </div>
                </DemoContainer>
              </LocalizationProvider> */}
              <div className="row">
                <div className="col">
                  <FormControl>
                    <FormLabel>Start Time</FormLabel>
                    <Input
                      type="time"
                      name="start_time"
                      value={formik.values.start_time}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl>
                    <FormLabel>End Time</FormLabel>
                    <Input
                      type="time"
                      name="end_time"
                      value={formik.values.end_time}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 mb-5 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Salary</h5>
              <p className="font-weight-bold">
                Choose How You Prefer to pay for this job
              </p>
            </div>
            <div className="col">
              <div className="row ">
                <div className="col mt-10px  mb-4">
                  <Input
                    placeholder="Amount Youn Want To Pay…"
                    name="salary_range"
                    value={formik.values.salary_range}
                    onChange={formik.handleChange}
                  />
                </div>
                {/* <div className="col mt-10px mb-4">
                  <Select placeholder="How You Want To Pay">
                    <Option value="dog">Yearly</Option>
                    <Option value="cat">Monthly</Option>
                    <Option value="fish">Weekly</Option>
                  </Select>
                </div> */}
              </div>
              <Checkbox
                label="Salery is Negotiable"
                variant="soft"
                defaultChecked
                name="is_negotiable"
                value={formik.values.is_negotiable}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="container mt-4 mb-5">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Add Subjects </h5>
              <p className="font-weight-bold ">
                Add all the subjects that your candidate should have expertised
                in
              </p>
            </div>
            <div className="col">
              <div>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Add Subject"
                    // inputProps={{ "aria-label": "search google maps" }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={handleClick}
                  >
                    <AddIcon />
                  </IconButton>
                </Paper>
                <Stack direction="row" spacing={1} className="mt-3 w-50 ">
                  {chips.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      variant="outlined"
                      onClick={() => console.log(`Clicked: ${chip}`)} // Add your custom click event handler
                      onDelete={() => handleDelete(chip)}
                      // sx={{ maxWidth: "100px" }} // Adjust the maximum width as per your requirements
                    />
                  ))}
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 mb-5 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold ">Minimum Qualification : </h5>
              <p className="font-weight-bold">
                This will be displayed on job page for candidates to see
              </p>
            </div>
            <div className="col ">
              <div className="row row-cols-3">
                <div className="col">
                  <Input
                    placeholder="Degree"
                    value={qualifications?.degree}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        degree: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <Input
                    placeholder="Subject"
                    value={qualifications?.subject}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        subject: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <Input
                    placeholder="Year"
                    type="month"
                    value={qualifications?.year}
                    onChange={(e) =>
                      setQualifications({
                        ...qualifications,
                        year: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">Job Location</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>
            <div className="col mb-3">
              <Input
                placeholder="Add Job Location"
                name="job_location"
                value={formik?.values?.job_location}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">Experience</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>
            <div className="col mb-3">
              <select
                className="form-select custom-select"
                placeholder="Select Years experience"
                name="experience"
                value={formik.values.experience}
                onChange={formik.handleChange}
                style={{ color: "#262626" }}
              >
                <option value="0-2 Years">0-2 years</option>
                <option value="2-5 Years">2-5 years</option>
                <option value="5+ Years">5+ years</option>
              </select>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold mb-3">Designation</h5>
              {/* <p className="font-weight-bold">
                Provide a short description about the job. Keep <br></br>it
                short and to the point.
              </p> */}
            </div>

            <div className="col mb-3">
              <select
                className="form-select custom-select"
                style={{ color: "#262626" }}
                placeholder="Select a Designation"
                indicator={<KeyboardArrowDown />}
                sx={{
                  width: 240,
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                }}
                name="designation"
                defaultValue={formik.values.designation}
                onChange={formik?.handleChange}
              >
                <option value="Teacher">Teacher</option>
                <option value="Principal">Principal</option>
                <option value="Librarian">Librarian</option>
                <option value="School Counselor">School Counselor</option>
              </select>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold mb-3">No of Vacancies</h5>
            </div>
            <div className="col">
              <Stack spacing={1.5}>
                <Input
                  type="number"
                  placeholder="Add Vacancy"
                  defaultValue={1}
                  name="vacancies"
                  value={formik.values.vacancies}
                  onChange={formik.handleChange}
                />
              </Stack>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold ">Contact Details</h5>
              <p className="font-weight-bold">
                Provide a contact details of the contact person for the position
              </p>
            </div>
            <div className="col">
              <Stack direction="row" spacing={1.5}>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                <Input
                  type="tel"
                  placeholder="Contact"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Stack>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-4 ">
              <h5 className="font-weight-bold ">Job Start & End Date</h5>
              <p className="font-weight-bold">
                Provide a Start And End Date Of Job Application
              </p>
            </div>
            <div className="col">
              <div className="row d-flex flex-wrap mb-4">
                <FormControl className="col">
                  <FormLabel>Start date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.start_date && (
                    <FormHelperText error className="text-danger">
                      {formik.touched.start_date && formik.errors.start_date}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl className="col">
                  <FormLabel>End date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="container mt-4 ">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 ">
              <h5 className="font-weight-bold">Spacial Note</h5>
              <p className="font-weight-bold">
                Provide a important note if applicable
              </p>
            </div>
            <div className="col">
              <Textarea
                minRows={4}
                placeholder="Note"
                name="special_note"
                value={formik.values.special_note}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div></div>
        <div className="text-center  mt-5 mb-100px">
          {/* <Link></Link> */}
          <Button variant="soft" onClick={formik.handleSubmit}>
            change a job
          </Button>
        </div>
      </div>
    </>
  );
}

export default Updatejob;
