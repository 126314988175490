import React, { useContext, useState } from "react";
import { AdminContext } from "../../SuperAdminContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import { useGlobalContext } from "global/context";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme?.palette?.background?.paper,
    color: theme?.palette?.text?.primary,
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette?.primary?.main,
    color: theme?.palette?.common?.white,
  },
  "& .MuiTableCell-body": {
    backgroundColor: theme.palette?.background?.default,
    color: theme.palette.text?.primary,
  },
}));

const CreateAdmin = () => {
  const { api } = useGlobalContext();
  const {
    admins,
    updateAdmin,
    removeAdmin,
    currentAdmin,
    setCurrentAdmin,
    fetchAdmins,
    isLoading,
  } = useContext(AdminContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [newAdmin, setNewAdmin] = useState({
    username: "",
    email: "",
    role: "",
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let temp = {};
    temp.username = newAdmin.username ? "" : "Username is required";
    temp.email = /\S+@\S+\.\S+/.test(newAdmin.email)
      ? ""
      : "Email is not valid format";
    temp.role = newAdmin.role ? "" : "Role is required";
    setErrors(temp);
    return Object.values(temp).every((x) => x === "");
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setNewAdmin({ ...newAdmin, [name]: files[0] });
  // };

  const handleAddAdmin = async () => {
    if (!validate()) return;

    const formData = new FormData();
    formData.append("username", newAdmin?.username);
    formData.append("email", newAdmin?.email);
    formData.append("role", newAdmin?.role);
    // Convert FormData to plain object for debugging
    const formDataObj = {};
    for (let pair of formData.entries()) {
      formDataObj[pair[0]] = pair[1];
    }
    try {
      await api.post("/admin/adminPage", formDataObj);
      fetchAdmins();
      toast.success("Admin added successfully");
      setDialogOpen(false);
      setNewAdmin({ username: "", email: "", role: "" });
    } catch (e) {
      if (e.response.status === 400) {
        toast.error(e.response.data.message);
        setErrors({});
      }
      if (e.response.status === 409) {
        toast.error(e.response.data.message);
      }
      toast.error("Error adding admin");
    }
  };

  const handleOpenConfirmation = (admin) => {
    setAdminToDelete(admin);
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setAdminToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (adminToDelete) {
      handleRemoveAdmin(adminToDelete.id);
    }
    handleCloseConfirmation();
  };

  const handleRemoveAdmin = (id) => {
    if (admins.length === 1) {
      setCurrentAdmin(null);
    }
    removeAdmin(id);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: 800,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 3,
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" component="h1">
          Admin Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
        >
          Add Admin
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <StyledTable>
          <TableHead sx={{ backgroundColor: "#0daab3", color: "#fff" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Avatar</TableCell>
              <TableCell sx={{ color: "#fff" }}>Email</TableCell>
              <TableCell sx={{ color: "#fff" }}>Role</TableCell>
              <TableCell sx={{ color: "#fff" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow
                key={admin.id}
                onClick={() => setCurrentAdmin(admin)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    admin.id === currentAdmin?.id ? "rgba(0,0,0,0.1)" : "",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                  },
                }}
              >
                <TableCell>
                  <Avatar src={admin?.logo} alt="logo" />
                </TableCell>
                <TableCell>{admin?.email}</TableCell>
                <TableCell>{admin?.role}</TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => setCurrentAdmin(admin)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleOpenConfirmation(admin)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <StyledDialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add New Admin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Name"
            type="text"
            fullWidth
            value={newAdmin.username}
            onChange={handleInputChange}
            variant="outlined"
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newAdmin.email}
            onChange={handleInputChange}
            variant="outlined"
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <Select
            label="Role"
            name="role"
            value={newAdmin.role}
            onChange={handleInputChange}
            fullWidth
            sx={{ marginTop: 2 }}
            variant="outlined"
            error={Boolean(errors.role)}
          >
            <MenuItem value="superadmin">Superadmin</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="sub-admin">Sub-admin</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddAdmin} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </StyledDialog>
      <StyledDialog open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete admin "{adminToDelete?.username}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

export default CreateAdmin;
