import React, { useContext, useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Button,
  Paper,
  Grid,
  Card,
  Switch,
  IconButton,
  Tooltip,
  Chip,
  Avatar,
  Divider,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { AdminContext } from "../SuperAdminContext";
import CreateAdmin from "./components/CreateAdmin";
import { styled } from "@mui/system";
import toast from "react-hot-toast";

const Item = styled(Paper)(({ theme }) => ({
  ...theme?.typography?.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme?.palette?.text?.secondary,
  ".MuiCard-root": {
    borderRadius: 0,
    boxShadow: "none",
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: grey[100],
  borderRadius: theme?.shape?.borderRadius * 2,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: green[600],
  "&:hover": {
    backgroundColor: green[800],
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme?.palette?.background?.default,
}));

const PrivilegeCard = ({ privilege, checked, onChange }) => {
  const isChecked = checked.includes(privilege.toLowerCase());
  return (
    <Card
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>{privilege}</Typography>
      <Switch
        checked={isChecked}
        onChange={() => onChange(privilege.toLowerCase())}
      />
    </Card>
  );
};

const PrivilegeManagement = ({ privileges, handlePrivilegeChange }) => {
  const privilegesList = [
    "Manage Users",
    "Manage Institutes",
    "Manage Profiles",
    "View All Users",
  ];

  return (
    <Grid container spacing={2}>
      {privilegesList.map((privilege) => (
        <Grid item xs={12} sm={6} md={3} key={privilege}>
          <Item>
            <PrivilegeCard
              privilege={privilege}
              checked={privileges}
              onChange={handlePrivilegeChange}
            />
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

const CreateAdminsProfile = () => {
  const { currentAdmin, updatePrevileges } = useContext(AdminContext);
  const [privileges, setPrivileges] = useState([]);

  useEffect(() => {
    if (currentAdmin) {
      setPrivileges(currentAdmin.privileges || []);
    }
  }, [currentAdmin]);

  const handlePrivilegeChange = (privilege) => {
    const updatedPrivileges = privileges.includes(privilege)
      ? privileges.filter((p) => p !== privilege)
      : [...privileges, privilege];
    setPrivileges(updatedPrivileges);
  };

  const handleSave = () => {
    updatePrevileges({ ...currentAdmin, privileges });
    toast.success("Privileges updated successfully");
  };

  return (
    <Box sx={{ my: 4 }}>
      <CreateAdmin />
      {currentAdmin ? (
        <StyledBox>
          <StyledPaper elevation={3}>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar
                alt={currentAdmin?.username}
                sx={{ mr: 2, bgcolor: green[500] }}
              />
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {currentAdmin?.email}
            </Typography>
            {currentAdmin?.role !== "superadmin" ? (
              <>
                <Tooltip title={`This user is a ${currentAdmin?.role}`} arrow>
                  <Chip
                    label={currentAdmin?.role}
                    color="primary"
                    sx={{ mt: 2 }}
                  />
                </Tooltip>
                <Typography variant="h6" gutterBottom>
                  Privileges
                </Typography>
                <PrivilegeManagement
                  privileges={privileges}
                  handlePrivilegeChange={handlePrivilegeChange}
                />
                <StyledButton variant="contained" onClick={handleSave}>
                  Save
                </StyledButton>
              </>
            ) : (
              <Tooltip title="Superadmin has all privileges by default" arrow>
                <Chip label="Superadmin" color="primary" sx={{ mt: 2 }} />
              </Tooltip>
            )}
          </StyledPaper>
        </StyledBox>
      ) : (
        <>
          <Typography variant="h6" align="center">
            No Current admins found!
          </Typography>
          <Typography variant="h6" align="center">
            Select an admin to view their previleges
          </Typography>
        </>
      )}
    </Box>
  );
};

export default CreateAdminsProfile;
