import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useGlobalContext } from "global/context";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  CircularProgress,
  CardActions,
  Avatar,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Email, LocationOn, School, StarBorder, Work } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import logo from "../assets/logo.png";
import { useParams } from "react-router-dom";

const ClassOptions = [
  { value: "", content: "All classes" },
  { value: "6", content: "Class 6" },
  { value: "7", content: "Class 7" },
  { value: "8", content: "Class 8" },
  { value: "9", content: "Class 9" },
  { value: "10", content: "Class 10" },
  { value: "11", content: "Class 11" },
  { value: "12", content: "Class 12" },
];

const SubjectOptions = [
  { value: "", content: "All subjects" },
  { value: "Mathematics", content: "Mathematics" },
  { value: "English", content: "English" },
  { value: "Physics", content: "Physics" },
  { value: "Chemistry", content: "Chemistry" },
  { value: "Biology", content: "Biology" },
  { value: "History", content: "History" },
  { value: "Geography", content: "Geography" },
  { value: "Computer Science", content: "Computer Science" },
  { value: "Hindi", content: "Hindi" },
  { value: "Economics", content: "Economics" },
  { value: "Science", content: "Science" },
  { value: "Finance", content: "Finance" },
];
const fontFamily = "'Quicksand', sans-serif";
const Teacherpoll = () => {
  const { locate } = useParams();
  const { api } = useGlobalContext();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [subject, setSubject] = useState("");
  const [classLevel, setClassLevel] = useState("");
  const [loading, setLoading] = useState(true);

  const userId = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    fetchNearbyTeachers();
  }, [locate]);

  useEffect(() => {
    filterData();
  }, [data, subject, classLevel]);
  const fetchNearbyTeachers = useCallback(async () => {
    try {
      const response = await api.get(`/app/user/teachers/nearby/${locate}`);
      setData(response?.data?.teachers);
      toast.success("Successfully fetched nearby teachers!");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching nearby teachers:", error);
    }
  }, []);

  const filterData = useCallback(() => {
    const filtered = data?.filter((item) => {
      return (
        (subject === "" || item.subject === subject) &&
        (classLevel === "" || item.class === classLevel)
      );
    });
    setFilteredData(filtered);
  }, [data, subject, classLevel]);

  const handleSubjectChange = useCallback((event) => {
    setSubject(event.target.value);
  }, []);

  const handleClassChange = useCallback((event) => {
    setClassLevel(event.target.value);
  }, []);

  const vote = async (itemId, rating) => {
    try {
      await api.post(`/app/user/teachers/vote/${itemId}`, {
        rating,
        user_id: userId,
      });
      fetchNearbyTeachers();
      toast.success("Successfully voted!");
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("You have already voted for this teacher");
        return;
      }
      toast.error("Failed to vote");
    }
  };
  //function to get color based on rating
  const getColor = (index, rating) => {
    switch (rating) {
      case 1:
        return "#ef476f";
      case 2:
        return "#ee9b00";
      case 3:
        return "#073b4c";
      case 4:
        return "#118ab2";
      case 5:
        return "#06d6a0";
      default:
        return "#06d6a0";
    }
  };

  const renderStars = (itemId, rating) => {
    return Array.from({ length: 5 }, (_, i) => (
      <IconButton
        key={i + 1}
        style={{ color: getColor(i, rating) }}
        onClick={() => vote(itemId, i + 1)}
      >
        {i < rating ? <StarIcon /> : <StarBorder />}
      </IconButton>
    ));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Please Wait...</Typography>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container className="mt-4">
      <Box
        sx={{
          background: "linear-gradient(45deg, #472fcb, #00a4a5)",
          p: 2,
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color="white"
          sx={{ fontWeight: "900" }}
        >
          Teachers Nearby Your Area
        </Typography>
        <Typography
          variant="span"
          sx={{
            fontWeight: "500",
            letterSpacing: "1px",
            lineHeight: "1.8",
            color: "#CCC4C4",
          }}
          mb={4}
        >
          <span className="fw-bold fs-24">Welcome!</span> This page simplifies
          your search for skilled educators in your area, offering a
          comprehensive listing of nearby teachers. Navigate through our curated
          list of available teachers with ease, filtering by subject and class
          level to find the perfect match for your learning needs.
        </Typography>
      </Box>
      <Row className="m-4 d-flex justify-content-center">
        <Col xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Subject</InputLabel>
            <Select
              value={subject}
              onChange={handleSubjectChange}
              label="Subject"
            >
              {SubjectOptions.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.content}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Class</InputLabel>
            <Select
              value={classLevel}
              onChange={handleClassChange}
              label="Class"
            >
              {ClassOptions.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.content}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </Row>
      {filteredData.length === 0 || filteredData === undefined ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "50vh",
          }}
        >
          <Typography variant="h6">No Teachers Found in your area.</Typography>
        </Box>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {filteredData.map((teacher) => (
            <Grid
              item
              key={teacher?.id}
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  width: { xs: "70%", md: "100%" },
                  maxWidth: 400,
                  borderRadius: "15px",
                  background: "#e0e0e0",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  overflow: "hidden",
                  "&:hover": {
                    boxShadow: "0 15px 30px rgba(0,0,0,0.2)",
                  },
                  ".MuiCardActions-root": {
                    padding: 0,
                  },
                  "&:hover .MuiCardActions-root": {
                    height: "65px",
                    borderTop: "1px solid #e0e0e0",
                  },
                  position: "relative",
                  margin: 1,
                  padding: { xs: 2, md: 1 },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 10,
                    fontWeight: 700,
                    color: "#969297",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    fontFamily: fontFamily,
                  }}
                  gutterBottom
                >
                  {teacher?.subject} - Class {teacher?.class}
                </Typography>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Avatar
                      sx={{
                        width: { xs: 50, md: 80 },
                        height: { xs: 50, md: 80 },
                        marginBottom: 1,
                      }}
                    />
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-center justify-content-md-start align-items-center">
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 15,
                          color: "#272932",
                          fontWeight: 700,
                          fontFamily: fontFamily,
                          textAlign: "center",
                          letterSpacing: 1,
                        }}
                      >
                        {teacher?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <School sx={{ color: "#969297", mr: 0.5 }} />
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#272932",
                          fontFamily: fontFamily,
                          letterSpacing: 1,
                        }}
                      >
                        {teacher?.qualification}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <Work sx={{ color: "#777", mr: 1 }} />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#555",
                          letterSpacing: 1,
                          fontFamily: fontFamily,
                        }}
                      >
                        {teacher?.experience || 0} years of experience
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <StarIcon sx={{ color: "#777", mr: 1 }} />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#555",
                          letterSpacing: 1,
                          fontFamily: fontFamily,
                        }}
                      >
                        {teacher?.rating.toFixed(1)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Email sx={{ color: "#0073e6", mr: 1 }} />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#0073e6",
                          letterSpacing: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "13rem",
                        }}
                      >
                        <a
                          href={`mailto:${teacher?.contact_info}`}
                          aria-label={`Email ${teacher?.name}`}
                          style={{
                            color: "#0073e6",
                            textDecoration: "none",
                            fontWeight: 600,
                            cursor: "pointer",
                            fontFamily: fontFamily,
                          }}
                        >
                          {teacher?.contact_info}
                        </a>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <LocationOn sx={{ color: "#777", mr: 1 }} />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#555",
                          letterSpacing: 1,
                          fontFamily: fontFamily,
                        }}
                      >
                        {teacher?.city}-{teacher?.state}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderTop: "1px solid transparent",
                    height: 0,
                    overflow: "hidden",
                    transition: "height 0.3s",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      fontWeight: 700,
                      color: "#333",
                      textTransform: "uppercase",
                      letterSpacing: 1,
                      mt: 0.5,
                    }}
                  >
                    Rate the Teacher
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {renderStars(teacher.id, Math.floor(teacher.rating))}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Teacherpoll;
