import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  Avatar,
  Button,
  Input,
  Modal,
  ModalClose,
  Sheet,
  ListItemDecorator,
  ListDivider,
  Box,
  Chip,
} from "@mui/joy";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import { DeleteForever, MoreVert } from "@mui/icons-material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import Debounce from "pages/utilities/Debouncing";

function Applications() {
  const navigate = useNavigate();
  const location = useLocation();
  const { api } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState("");
  const { title } = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [menuState, setMenuState] = useState({ anchorEl: null, openId: null });

  const getApplications = async () => {
    try {
      const res = await api.get(`/app/jobs/applications/${location?.state?.id}`);
      if (res.status === 200) {
        console.log("Applications Found: ", res.data);
        setData(res.data);
        setFilteredData(res.data);
      }
    } catch (err) {
      toast.error("Error getting applications");
    }
  };

  const handleClick = (event, id) => {
    setMenuState({ anchorEl: event.currentTarget, openId: id });
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, openId: null });
  };
  const handleSearchValue=(e)=>{
    setSearchTerm(e.target.value);
  }

  const handleSearch = useCallback(
    (e) => {
      const searchValue=searchTerm.toLowerCase()
      const filtered = data.filter((item) =>
        item.users.first_name.toLowerCase().includes(searchValue) ||
        item.users.last_name.toLowerCase().includes(searchValue)
      );
      setFilteredData(filtered);
    },
    [data,searchTerm]
  );

  const debouncedHandleSearch = useCallback(Debounce(handleSearch, 2000), [handleSearch]);

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    debouncedHandleSearch();
  }, [searchTerm, debouncedHandleSearch,data]);

  const handleStatus = async (id, status) => {
    try {
      const res = await api.put(`/app/jobs/application/status/${id}`, {
        newStatus: status,
      });
      if (res.status === 200) {
        getApplications();
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error("Error updating status of application");
    }
  };
  return (
    <div className="p-2">
      <div className="row mb-4 p-2">
        <div className="col d-flex align-items-center justify-content-around">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosRoundedIcon className="text-dark" />
          </IconButton>
          <h4 className="font-weight-bold">{location?.state?.title}</h4>
        </div>
        <div className="form col d-flex align-items-center justify-content-between">
          <Input
            fullWidth
            placeholder="search by name..."
            variant="outlined"
            sx={{ p: 1 }}
            value={searchTerm}
            onChange={handleSearchValue}
          />
        </div>
      </div>
      <div className="">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "rgba(25,103,210,.1)" }}>
              <TableRow>
                <TableCell>
                  <h6 className="font-weight-bold">Name</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Job Role</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Email</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Phone</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Status</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Action</h6>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((item) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div
                      className="d-flex align-items-center"
                      onClick={() =>
                        navigate(
                          `/institute/jobs/Applications/applicant/${item?.users?.username}`,
                          {
                            state: {
                              id: item?.id,
                              status: item?.status,
                            },
                          }
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Avatar
                        alt={`${item?.users?.first_name} ${item?.users?.last_name}`}
                        src={item?.users?.avatar_url}
                        sx={{ width: "46px", height: "46px" }}
                      />
                      <div className="ml-2 fs-15 font-weight-bold">
                        {item?.users?.first_name} {item?.users?.last_name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-primary text-capitalize font-weight-bold">
                      {item?.users?.role}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="">{item?.users?.email}</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="">{item?.users?.phone}</p>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Chip
                        variant="soft"
                        color={
                          item?.status === "hired"
                            ? "success"
                            : item?.status === "hold"
                            ? "warning"
                            : item?.status === "in-progress"
                            ? "info"
                            : item?.status === "rejected"
                            ? "danger"
                            : "neutral"
                        }
                      >
                        {item?.status}
                      </Chip>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ gap: 2 }}>
                      <Tooltip title="View Application" variant="outlined" color="secondary">
                        <IconButton>
                          <VisibilityIcon
                            onClick={() =>
                              navigate(
                                `/institute/jobs/Applications/applicant/${item?.users?.username}`,
                                {
                                  state: {
                                    id: item?.id,
                                    status: item?.status,
                                  },
                                }
                              )
                            }
                            className="fs-17"
                          />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        aria-controls={menuState.openId === item?.id ? "positioned-demo-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuState.openId === item?.id}
                        variant="plain"
                        color="neutral"
                        onClick={(event) => handleClick(event, item?.id)}
                      >
                        <MoreVert className="fs-17" />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={menuState.anchorEl}
                        open={menuState.openId === item?.id}
                        onClose={handleClose}
                        aria-labelledby="basic-demo-button"
                        placement="bottom-end"
                      >
                        <MenuItem onClick={() => handleStatus(item?.id, "reviewed")}>
                          Shortlisted
                        </MenuItem>
                        <MenuItem onClick={() => handleStatus(item?.id, "in-progress")}>
                          In Progress
                        </MenuItem>
                        <MenuItem onClick={() => handleStatus(item?.id, "hold")}>
                          On Hold
                        </MenuItem>
                        <MenuItem onClick={() => handleStatus(item?.id, "hired")}>
                          Hired
                        </MenuItem>
                        <MenuItem onClick={() => handleStatus(item?.id, "rejected")}>
                          Rejected
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Applications;
