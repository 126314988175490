import React, { useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactApexChart from "react-apexcharts";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import Alert from "@mui/joy/Alert";
import {
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Chip, Typography } from "@mui/joy";
import NewApplicationListItem from "../Careers/components/NewApplicationListItem";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import DepartmentJobSection, {
  DepartmentList,
  JobApplicationList,
  NewApplications,
  SelectedDepartment,
} from "./DepartmentJobsection";
import { Col, Container, Row } from "react-bootstrap";

//search box
const SearchBox = ({ searchTerm, handleSearch }) => (
  <TextField
    label="Search"
    variant="outlined"
    placeholder="Search by name..."
    fullWidth
    value={searchTerm}
    onChange={handleSearch}
    sx={{ my: 2 }}
  />
);
//applications table
const ApplicationsTable = ({ users }) => (
  <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#1b98d4" }}>
        <TableRow>
          <TableCell
            sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
          >
            Name
          </TableCell>
          <TableCell
            sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
          >
            Applications
          </TableCell>
          <TableCell
            sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
          >
            Applied on
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users?.map((user, index) => (
          <TableRow key={index}>
            <TableCell>{user?.name}</TableCell>
            <TableCell>
              {user?.jobTitles?.map((jobTitle, index) => (
                <Typography key={index} variant="body2" color="textSecondary">
                  {jobTitle}
                </Typography>
              ))}
            </TableCell>
            <TableCell>
              {user?.appliedDates?.map((appliedDate, index) => (
                <Typography key={index} variant="body2" color="textSecondary">
                  {appliedDate?.slice(0, 10)}
                </Typography>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
//Tab content
const TabContent = ({
  title,
  searchTerm,
  handleSearch,
  users,
  filteredUsers,
  setFilteredUsers,
}) => (
  <Row className="mb-4">
    <Col xs={12}>
      {/* This is the content of the tab */}
      <Typography variant="h2" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        {title}
      </Typography>
      <SearchBox
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
      />
      <ApplicationsTable users={users} />
    </Col>
  </Row>
);
//Application Tabs
const ApplicationsTabs = ({
  selectedTab,
  searchTerm,
  handleSearch,
  filteredUsers,
  setFilteredUsers,
  isloading,
  setIsloading,
}) => {
  switch (selectedTab) {
    case "pending":
      return (
        <TabContent
          title="Pending"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          users={filteredUsers}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsloading={setIsloading}
        />
      );
    case "hired":
      return (
        <TabContent
          title="Hired"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          users={filteredUsers}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsloading={setIsloading}
        />
      );
    case "reviewed":
      return (
        <TabContent
          title="Shortlisted"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          users={filteredUsers}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsloading={setIsloading}
        />
      );
    case "hold":
      return (
        <TabContent
          title="Onhold"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          users={filteredUsers}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsloading={setIsloading}
        />
      );
    case "rejected":
      return (
        <TabContent
          title="Rejected"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          users={filteredUsers}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          isloading={isloading}
          setIsloading={setIsloading}
        />
      );
    default:
      return null;
  }
};
//card template
const StatusCard = ({
  title,
  count,
  percentage,
  color,
  selectedTab,
  onClick,
}) => (
  <div className="col mt-4" onClick={onClick}>
    <div
      className="shadow rounded-3 mb-3 mb-lg-0"
      style={{
        cursor: "pointer",
        backgroundColor: selectedTab ? "#f5f5f5" : "#fff",
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-between p-4">
        <div className="font-weight-bold">
          <p
            className="fs-13 text-capitalize"
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "100px",
            }}
          >
            {title}
          </p>
          <h4>{count || 0}</h4>
        </div>
        <div>
          <CircularProgress
            size="lg"
            determinate
            value={parseFloat(percentage || 0).toFixed(2)}
            color={color}
          >
            {parseFloat(percentage || 0).toFixed(2)} %
          </CircularProgress>
        </div>
      </div>
    </div>
  </div>
);
//progrees bar template
const ProgressBar = ({ percentages, colors }) => (
  <div
    className="d-flex mx-2 rounded overflow-hidden mb-4"
    style={{ height: "10px" }}
  >
    {percentages.map((percentage, index) => (
      <div
        key={index}
        className="h-10"
        style={{
          width: `${parseFloat(percentage || 0).toFixed(2)}%`,
          backgroundColor: colors[index],
        }}
      ></div>
    ))}
  </div>
);
//legend card template
const LegendItem = ({ color, label, percentage }) => (
  <div className="row align-items-center mb-4">
    <div className="col-2">
      <div
        className={`rounded`}
        style={{ height: "14px", width: "14px", backgroundColor: color }}
      ></div>
    </div>
    <div className="col-7">
      <p>{label}</p>
    </div>
    <div className="col-3">
      <Chip size="sm" variant="soft" color={color}>
        {parseFloat(percentage || 0).toFixed(2)}%
      </Chip>
    </div>
  </div>
);

const InstituteDashboard = React.memo(() => {
  const [timeRange, setTimeRange] = useState("month"); // 'week', 'month', 'year', 'today'
  const [selectedTab, setSelectedTab] = useState(null);
  const [applicationsAnalytics, setApplicationsAnalytics] = useState({
    options: {},
    series: [],
  });
  const [applicationStats, setApplicationStats] = useState([]);
  const [newApplicants, setNewApplicants] = useState([]);
  const { api, userData } = useGlobalContext();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [teachersData, setTeachersData] = useState({});
  const [jobs, setJobs] = useState([]);
  const [filteredJobUsers, setFilteredJobUsers] = useState([]);
  const [searchJobTerm, setSearchJobTerm] = useState("");
  const [count, setCount] = useState(0);
  const [todayApplications, setTodayApplications] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({
    department: null,
    teachers: null,
  });

  const user = localStorage.getItem("user");
  //convert json to object
  const userObject = JSON.parse(user);

  const handleJobSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchJobTerm(searchValue);

    const filteredJobs = jobs.filter((job) => {
      const jobTitle = job.job_descriptions.job_title?.toLowerCase();
      const userName =
        job.users.first_name?.toLowerCase() +
        " " +
        job.users.last_name?.toLowerCase();

      return jobTitle.includes(searchValue) || userName.includes(searchValue);
    });

    setFilteredJobUsers(filteredJobs);
  };

  const fetchDepartments = async () => {
    try {
      const res = await api.get(
        `/v1/getdepartments/${userObject?.id || userData?.id}`
      );
      const departmentsData = res?.data?.data || [];
      setDepartments(departmentsData);

      for (const department of departmentsData) {
        try {
          const teacherRes = await api.get(`/v1/getteachers/${department.id}`);
          const teachersData = teacherRes.data;
          setTeachersData((prev) => ({
            ...prev,
            [department.id]: teachersData,
          }));
        } catch (err) {
          toast.error("Error fetching teachers for department");
        }
      }
      toast.success("Successfully fetched department information");
    } catch (err) {
      console.error("Error fetching departments", err);
      toast.error("Error fetching department information");
    }
  };
  const fetchJobs = async () => {
    try {
      const res = await api.get(
        `/app/jobs/institute/applications/${userObject?.id || userData?.id}`
      );
      const jobsData = res?.data || [];
      setCount(jobsData.totalJobDescriptions);
      setJobs(jobsData.jobApplications);
      setFilteredJobUsers(jobsData.jobApplications);
      toast.success("Successfully fetched applications");
    } catch (err) {
      toast.error("Error fetching applications");
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchJobs();
  }, [userObject?.id]);

  const showDepartment = () => {
    setSelectedTab((prev) => (prev === "departments" ? null : "departments"));
  };

  const showJobs = () => {
    setSelectedTab((prev) => (prev === "jobs" ? null : "jobs"));
  };
  const showNew = () => {
    setSelectedTab((prev) => (prev === "new" ? null : "new"));
  };
  const ShowSelectedDepartment = () => {
    setSelectedTab((prev) => (prev === "department" ? null : "department"));
  };

  const ScrollerStyle = {
    maxHeight: "100vh",
    width: "100%",
    overflowY: "scroll",
    justifyContent: "flex-end",
    // marginTop: "140px",
  };
  const getApplicationsAnalytics = async () => {
    try {
      const res = await api.get(
        `/app/charts/job-applications/institute/${
          userObject?.id || userData?.id
        }?timeRange=${timeRange}`
      );
      setApplicationsAnalytics(res?.data?.response);
      toast.success("Applications analytics fetched successfully");
    } catch (err) {
      toast.error("Error getting applications analytics");
    }
  };

  const getApplicationStats = async () => {
    setIsloading(true);
    try {
      const res = await api.get(
        `/app/charts/job-applications-stats/institute/${
          userObject?.id || userData?.id
        }`
      );
      setApplicationStats(res?.data?.applicationStatusStats);
      toast.success("Application stats fetched successfully");
      setIsloading(false);
    } catch (err) {
      toast.error("Error getting applications stats");
    }
  };

  const getNewApplicants = async () => {
    setIsloading(true);
    try {
      const res = await api.get(
        `/app/applicants/new-applicants/${userObject?.id || userData?.id}`
      );
      setNewApplicants(res?.data);

      // Get today's date at 00:00:00
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Filter applicants who applied today
      const todayApplicants = res?.data?.filter((applicant) => {
        const applicantDate = new Date(applicant.createdAt);
        return applicantDate.getTime() >= today.getTime();
      });
      setTodayApplications(todayApplicants);
    } catch (err) {
      toast.error("Error getting applications stats");
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getApplicationsAnalytics();
    getApplicationStats();
    getNewApplicants();
  }, [timeRange]);
  const handleTabChange = async (newValue) => {
    setSearchTerm("");
    setIsloading(true);
    setSelectedTab((prevState) => (prevState === newValue ? "" : newValue));
    if (selectedTab === newValue) {
      setIsloading(false);
      return;
    }
    try {
      const res = await api.get(
        `/app/charts/${newValue}/institute/${userObject?.id || userData?.id}`
      );
      const fetchedData = res?.data?.applicationStatusStats;
      setData(fetchedData);
      setFilteredUsers(fetchedData[newValue]?.users || []);
      setIsloading(false);
    } catch (err) {
      toast.error("Error getting applications analytics");
    }
  };

  useEffect(() => {
    if (selectedTab) {
      filterUsers(searchTerm, selectedTab);
    }
  }, [data, searchTerm, selectedTab]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    filterUsers(searchValue, selectedTab);
  };

  const filterUsers = (searchValue, status) => {
    const filtered = data[status]?.users.filter((user) => {
      const matchesSearch =
        user.name.toLowerCase().includes(searchValue) ||
        user.jobTitles.some((title) =>
          title.toLowerCase().includes(searchValue)
        );
      return matchesSearch;
    });
    setFilteredUsers(filtered);
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} className="scroll" style={ScrollerStyle}>
            {/* <DepartmentJobSection id={userObject?.id} /> */}
            <Row className="mb-4">
              <Col xs={12} md={6}>
                <StatusCard
                  title="DEPARTMENTS"
                  count={departments.length}
                  percentage={departments.length * 10}
                  onClick={showDepartment}
                  selectedTab={selectedTab === "departments"}
                />
              </Col>
              <Col xs={12} md={6}>
                <StatusCard
                  title="JOBS"
                  count={count}
                  percentage={count * 10}
                  onClick={showJobs}
                  selectedTab={selectedTab === "jobs"}
                />
              </Col>
              <Col xs={12} md={3}>
                {selectedDepartment.department ? (
                  <StatusCard
                    title={selectedDepartment?.department?.name + " department"}
                    count="0"
                    percentage={0}
                    onClick={ShowSelectedDepartment}
                    selectedTab={selectedTab === "department"}
                  />
                ) : (
                  <StatusCard
                    title="APPLICATIONS"
                    count={todayApplications?.length}
                    percentage={todayApplications?.length * 10}
                    onClick={showNew}
                    selectedTab={selectedTab === "new"}
                  />
                )}
              </Col>
              <Col xs={12} md={3}>
                <StatusCard
                  title="PENDING"
                  count={applicationStats?.pending?.count}
                  percentage={applicationStats?.pending?.percentage}
                  onClick={() => handleTabChange("pending")}
                  selectedTab={selectedTab === "pending"}
                />
              </Col>
              <Col xs={12} md={3}>
                <StatusCard
                  title="HIRED"
                  count={applicationStats?.hired?.count}
                  percentage={applicationStats?.hired?.percentage}
                  color="success"
                  onClick={() => handleTabChange("hired")}
                  selectedTab={selectedTab === "hired"}
                />
              </Col>
              <Col xs={12} md={3}>
                <StatusCard
                  title="SHORTLISTED"
                  count={applicationStats?.reviewed?.count}
                  percentage={applicationStats?.reviewed?.percentage}
                  color="info"
                  onClick={() => handleTabChange("reviewed")}
                  selectedTab={selectedTab === "reviewed"}
                />
              </Col>
              <Col xs={12} md={3}>
                <StatusCard
                  title="ONHOLD"
                  count={applicationStats?.hold?.count}
                  percentage={applicationStats?.hold?.percentage}
                  color="warning"
                  onClick={() => handleTabChange("hold")}
                  selectedTab={selectedTab === "hold"}
                />
              </Col>
              <Col xs={12} md={3}>
                <StatusCard
                  title="REJECTED"
                  count={applicationStats?.rejected?.count}
                  percentage={applicationStats?.rejected?.percentage}
                  color="danger"
                  onClick={() => handleTabChange("rejected")}
                  selectedTab={selectedTab === "rejected"}
                />
              </Col>
            </Row>
            {isloading ? (
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="d-flex justify-content-center">
                    <CircularProgress size="lg" color="info" className="mb-4" />
                  </div>
                </div>
              </div>
            ) : (
              <ApplicationsTabs
                selectedTab={selectedTab}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                data={data}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                isloading={isloading}
                setIsloading={setIsloading}
              />
            )}
            {selectedTab === "departments" && (
              <DepartmentList
                departments={departments}
                teachersData={teachersData}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
              />
            )}
            {selectedTab === "jobs" && (
              <JobApplicationList
                searchTerm={searchJobTerm}
                handleSearch={handleJobSearch}
                filteredapplicants={filteredJobUsers}
              />
            )}
            {selectedTab === "new" && (
              <NewApplications
                applicant={todayApplications}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
              />
            )}
            {selectedTab === "department" && (
              <SelectedDepartment
                department={selectedDepartment.department}
                teachers={selectedDepartment.teachers}
              />
            )}
            <div className="row mb-4">
              <div className="col-12 col-lg-8">
                <div className="chart shadow p-4 rounded">
                  <Typography
                    variant="plain"
                    color="warning"
                    className=" mb-4 pl-3"
                  >
                    <strong> Top Active Jobs </strong>
                  </Typography>
                  {applicationsAnalytics?.series?.length && (
                    <ReactApexChart
                      options={applicationsAnalytics?.options}
                      series={applicationsAnalytics?.series}
                      type="area"
                      height={350}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="row mb-4">
                  <div className="col">
                    <div className="text-dark shadow rounded-3 p-4">
                      <Typography
                        variant="plain"
                        color="warning"
                        className="mb-4 pl-3"
                      >
                        <strong>Accusitions</strong>
                      </Typography>

                      <ProgressBar
                        percentages={[
                          applicationStats?.pending?.percentage,
                          applicationStats?.reviewed?.percentage,
                          applicationStats?.hold?.percentage,
                          applicationStats?.rejected?.percentage,
                        ]}
                        colors={["#008ffb", "#00e396", "#ffc107", "#ff4560"]}
                      />
                      <Container>
                        <LegendItem
                          label="Pending"
                          percentage={applicationStats?.pending?.percentage}
                          color="#008ffb"
                        />
                        <LegendItem
                          label="Hired"
                          percentage={applicationStats?.hired?.percentage}
                          color="#00e396"
                        />
                        <LegendItem
                          label="Shortlisted"
                          percentage={applicationStats?.reviewed?.percentage}
                          color="purple"
                        />
                        <LegendItem
                          label="On-Hold"
                          percentage={applicationStats?.hold?.percentage}
                          color="#ffc109"
                        />
                        <LegendItem
                          label="Rejected"
                          percentage={applicationStats?.rejected?.percentage}
                          color="#ff4560"
                        />
                      </Container>
                    </div>
                  </div>
                </div>
                <div className="row mb-12">
                  <div className="col">
                    <div className="text-dark shadow rounded-3 p-4">
                      <Typography
                        variant="plain"
                        color="warning"
                        className="mb-3 pl-3"
                      >
                        <strong>New Applications</strong>
                      </Typography>
                      {newApplicants?.map((applicant, index) => (
                        <NewApplicationListItem
                          key={index}
                          applicant={applicant}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default InstituteDashboard;
