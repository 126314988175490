import { Button } from '@mui/joy';
import React from 'react';
import { useState } from 'react';
import { useGlobalContext } from 'global/context';
import { useEffect } from 'react';
import AwardCard from './AwardCard';
import AddAwards from 'pages/user/profile/components/Modals/AddAwards';
import UserList from 'components/user/List/UserList';

function Award({userId}) {
  const { api, userData } = useGlobalContext();
  const [openAddAward, setOpenAddAward] = useState(false);
  const [award, setAward] = useState([]);

  const getAward = async () => {
    try {
        const res = await api.get(`/app/candidates/award/${userId || userData?.id}`);
        console.log(res)
        if (res?.status === 200) {
            console.log("research : ", res?.data?.results);
            setAward(res?.data?.results);
        }
    } catch (e) {
        console.log(e);
        setAward([]);
    }
}


useEffect(() => {
    getAward();
}, [userId]);

  return (
    <>
    <div className="job-container">
        <div className="job-header mb-4 d-flex flex-wrap align-items-center justify-content-between">
            <h4>Awards</h4>
            {
                userId === userData?.id ?
                <Button variant='soft' onClick={() => setOpenAddAward(true)}>Add Award</Button>
                 :null   
            }
        </div>
        {
                    award?.length ?
                        <div className="job-list">
                            {
                                award?.map(award => (
                                    <AwardCard 
                                    award={award} 
                                    key={award?.id} 
                                    setOpenAddAward={setOpenAddAward}
                                    getAward={getAward}
                                    />
                                ))
                            }
                        </div> : null
                }
                
        <AddAwards 
        open={openAddAward} 
        setOpen={setOpenAddAward} 
        award={award} 
        getAward={getAward} 
        action='add' 
            
        />
        
    </div>
</>
  )
}

export default Award