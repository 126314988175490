import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Avatar,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import {
  Dashboard,
  Home,
  Work,
  People,
  Assessment,
  ExpandLess,
  ExpandMore,
  NotificationAdd,
  EmojiPeople,
  School,
  Poll,
  PollRounded,
  Person,
  ExitToApp,
} from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { ListItemButton, ListItemContent, ListItemDecorator } from "@mui/joy";
import { useGlobalContext } from "global/context";

const fontFamily = "'Quick-sand',sans-serif";

const SidebarItem = ({ label, icon, to }) => (
  <List>
    <ListItem
      component={NavLink}
      to={to}
      className="sidebarItem"
      sx={{
        color: "#262626",
        borderRadius: 2,
        "&.active": {
          background: "#b3e5fc", // Active background color
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText sx={{ fontFamily: `${fontFamily}` }} primary={label} />
    </ListItem>
  </List>
);

const SidebarCollapseItem = ({ open, handleClick, icon, label, subItems }) => (
  <>
    <ListItem button onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {subItems.map((subItem) => (
          <ListItem
            key={subItem.label}
            component={NavLink}
            to={subItem.to}
            className="sidebarItem"
            sx={{
              pl: 4,
              color: "#262626",
              borderRadius: 2,
              "&.active": {
                background: "#b3e5fc", // Active background color
              },
            }}
          >
            <ListItemIcon>{subItem.icon}</ListItemIcon>
            <ListItemText primary={subItem.label} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  </>
);

const SupSidebar = ({ open }) => {
  const { removeToken,removeUser } = useGlobalContext()
  const [usersOpen, setUsersOpen] = useState(false);
  const navigate=useNavigate()

  const handleUsersClick = () => {
    setUsersOpen(!usersOpen);
  };

  const userSubItems = [
    { label: "Admins", to: "/superadmin/createadmins", icon: <Person /> },
    { label: "Students", to: "/superadmin/users/students", icon: <Person /> },
    { label: "Teachers", to: "/superadmin/users/teachers", icon: <Person /> },
    {
      label: "Professionals",
      to: "/superadmin/users/professionals",
      icon: <Person />,
    },
  ];
  const handleLogout = () => {
    localStorage.removeItem("isLogin");
    removeToken();
    removeUser();
    navigate("/auth/admin/login");
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        width: 240,
        height: "100vh",
        color: "#262626",
        background: "#f8f9fa",
        transition: "width 0.3s",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&:hover::-webkit-scrollbar": {
          width: 8,
          display: "block",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(255, 255, 255, 0.2)",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "rgba(255, 255, 255, 0.4)",
        },
        "&::-webkit-scrollbar-thumb:active": {
          background: "rgba(255, 255, 255, 0.5)",
        },
      }}
    >
      <SidebarItem
        label="Dashboard"
        to="/superadmin/dashboard"
        icon={<Dashboard />}
      />
      <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />
      <SidebarItem
        label="Notifications"
        to="/superadmin/notification"
        icon={<NotificationAdd />}
      />
      <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />
      <SidebarItem
        label="Institutes"
        to="/superadmin/institute"
        icon={<School />}
      />
      <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />

      <SidebarCollapseItem
        open={usersOpen}
        handleClick={handleUsersClick}
        icon={<People />}
        label="Users"
        subItems={userSubItems}
      />
      <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />

      <ListItem
        className="sidebarItem"
        sx={{
          "&.active": {
            background: "#b3e5fc", // Active background color
          },
        }}
      >
        <ListItemButton
        variant="plain"
        color="primary"
        onClick={handleLogout}
        className="rounded mb-1"
        >
          <ListItemDecorator className="p-2 rounded">
            <ExitToApp />
          </ListItemDecorator>
          <ListItemContent sx={{ fontFamily: fontFamily, fontWeight: "bold" }}>
            Logout
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default SupSidebar;
