import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import InstituteSidebar from "./Component/InstituteSidebar";
import { Divider } from "@mui/joy";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/system";

const InstituteParentContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100vh",
});

const SidebarContainer = styled("div")(({ hide }) => ({
  flex: "0 0 auto",
  width: hide ? "0" : "250px",
  overflow: "hidden",
  transition: "width 0.3s ease",
}));

const ContentContainer = styled("div")({
  flex: "1 1 auto",
  // padding: "1rem", // Padding similar to Bootstrap's container padding
});

const CustomDivider = styled(Divider)(({ hide }) => ({
  display: hide ? "none" : "block",
  // backgroundColor: "#1976d2", // Primary color similar to MUI's primary color
  width: "1px",
}));

function InstituteParent() {
  const [hideProfileDiv, setHideProfileDiv] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setHideProfileDiv(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <InstituteParentContainer>
      <SidebarContainer hide={hideProfileDiv}>
        <InstituteSidebar />
      </SidebarContainer>
      <CustomDivider
        hide={hideProfileDiv}
        orientation="vertical"
        variant="middle"
      />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </InstituteParentContainer>
  );
}

export default InstituteParent;
