import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import {
  Chip,
  IconButton,
  Paper,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row,Container } from "react-bootstrap";

const JobAppliedUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.job;

  return (
    <Container>
      <Row className="mb-4 p-2">
        <Col className="d-flex align-items-center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosRoundedIcon className="text-dark mt-2" />
          </IconButton>
          <h3 className="  font-weight-bold ">{data.user.last_name !== null ? data.user.first_name + " " + data.user.last_name : data.user.first_name}</h3>
        </Col>
      </Row>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "rgba(25,103,210,.1)" }}>
              <TableRow>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Applications</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Status</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">UserRole</h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Email </h6>
                </TableCell>
                <TableCell align="center">
                  <h6 className="font-weight-bold">Phone </h6>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Looping through applications and displaying each in a row */}
              {data.jobApplications.map((item, index) => (
                <TableRow key={index}>  {/* Adding a unique key for each row */}
                  <TableCell align="center">
                    <h6 className="font-weight-bold">{item.jobTitle}</h6>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Chip label={item.status} color={
                        item.status === "Pending" ? "primary" :
                        item.status === "hired" ? "success" :
                        item.status === "rejected" ? "error" : 
                        item.status === "hold" ? "warning" : 
                        item.status === "reviewed" ? "info" :"secondary"
                      } />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <h6 className="font-weight-bold">{data.user.role}</h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6 className="font-weight-bold">{data.user.email}</h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6 className="font-weight-bold">{data.user.phone}</h6>
                  </TableCell>
                  <TableCell align="center">
                    {data.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default JobAppliedUsers;
