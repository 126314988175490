import React, { useState, useEffect } from "react";
import { useGlobalContext } from "global/context";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import toast from "react-hot-toast";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, ModeEdit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Jobcategory = () => {
  const { api } = useGlobalContext();
  const navigate=useNavigate()
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [updateModalOpen, setupdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
 
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.get("/admin/categories");
      setCategories(response?.data?.results);
      toast.success("All Category Successfully fetched");
    } catch (err) {
      if (err.response.status === 409) {
        Popup("error", "Access denied");
        navigate("/");
        return;
      }
      toast.error("Error fetching categoryData");
    }
  };

  

  //update category
  const updateCategory = async () => {
    try {
      await api.put(`/admin/categories/edit/${currentCategory.id}`, {
        category: currentCategory,
      });
      fetchCategories();
      toast.success("Successfully Update a Category");
    } catch (err) {
      toast.error("Error to update category");
    }
    setupdateModalOpen(false);
  };
  //delete category
  const deleteCategory = async (id) => {
    try {
      await api.delete(`/admin/categories/delete/${id}`);
      fetchCategories();
      toast.success("Successfully Delete a Category");
    } catch (err) {
      toast.error("Error to delete category");
    }
    setDeleteModalOpen(false);
  };
  const handleUpdateCategory = (category) => {
    setCurrentCategory(category);
    setupdateModalOpen(true);
  };
  const handleCloseUpdateModal = () => {
    setupdateModalOpen(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteCategoryId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        

        {/* Job Categories Table */}
        <TableContainer
          component={Paper}
          sx={{ maxWidth: "950px", margin: "auto" }}
        >
          <Table sx={{ minWidth: "650px" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#A3BFA8" }}>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Icon
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Slug
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Created At
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category) => (
                <TableRow
                  key={category.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <TableCell>{category.name}</TableCell>
                  <TableCell>
                    <img
                      src={category.icon}
                      alt={category.name}
                      style={{ width: "30px", height: "30px" }}
                    />
                  </TableCell>
                  <TableCell>{category.slug}</TableCell>
                  <TableCell>
                    {category.createdAt
                      ? new Date(category.createdAt).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => handleUpdateCategory(category)}
                        sx={{
                          "&:hover": {
                            color: "primary.main",
                          },
                        }}
                      >
                        <ModeEdit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleOpenDeleteModal(category.id)}
                        sx={{
                          color: "#e53935",
                          "&:hover": {
                            color: "#d32f2f",
                          },
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Update Modal */}
      <Dialog open={updateModalOpen} onClose={handleCloseUpdateModal}>
        <DialogTitle>Update Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentCategory ? currentCategory.name : ""}
            onChange={(e) =>
              setCurrentCategory((prevCategory) => ({
                ...prevCategory,
                name: e.target.value,
              }))
            }
          />
          <TextField
            margin="dense"
            label="Icon"
            type="text"
            fullWidth
            value={currentCategory ? currentCategory.icon : ""}
            onChange={(e) =>
              setCurrentCategory((prev) => ({
                ...prev,
                icon: e.target.value,
              }))
            }
          />
          <TextField
            margin="dense"
            label="Slug"
            type="text"
            fullWidth
            value={currentCategory ? currentCategory.slug : ""}
            onChange={(e) =>
              setCurrentCategory((prevCategory) => ({
                ...prevCategory,
                slug: e.target.value,
              }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateCategory(currentCategory);
            }}
            sx={{
              backgroundColor: "#5842AF",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px 20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#7163A7",
              },
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal */}
      <Dialog open={deleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteCategory(deleteCategoryId);
            }}
            sx={{
              backgroundColor: "#f44336", // Red color
              color: "#fff", // White text color
              borderRadius: "8px", // Rounded corners
              padding: "10px 20px", // Padding
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Shadow effect
              transition: "background-color 0.3s", // Smooth transition
              "&:hover": {
                backgroundColor: "#d32f2f", // Darker red color on hover
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Jobcategory;
