// Notifications.js
import { Avatar } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useGlobalContext } from 'global/context';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { Button } from '@mui/material';

// NotificationItem Component
const NotificationItem = ({ data, onClick }) => {
  console.log("Data In NotificationItem : ",data)
  return (
    <div
      className='d-flex justify-content-between align-items-center p-3 hover-s shadow-sm rounded-3 mb-3'
      onClick={() => onClick(data)}
      style={{ cursor: 'pointer' }}
    >
      <div className='d-flex '>
        <Avatar
          alt={`${data?.message?.split(' ')[0]} ${data?.message?.split(' ')[1]}`}
          src="https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
          className="mr-3"
          sx={{ width: "60px", height: "60px" }}
        />
        <div style={{ lineHeight: "20px" }}>
          <p className='text-dark'><strong>{data?.message?.split(' ')[1]}</strong></p>
          <p className='fs-14'>{data?.message}</p>
          <p className='fs-11'>{moment(data?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div>
        <img
          className='rounded-2'
          src='https://images.pexels.com/photos/8767784/pexels-photo-8767784.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          alt='logo'
          style={{ width: "60px", height: "60px" }}
        />
      </div>
    </div>
  );
}

// DetailedView Component
const DetailedView = ({ data, onClose }) => {
  console.log("Data in Detailed : ",data)
  if (!data) return null;

  return (
    <div className='detailed-view p-3 m-5 shadow-sm rounded-3 border border-success rounded'>
      <Button onClick={onClose} variant="contained" color="secondary" size="small">Close</Button>
      <div className='d-flex align-items-center mt-3'>
      <div>
      <h3>From </h3>
      </div>
        <Avatar
          alt={`${data?.message?.split(' ')[0]} ${data?.message?.split(' ')[1]}`}
          src="https://images.unsplash.com/flagged/photo-1557427161-4701a0fa2f42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
          className="mr-3"
          sx={{ width: "60px", height: "60px" }}
        />
        <div>
        
          <p className='text-dark'><strong>{data?.message?.split(' ')[1]}</strong></p>
          <p className='fs-14'>{data?.message}</p>
          <p className='fs-11'>{moment(data?.createdAt).fromNow()}</p>
        </div>
      </div>
      <div className='mt-3'>
        <p><strong>Details:</strong></p>
        <p>{data?.message}</p>
      </div>
    </div>
  );
}

// Notifications Component
function Notifications() {
  const { api } = useGlobalContext();
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  console.log("Notification : ",notifications)

  const getNotifications = async () => {
    try {
      const res = await api.get('/app/notification');
      if (res?.status === 200) {
        setNotifications(res?.data?.notifications);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error getting notifications");
    }
  }

  const handleNotificationClick = (data) => {
    setSelectedNotification(data);
  };

  const handleCloseDetailedView = () => {
    setSelectedNotification(null);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className='container mb-3 my-5 d-flex' style={{ minHeight: 500 }}>
      <div className='notification-list'>
        <h3 className='mb-4 fw-bold'>Notification <NotificationsNoneIcon className='fw-bold' color='info' /></h3>
        <div>
          {
            notifications.length ?
              notifications.map((notification, key) => (
                <NotificationItem key={key} data={notification} onClick={handleNotificationClick} />
              ))
              :
              <h4>No notifications found</h4>
          }
        </div>
      </div>
      <div className='detailed-view-container ms-4' style={{ flex: 1 }}>
        <DetailedView data={selectedNotification} onClose={handleCloseDetailedView} />
      </div>
    </div>
  );
}

export default Notifications;