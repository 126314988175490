import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import toast from "react-hot-toast";
import { styled } from "@mui/system";
import { useGlobalContext } from "global/context";
import { CircularProgress } from "@mui/joy";
import { AdminContext } from "../Admin";
import { Popup } from "layout/Popup";
import { useNavigate } from "react-router-dom";

const AdminNotification = () => {
  const { api } = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState("jobs");
  const [isLoading, setIsLoading] = useState(true);
  const { fontFamily } = useContext(AdminContext);
  const navigate=useNavigate()

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/app/jobs/inactive");
      if (res.data.results.length === 0) {
        toast.error("No Pending Jobs");
      }
      setJobs(res.data.results);
      setIsLoading(false);
      toast.success(res.data.message);
    } catch (err) {
      if (err.response.status === 409) {
        Popup("error", "Access denied");
        navigate("/");
        return;
      }
      toast.error("Error getting jobs");
      setIsLoading(false);
    }
  };

  const handleApprove = async (jobId) => {
    setIsLoading(true);
    try {
      const res = await api.put(`/app/jobs/approve-job/${jobId}`);
      if (res.status === 200) {
        toast.success(res.data.message);
      }
      fetchJobs();
    } catch (err) {
      toast.error("Error approving a job");
      setIsLoading(false);
    }
  };

  const handleReject = async (jobId) => {
    setIsLoading(true);
    try {
      const res = await api.put(`/app/jobs/reject-job/${jobId}`);
      if (res.status === 200) {
        toast.success(res.data.message);
      }
      fetchJobs();
      Popup("success","Successfully Reject the Job")
    } catch (err) {
      Popup("error","Error rejecting a job")
    }finally{
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const renderJobCard = (job) => {
    const jobQualifications = JSON.parse(job?.minimum_qualification);

    return (
      <Grid item xs={12} md={6} key={job.id}>
        <StyledJobCard>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography variant="h5" gutterBottom fontFamily={fontFamily} color="#CBA135" fontWeight="bold">
              {job.job_title}
            </Typography>
            <Typography
              variant="body1"
              fontFamily={fontFamily}
              dangerouslySetInnerHTML={{ __html: job.job_description }}
            ></Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontFamily={fontFamily}
            >
              <span style={{ color: "#0daab3" }}>Qualifications:</span>{" "}
              {`${jobQualifications?.degree}, ${jobQualifications?.subject}, ${jobQualifications?.year}`}
            </Typography>
            <Typography
              variant="body2"
              fontFamily={fontFamily}
              color="textSecondary"
            >
              <span style={{ color: "#0daab3" }}>Salary:</span>{" "}
              {job.salary_range}
            </Typography>
            <Typography variant="body2" fontFamily={fontFamily} color="textSecondary">
              <span style={{ color: "#0daab3" }}>Institute:</span>{" "}
              {job.institute_name}
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-around", pb: 2 }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => handleApprove(job.id)}
            >
              Approve
            </StyledButton>
            <StyledButton
              variant="contained"
              color="error"
              onClick={() => handleReject(job.id)}
            >
              Reject
            </StyledButton>
          </Box>
        </StyledJobCard>
      </Grid>
    );
  };

  const StyledCard = styled(Card)(({ theme, isActive }) => ({
    backgroundColor: isActive ? "#1b98d4" : "#fff",
    color: isActive ? "#fff" : "#000",
    border: "1px solid #eee",
    padding: theme.spacing(1),
    width: "130px",
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: theme.shape.borderRadius * 2,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.4)",
      transform: "translateY(-4px)",
    },
    "& .MuiCardContent-root:last-child": {
      padding: 0,
    },
  }));

  const StyledJobCard = styled(Card)(({ theme }) => ({
    mb: 4,
    width: { xs: "100%", md: "50%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    transition: "0.3s",
    boxShadow:
      "0 8px 20px -12px rgba(0,0,0,0.4), 0 16px 40px -12px rgba(0,0,0,0.3)",
    borderRadius: theme?.shape?.borderRadius,
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow:
        "0 16px 30px -12px rgba(0,0,0,0.4), 0 24px 40px -12px rgba(0,0,0,0.3)",
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    width: "45%",
    fontSize: "0.875rem",
  }));

  const showJobs = () => {
    setActiveTab((prev) => (prev === "jobs" ? null : "jobs"));
  };

  const showNotifications = () => {
    setActiveTab((prev) => (prev === "notifications" ? null : "notifications"));
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item>
          <StyledCard onClick={showJobs} isActive={activeTab === "jobs"}>
            <CardContent>
              <Typography
                variant="h6"
                className="title"
                sx={{
                  color: activeTab === "jobs" ? "#fff" : "#000",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontFamily: fontFamily,
                }}
              >
                Pending Jobs
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item>
          <StyledCard
            onClick={showNotifications}
            isActive={activeTab === "notifications"}
          >
            <CardContent>
              <Typography
                variant="h6"
                className="title"
                sx={{
                  color: activeTab === "notifications" ? "#fff" : "#000",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontFamily: fontFamily,
                }}
              >
                Notifications
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      {activeTab === "jobs" && (
        <Box my={1.5}>
          <Typography variant="h5" gutterBottom>
            {jobs.length == 0 && "No Pending Jobs"}
          </Typography>
          <Grid container spacing={2}>
            {jobs.filter((job) => job.status === "inactive").map(renderJobCard)}
          </Grid>
        </Box>
      )}
      {activeTab === "notifications" && (
        <Box my={1.5}>
          <Typography variant="h5" gutterBottom>
            {notifications.length == 0 && "No Notifications"}
          </Typography>
          {notifications.map((note, index) => (
            <Typography key={index} variant="body1">
              {note}
            </Typography>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default AdminNotification;
