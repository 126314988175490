import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Swal from "sweetalert2"
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import Select, { ReactSelectProps } from 'react-select'
import {
  Checkbox,
  Divider,
  FormHelperText,
  ModalClose,
  experiences,
  Option,
  Textarea,
} from "@mui/joy";
// import Select, { selectClasses } from "@mui/joy/Select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormGroup } from "@mui/material";
import "./Modal.css";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import * as Yup from "yup";


const MINIMUM_EDUCATION_GAP_MONTHS = 6;

const validationSchema = Yup.object().shape({
  
  title: Yup.string()
    .required("Title is required"),
  organization: Yup.string()
    .required("Organization is required"),
  subject: Yup.string()
    .required("Subject is required")
    .matches(/^[a-zA-Z\s]*$/, "Subject should not contain numbers"),
  location: Yup.string()
    .required("Location is required"),
  description: Yup.string()
    .required("Describe Experience."),
  ctc: Yup.number().required("CTC is required").integer("CTC must be a number"),
  standard: Yup.string()
    .required("Class is required")
    .matches(/^[a-zA-Z0-9\s]*$/, "Subject should not contain sign"),
  start_date: Yup.date()
    .required("Start date is required"),
    // .max(Yup.ref("end_date"), "Start date must be Before End date"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date must be after the start date")
    .max(new Date(), "End date must be today or before")
    .test(
      'min-gap',
      `The education period must be at least ${MINIMUM_EDUCATION_GAP_MONTHS} months long`,
      function (value) {
        const { start_date } = this.parent;
        if (!start_date || !value) {
          return true; // Skip this test if there are no dates set yet
        }
        const startDate = new Date(start_date);
        const endDate = new Date(value);

        // Calculate the difference in months
        const monthDifference =
          endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear());

        return monthDifference >= MINIMUM_EDUCATION_GAP_MONTHS;
      }
    ),
})

export default function AddExperience({
  open = false,
  setOpen,
  action,
  edit = false,
  experiences,
  getExperiences,
  data,
}) {
  const [isTillPresent, setIsTillPresent] = React.useState(false);
  const [filteredExperiences, setFilteredExperiences] = useState(experiences);


  const { userData, api } = useGlobalContext();
  const formik = useFormik({
    initialValues: {
      title: data?.title || "",
      description: data?.description || "",
      organization: data?.organization || "",
      ctc: data?.ctc || "",
      subject: data?.subject || "",
      standard: data?.standard || "",
      location: data?.location || "",
      employment_type: data?.employment_type || "",
      is_working: data?.is_working || "",
      start_date: data?.start_date || new Date().getDate(),
      end_date: data?.end_date || new Date().getDate(),
    },
    validationSchema,
    onSubmit: async (values) => {
      values = { ...values, user_id: userData?.id };
      let notify = toast.loading("Adding Experience...");
      try {
        if (edit) {
          const res = await api.put(
            `/app/candidates/experiences/${data?.id}`,
            values
          );
          console.log("PUT response:", res);
          if (res?.status === 200) {
            console.log("Update successful");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Updated Successfully");
            console.log("Calling getExperience after update");
            getExperiences();
          }
        } else {
          const res = await api.post(
            `/app/candidates/experiences/${userData?.id}`,
            values
          );
          if (res?.status === 200) {
            console.log("Successfully Added");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Successfully Added");
            formik.resetForm(); // Reset form values
            getExperiences();
          }
        }
      } catch (error) {
        console.log(error);
        toast.dismiss(notify);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    },
  });

  const handleDelete = async (id, title) => {
    setOpen(false)

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then ( async(result) => {
        if (result.isConfirmed) {
          try {

            const res = await api.delete(`/app/candidates/experiences/${id}`);
            if (res?.status === 200) {

              toast.success(`${title} successfully deleted!`);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              getExperiences();
        }
          } catch (error) {
            console.error("Error In Deletion : ",error)
          }
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to delete");
    }
  };

  useEffect(() => {
    setFilteredExperiences(experiences);
  }, [experiences]);

  const statusOptions = [
    { value: 'full_time', label: 'Full Time' },
    { value: 'part_time', label: 'Part Time' },
    { value: 'guest', label: 'Guest' },
  ];

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          layout="center"

          // sx={{ maxWidth: 500 }}
          // variant="plain"
        >
          <ModalClose />
          <div className="px-3 my-3">
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              fontWeight={"lg"}
            >
              {edit ? "Edit experience" : "Add new experience"}
            </Typography>
            <Typography
              id="basic-modal-dialog-description"
              textColor="text.tertiary"
              level="body3"
            >
              Share where you worked by this profile
            </Typography>
          </div>
          <FormGroup
            onSubmit={formik.handleSubmit}
            className="scroll-bar-hidden"
            sx={{
              overflow: "scroll",
              mx: "calc(-1 * var(--ModalDialog-padding))",
              px: "var(--ModalDialog-padding)",
            }}
          >
            <Stack spacing={2}>
              <div className="row px-3">
                <FormControl className="col">
                  <FormLabel>Title*</FormLabel>
                  <Input
                    autoFocus
                    required
                    placeholder="Eg - Manager"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.title && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.title && formik.errors.title}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Organisation*</FormLabel>
                  <Input
                    required
                    placeholder="ABC University"
                    name="organization"
                    value={formik.values.organization}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.organization && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.organization &&
                          formik.errors.organization}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
                <FormControl className="col">
                  <FormLabel>CTC*</FormLabel>
                  <Input
                    type="number"
                    required
                    placeholder="Eg - 5 LPA"
                    name="ctc"
                    value={formik.values.ctc}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.ctc && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.ctc && formik.errors.ctc}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col ">
                  <FormLabel>Subject</FormLabel>
                  <Input
                    required
                    placeholder="Eg - Mathematics"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.subject && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.subject && formik.errors.subject}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
                <FormControl className="col ">
                  <FormLabel>Class</FormLabel>
                  <Input
                    required
                    placeholder="Eg - Intermediate"
                    name="standard"
                    value={formik.values.standard}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.standard && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.standard && formik.errors.standard}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Location*</FormLabel>
                  <Input
                    required
                    placeholder="Bangalore"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.location && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.location && formik.errors.location}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
                <FormControl className="col ">
                  <FormLabel>Employment*</FormLabel>
                  <Select
                name="employment_type"
                options={statusOptions}
                value={statusOptions.find(option => option.value === formik.values.employment_type)}
                onChange={option => formik.setFieldValue("employment_type", option.value)}
                onBlur={formik.handleBlur}
              />
              {formik.errors.employment_type && formik.touched.employment_type && (
                <FormHelperText error>{formik.errors.employment_type}</FormHelperText>
              )}
                </FormControl>
              </div>
              <FormControl className="row">
                <div className="col">
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    minRows={3}
                    placeholder="Describe your responsibilities in concise statements led by strong verbs"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.description && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.description && formik.errors.description}
                      </FormHelperText>
                    )}
                  </div>
                  {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                </div>
              </FormControl>
              <div className="row">
                <Checkbox
                  className="col"
                  label="I am currently working in this role"
                  variant="outlined"
                  // onChange={(e) => {
                  //     setIsTillPresent(e.target.checked);
                  // }}
                  name="is_working"
                  value={formik.values.is_working}
                  onChange={formik.handleChange}
                  onError={formik.handleError}
                />
                {/* <div className="ml-3">
                    {formik.errors.is_working && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.is_working && formik.errors.is_working}
                      </FormHelperText>
                    )}
                  </div> */}
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Start date*</FormLabel>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className='rounded-3' views={['month', 'year']} />
                                    </LocalizationProvider> */}
                  <Input
                    type="date"
                    required
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.start_date && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.start_date && formik.errors.start_date}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
                <FormControl
                  className="col "
                  disabled={formik.values.is_working}
                >
                  <FormLabel>End date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.end_date && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.end_date && formik.errors.end_date}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
            </Stack>
          </FormGroup>
          <div className="row g-2 d-flex p-3 justify-content-between">
              {/* {edit && ( <Button > Delete </Button> )} */}
              {edit && (
                        <Button onClick={() => handleDelete(data?.id, data?.title)}>Delete</Button>
                    )}
            <Button
              type="submit"
              onClick={()=>{
                formik.handleSubmit()
                console.log("clicked", formik.errors)
              }
              }
            >
              {edit ? "Save changes" : "Add Experience"}
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
