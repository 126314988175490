import { Box, Avatar } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'global/context';

// Styled component with media queries for mobile and desktop
const MainChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 500px;
  padding: 1rem;

  @media (max-width: 768px) {
    height: 600px;
  }

  @media (max-width: 600px) {
    height: 500px;
  }

  .chat-box {
    flex-grow: 1;
    overflow-y: auto;
    height: 460px;

    @media (max-width: 768px) {
      height: 600px;
    }

    @media (max-width: 600px) {
      height: 500px;
    }

    &::-webkit-scrollbar {
      display: none; // Hide scrollbar for webkit browsers
    }
  }
`;

const Mainchat = ({ pd, roomData, handleSendMsg, allMsg, handleReply, msg, setMsg }) => {
  const { userData, api, showMessage, setShowMessage, socket } = useGlobalContext();

  const handleChange = (e) => {
    setMsg(e.target.value);
  };

  return (
    <MainChatContainer>
      {roomData ? (
        <div className="w-full h-full detailed-view p-3 ml-2 mt-2 shadow-sm rounded-3 border border-success flex flex-col">
          <div className="chat-box">
            {allMsg.map((items) => (
              <div key={items.id} className="w-full mb-3 p-3 shadow-sm border rounded-3 bg-light">
                <div className="d-flex align-items-center">
                  <Avatar
                    alt="image"
                    src={items.sender.avatar_url}
                    className="mr-3"
                    sx={{ width: '60px', height: '60px' }}
                  />
                  <div>
                    <p className="text-dark mb-1">
                      <strong>{items.sender.first_name + ' ' + items.sender.last_name}</strong>
                    </p>
                    <p className="fs-14 mb-0">{items.message.split('\n')[0]}</p> {/* Title */}
                    <p className="fs-11">{new Date(items.createdAt).toLocaleString()}</p> {/* Formatted Date */}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-1">
                    <strong>Details:</strong>
                  </p>
                  <p>{items.message.split('\n')[1]}</p>
                </div>
                <div className="w-full">
                  <div className="flex items-center">
                    <input
                      className="w-full bg-transparent placeholder:text-green-400 text-green-700 text-sm border border-green-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-green-500 hover:border-green-300 shadow-sm focus:shadow"
                      placeholder="Type here..."
                      value={msg}
                      onChange={handleChange}
                    />
                    <button
                      className="ml-2 rounded bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      onClick={handleReply}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="chat-page relative w-full h-[80vh] bg-[#fafafa] flex justify-center items-center">
          <div className="flex flex-col items-center justify-center w-full h-full bg-white rounded-3">
            <img className="w-[50px] h-[50px] rounded-full" src={pd?.logo} alt="User profile" />
            <h3 className="text-[#111b21] text-[20px] font-medium tracking-wide text-center">
              Welcome{' '}
              <span className="text-[#166e48] text-[19px] font-bold">{pd.name}</span>
            </h3>
          </div>
        </div>
      )}
    </MainChatContainer>
  );
};

export default Mainchat;
