import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box, TextField, Typography, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";
import { useGlobalContext } from "global/context";

function AdminRecoverPassword() {
  const navigate = useNavigate();
  const { api } = useGlobalContext();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email is required"),
  });

  const handleSubmit = async (values) => {
    try {
      await api.post("/admin/auth/recover", values);
      Popup("success", "Recovery email sent successfully");
      navigate("/auth/admin/login");
    } catch (error) {
      Popup("error", "Error sending recovery email");
    }
  };

  return (
    <Container fluid className="p-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
          <Typography variant="h4" gutterBottom textAlign={"center"} color="primary">
            Recover Password
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
                <Box textAlign="center" my={2}>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    Send Recovery Email
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Typography variant="body2" className="text-center">
            Remembered your password?{" "}
            <strong>
              <Link to="/auth/admin/login">Login</Link>
            </strong>
          </Typography>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminRecoverPassword;
