import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Paper from "@mui/material/Paper";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, Box, Button, Chip } from "@mui/joy";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "global/context";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { SubjectRounded } from "@mui/icons-material";
function createData(id, image, Title, Application, Time, status, Action) {
  return { id, image, Title, Application, Time, status, Action };
}

function JobsData({ jobs, getJobs, fontFamily,setjobloading }) {
  const { api } = useGlobalContext();
  const [currentjobId, setcurrentjobId] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const navigate = useNavigate();
  const handleDeleteJob = async (id) => {
    try {
      const res = await api.delete(`/app/jobs/delete/${id}`);
      if (res?.status === 200) {
        getJobs();
        toast.success("Job deleted successfully");
      }
    } catch (err) {
      toast.error("Error deleting job");
    }
    setdeleteModal(false);
  };
  const handleDeleteJobbox = (id) => {
    setcurrentjobId(id);
  };
  const handleClick = (item) => {
    if(!item.totalApplications && !item.jobCount){
      toast.error("No Applications")
      return;
    }
    if(item && item?.job_title){
      navigate(`/institute/jobs/Applications`, {
        state: {
          id: item?.id,
          title: item?.job_title,
        },
      });
    }else if(item?.jobCount){
      navigate(`/institute/jobs/AppliedUsers`, {
        state: {
          job:item
        },
      });
    } else {
      toast.error("No Applications");
    }
    setjobloading(false)
  }
  return (
    <TableContainer
      className="rounded shadow-sm"
      sx={{
        height: "100vh",
        maxHeight: "100vh",
        marginBlock: "1rem",
        overflow: "auto",
        '&::-webkit-scrollbar': {
          display:"none"
        },
      }}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: "rgb(133 0 255 / 17%)" }}>
          <TableRow>
            <TableCell>
              {" "}
              <h6
                className="font-weight-bold"
                style={{ fontFamily: fontFamily }}
              >
                Title
              </h6>
            </TableCell>
            <TableCell align="center">
              <h6
                className="font-weight-bold"
                style={{ fontFamily: fontFamily }}
              >
                Applications
              </h6>
            </TableCell>
            <TableCell align="center">
              {jobs[0]?.jobApplications && (
                <h6
                  className="font-weight-bold"
                  style={{ fontFamily: fontFamily }}
                >
                  Applied on
                </h6>
              )}
              {jobs[0]?.createdAt && (
                <h6
                  className="font-weight-bold"
                  style={{ fontFamily: fontFamily }}
                >
                  Created & Expired{" "}
                </h6>
              )}
            </TableCell>
            <TableCell align="center">
              <h6
                className="font-weight-bold"
                style={{ fontFamily: fontFamily }}
              >
                Status
              </h6>
            </TableCell>
            <TableCell align="center">
              <h6
                className="font-weight-bold"
                style={{ fontFamily: fontFamily }}
              >
                Action
              </h6>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs &&
            jobs?.map((item, index) => (
              <React.Fragment key={item?.Title}>
                <TableRow
                  key={item?.Title}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div
                      className="d-flex align-items-center  "
                      onClick={() => handleClick(item)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div>
                        <Avatar
                          alt={item?.job_title}
                          sx={{ width: "46px", height: "46px" }}
                        >
                          <SubjectRounded />
                        </Avatar>{" "}
                      </div>
                      <Typography
                        level="h5"
                        fontFamily={fontFamily}
                        fontWeight={"bold"}
                        className="ml-2"
                        sx={{
                          transition: "all 0.5s ease",
                          "&:hover": { color: "rgba(0, 0, 0, 0.5)" },
                        }}
                      >
                        {item?.user?.first_name || item?.user?.last_name
                          ? `${item?.user?.first_name || ""} ${
                              item?.user?.last_name || ""
                            }`.trim()
                          : item?.job_title}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Button
                      variant="soft"
                      size="sm"
                      color="neutral"
                      // onClick={() => toggleRowExpansion(row?.Title)}
                      onClick={() => handleClick(item)}
                      disabled={
                        item?.totalApplications || item?.jobCount ? false : true
                      }
                    >
                      {item?.totalApplications || item?.jobCount} Applied
                    </Button>
                  </TableCell>
                  {item?.createdAt ? (
                    <TableCell align="center" sx={{ fontFamily: fontFamily }}>
                      {item && item.createdAt
                        ? item.createdAt?.substring(0, 10)
                        : ""}{" "}
                      &
                      {item && item.expiry_date
                        ? item.expiry_date?.slice(0, 10)
                        : ""}
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      {item?.jobApplications?.map((job, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          color="textSecondary"
                        >
                          {job?.createdAt?.substring(0, 10)}
                        </Typography>
                      ))}
                    </TableCell>
                  )}
                  {item?.status ? (
                    <TableCell align="center">
                      <Chip
                        variant="soft"
                        color={
                          item?.status === "active"
                            ? "success"
                            : item?.status === "inactive"
                            ? "warning"
                            : item?.status === "expired"
                            ? "danger"
                            : item?.status === "renewed"
                            ? "success"
                            : item?.status === "cancelled"
                            ? "danger"
                            : "neutral"
                        }
                        className="fw-bold text-capitalize"
                      >
                        {item?.status}
                      </Chip>
                    </TableCell>
                  ) : (
                    <TableCell align="center" sx={{ fontFamily: fontFamily }}>
                      {item?.jobApplications?.map((job, index) => (
                        <div className="d-flex align-items-center justify-content-center mt-1">
                          <Chip
                            variant="soft"
                            color={
                              job?.status === "hired"
                                ? "success"
                                : job?.status === "hold"
                                ? "warning"
                                : job?.status === "rejected"
                                ? "danger"
                                : job?.status === "reviewed"
                                ? "success"
                                : "neutral"
                            }
                            className="fw-bold text-capitalize"
                          >
                            {job?.status}
                          </Chip>
                        </div>
                      ))}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <div>
                      <IconButton
                        onClick={() => {
                          localStorage.setItem("jobId", item?.id);
                          navigate(
                            `/institute/jobs/Applications/ApplicationDetail`,
                            {
                              state: {
                                id: item?.id,
                              },
                            }
                          );
                        }}
                      >
                        <VisibilityIcon className="fs-17" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setdeleteModal(true);
                          handleDeleteJobbox(item?.id);
                        }}
                      >
                        <DeleteIcon
                          className="fs-27"
                          sx={{
                            backgroundColor: "#FC3F3E",
                            color: "#fff",
                            padding: "5px",
                            borderRadius: "50px",
                            marginLeft: "0.4rem",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#FA6867",
                              scale: "1.1",
                              color: "#262626",
                            },
                            transition: "all 0.5s ease",
                          }}
                        />
                      </IconButton>
                      {/* This is delete Teacher modal */}
                      <Modal
                        open={deleteModal}
                        onClose={() => {
                          setdeleteModal(false);
                        }}
                      >
                        <ModalDialog sx={{ maxWidth: "500px" }}>
                          <Typography level="h5">
                            Are you sure you want to delete this Job?
                          </Typography>
                          <Button
                            onClick={() => {
                              handleDeleteJob(currentjobId);
                            }}
                            variant="danger"
                            sx={{
                              backgroundColor: "#FC3F3E",
                              color: "#fff",
                              marginBlock: "0.5rem",
                              padding: "5px",
                              borderRadius: "50px",
                              marginLeft: "0.4rem",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#FA6867",
                                scale: "1.01",
                                color: "#262626",
                              },
                              transition: "all 0.5s ease",
                            }}
                          >
                            Confirm
                          </Button>
                          <Button
                            onClick={() => {
                              setdeleteModal(false);
                            }}
                            variant="soft"
                            sx={{
                              backgroundColor: "#0daab3",
                              color: "#fff",
                              padding: "5px",
                              borderRadius: "50px",
                              marginLeft: "0.4rem",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#0daab1",
                                scale: "1.01",
                                color: "#262626",
                              },
                              transition: "all 0.5s ease",
                            }}
                          >
                            Cancel
                          </Button>
                        </ModalDialog>
                      </Modal>
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default React.memo(JobsData)