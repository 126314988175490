import React,{useState} from 'react'
import { useNavigate } from "react-router-dom";

const truncateTitle = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const SmallJobCard = ({ jd }) => {
  const navigate = useNavigate();
  const truncatedTitle = truncateTitle(jd?.job_title, 16);
  const truncatedDescription = truncateText(jd?.job_description, 30); // 30 कैरेक्टर्स के लिए ट्रंकेट
  const formattedDate = formatDate(jd?.expiry_date);
  const [showDate, setShowDate] = useState(false);

  return (
    <>
      <div
        onClick={() =>
          navigate(`/careers/job/${jd?.slug}`, {
            state: {
              id: jd?.id,
            },
          })
        }
        style={{ cursor: 'pointer' }}
      >
        <h2 style={{ fontSize: '12px', fontWeight: 'bold' }}>
          {truncatedTitle}
        </h2>
        <p style={{ fontSize: '11px', color: 'black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        dangerouslySetInnerHTML={{__html: truncatedDescription}}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ fontSize: '10px', fontWeight: 'bold', color: '#666' }}>
    Vacancies - {jd?.vacancies}
  </div>
  <div style={{ fontSize: '8px', fontWeight: 'bold', color: '#666', position: 'relative' }}>
          <button
            style={{ fontSize: '8px', fontWeight: 'bold', color: '#666', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', padding: 0 }}
            onMouseEnter={() => setShowDate(true)}
            onMouseLeave={() => setShowDate(false)}
          >
            Last Date
          </button>
          {showDate && (
            <div
              style={{
                fontSize: '8px',
                fontWeight: 'bold',
                color: '#666',
                position: 'absolute',
                bottom: '100%',
                left: 0,
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                padding: '2px',
                zIndex: 1,
              }}
            >
              {formattedDate}
            </div>
          )}
        </div>
</div>
    </>
  );
};

export default SmallJobCard;
