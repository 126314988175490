import React, { useState } from "react";
import Sidebar from "./components/SupSidebar";
import Header from "./components/SupHeader";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { AdminProvider } from "./SuperAdminContext";

const Superadmin = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <AdminProvider>
      <div>
        <Box
          component="main"
          sx={{
            transition: "margin-left 0.3s",
            minHeight: "calc(100vh - 64px)", // Adjust based on your header height
          }}
          className="Superadmin-layout d-flex"
        >
          <div style={{ marginRight:3}}>
            <Sidebar open={sidebarOpen} />
          </div>
          <div className="Superadmin-content" style={{ flexGrow: 1 }}>
            <Outlet />
          </div>
        </Box>
      </div>
    </AdminProvider>
  );
};

export default Superadmin;
