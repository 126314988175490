import { Divider } from "@mui/joy";
import React from "react";
import { useEffect } from "react";

function Termsofservice() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <>
      <div className="container my-4">
        <div className="  p-5 ">
          <h1 className="text-center uppercase">TERMS OF SERVICE</h1>
          <h6 className="mb-5 text-center">(Updated on 27th June, 2023)</h6>
          <Divider className="my-4"/>
          <h4 className="mb-4">Terms of Service Overview</h4>
          <p>
            Below is an overview of our Terms of Service for our “Platform”,
            which means any website, application, or service we offer. You
            should read the complete Terms of Service because that document (and
            not this overview) is our legally binding agreement. The Terms of
            Service includes information about your legal rights and covers
            areas such as automatic subscription renewals, limitations of
            liability, resolution of disputes by mandatory arbitration rather
            than a judge or jury in a court of law, and a class action waiver.
          </p>
          <h5 className="mb-2 mt-4">Your Relationship with ekSathi</h5>
          <ul>
            <li>
              By using our Platform, you are agreeing to our Terms of Service. The
              Terms of Service are a legally binding agreement between you and
              ekSathi.
            </li>
            <li>
              If you break the rules, we may suspend or terminate your account
            </li>
            <li>
              We may charge for certain aspects of our Platform, and some of these
              fees may be billed on a regular and recurring basis (unless you
              disable auto-renewal or cancel your subscription).
            </li>
            <li>
              If you do not or are unable to agree to this Agreement, do not use
              our Platform.
            </li>
          </ul>
          <h5 className="mb-2 mt-4">ekSathi Members Should take care</h5>
          <li>
            Using our Platform involves meeting real people and doing real
            things in the real world, which can sometimes lead to unexpected
            situations. We can’t control what happens in the real world, and we
            are not responsible for it. You should use common sense and good
            judgment when interacting with others.
          </li>
          <h5 className="mb-2 mt-4">Your Content and Content of Others</h5>
          <li>
            You are responsible for your “Content”, which means any information,
            material, or other content posted to our Platform. Your Content must
            comply with our Terms of Service, which includes the Usage and
            Content Policies, Groups and Events Policies, Organizer and
            Leadership Standards, Member Restrictions, Payment
            Policies, Trademark Usage Guidelines, and API License Terms. Your
            Content is also subject to our Intellectual Property Dispute
            Policies.
          </li>
          <li>
            We do not own the Content that you post. However, we do require
            that you provide us a license to use this Content in order for us to
            operate, improve, promote, and protect eksathi and our Platform for
            the benefit of you and others.
          </li>
          <li>
            We are not responsible for Content that members post or the
            communications that members send using our Platform. We generally
            don’t review Content before it’s posted. If you see Content that
            violates our Terms of Service, you may report inappropriate
            Content to us.
          </li>
          <h5 className="mb-2 mt-4">Our Platform</h5>
          <li>
            We try hard to make sure that our Platform is always available and
            working, but we cannot guarantee it will be. Occasionally things may
            not go exactly as planned. We apologize in advance for any
            inconvenience.
          </li>
          <li>
            We are continually improving our Platform. This means that we may
            modify or discontinue portions of our Platform
          </li>
          <li>
            By using our Platform, you agree to the limitations of liability and
            release in our Terms of Service. You also agree to resolve any
            disputes you may have with us in the manner described in the Terms
            of Service. You agree to mandatory arbitration and waive your right
            to seek relief from a judge or jury in a court of law. Claims can
            only be brought individually, and not as part of a class action.
          </li>
          <h3>Terms of Service</h3>
          <p>
            Our Terms of Service contain important information about your legal
            rights. To make it easier to understand, we have provided summaries
            and tips below. These summaries are not part of the official terms,
            and it is important for you to read the language in each section
            carefully.
          </p>
          <h4 className="mb-4">1. This Agreement</h4>
          <h5 className="mb-2 mt-4">
            Summary:
            <p>
              You agree to follow some basic rules when using ekSathi’s
              Platform. These rules are described in these Terms of Service,
              including the related policies and guidelines discussed below. We
              may change these rules.
            </p>
          </h5>
          <h5 className="mb-2 mt-4">
            1.1 The Agreement.
            <p>
              ekSathi enables you and other members to arrange offline,
              real-world EkSathi groups and EkSathi events. The terms “EkSathi,”
              “we,” “us,” and “our” include EkSathi, Inc. and our corporate
              parent, affiliates, or subsidiaries. We use the terms “you” and
              “your” to mean any person using our Platform, and any organization
              or person using the Platform on an organization’s behalf. We use
              the word “Platform” to mean any website, application, or service
              offered by EkSathi, including content we offer and electronic
              communications we send. We provide our Platform to you subject to
              these Terms of Service. We use the terms “Terms of Service” and
              “Agreement” interchangeably to mean this document together with
              our Usage and Content Policies, Groups and Events
              Policies, Organizer and Leadership Standards, Member
              Restrictions, Payment Policies, Trademark Usage Guidelines,
              and API License Terms. Your use of the Platform signifies that you
              agree to this Agreement. If you are using the Platform for an
              organization, you agree to this Agreement on behalf of that
              organization, and represent you have authority to bind that
              organization to the terms contained in this Agreement. If you do
              not or are unable to agree to this Agreement, do not use our
              Platform.
            </p>
          </h5>
          <h5 className="mb-2 mt-4">
            Tip:{" "}
            <p>
              We have included summaries and tips to provide an overview of some
              legal concepts and answer common questions, but the text of these
              summaries and tips are for your convenience only and do not have a
              legal effect.
            </p>
          </h5>
          <h5 className="mb-2 mt-4">
            Tip:
            <p>
              These Terms of Service are an agreement between you and EkSathi
              and not between you and any other member. For more information,
              see our details mentioned.
            </p>
          </h5>
          <h5 className="mb-2 mt-4"> 1.2 Revisions to this Agreement. </h5>{" "}
          <p>
            We may modify this Agreement from time to time. Please visit these
            terms before using our platform. By continuing to use the Platform
            after any changes come into effect, you agree to the revised
            Agreement. If you do not wish to accept the revised Agreement, you
            can close your account.
          </p>
          <h5 className="mb-2 mt-4">2. Your Account and Membership</h5>
          <h5 className="mb-2 mt-4">Summary:</h5>
          <p>
            You need to be at least 18 years old to use our Platform. EkSathi
            organizers control the Content and membership of their EkSathi
            groups. However, we may remove any Content you post or terminate
            your account at any time.
          </p>
          <h5 className="mb-2 mt-4">2.1 Eligibility. </h5>
          <p>
            Our Platform is available to anyone who is at least 18 years old.
            You represent that you are at least 18. Additional eligibility
            requirements for a particular portion of our Platform may be set by
            any member who has the ability to moderate or manage that portion of
            our Platform. For example, the eligibility requirements for aEkSathi
            group or EkSathi event may be set by the organizers of that group.
          </p>
          <h5 className="mb-2 mt-4">
            2.2 Modification, Suspension, and Termination of Your Account.
          </h5>
          <p>
            We may modify, suspend, or terminate your account or access to the
            Platform if, in our sole discretion, we determine that you have
            violated this Agreement, including any of the policies or guidelines
            that are part of this Agreement, that it is in the best interest of
            the EkSathi community, or to protect our brand or Platform. When
            this happens, we will notify you of the reasons for the
            modification, suspension, or termination. We also may remove
            accounts of members who are inactive for an extended period of time.
            Please email policy@ekSathi.com if you believe the modification,
            suspension, or termination has occurred in error.
          </p>
          <p>
            A member who has the ability to moderate or manage a particular
            portion of our Platform also has the ability, in his or her sole
            discretion, to modify, suspend, or terminate your access to that
            portion of the Platform.
          </p>
          <h5 className="mb-2 mt-4">2.3 Account Information and Security. </h5>
          <p>
            When you register, you provide us with some basic information,
            including an email address and a password. Keep your email address
            and other account information current and accurate. Also, you agree
            to maintain the security and confidentiality of your password (or
            else we may need to disable your account). We strongly encourage you
            to choose a strong and unique password that is not shared with any
            other account or online service and practice other healthy password
            security habits to help avoid unauthorized access to your
            account. You alone are responsible for anything that happens from
            your failure to maintain that security and confidentiality, such as
            by sharing your account credentials with others. If someone is using
            your password or accessing your account without your
            permission, email us at policy@ekSathi.com.
          </p>
          <h5 className="mb-2 mt-4">2.4 License to the EkSathi Platform and Services. </h5>
          <p>
            Subject to your compliance with this Agreement, EkSathi grants you a
            limited, non-exclusive, revocable, non-sublicensable,
            non-transferable right to use the Platform in order to access and
            use the services and features that we make available to you.
          </p>
          <h5 className="mb-2 mt-4">3. Fees, Payments, and Offers</h5>
          <h5 className="mb-2 mt-4">Summary: </h5>
          <p>
            We may charge for certain features on our Platform. If you’re a
            EkSathi group organizer, you’re responsible for paying for organizer
            subscription fees related to your EkSathi group. These fees are
            billed on a regular and recurring basis (unless you disable
            auto-renewal or cancel your subscription), and may change in the
            future. Additionally, some organizers charge money for participation
            in aEkSathi group or EkSathi event.
          </p>
          <h5 className="mb-2 mt-4">3.1 Fees Charged by EkSathi. </h5>
          <p>
            Use of some of the features on our Platform is free, and we may
            charge fees for other features. We may in the future implement a new
            fee, or modify an existing fee, for certain current or future
            features of our Platform. If we implement a new or modified fee, we
            will give you notice in advance such as by posting changes on our
            Platform or sending you an email. You agree to pay those fees and
            any associated taxes for your continued use of the applicable
            service. Unless otherwise stated, all fees and all transactions are
            in Inidan Rupees (INR). Organizer subscriptions are
            non-transferable. Members based in the European Union may cancel
            with a full refund within 14 days after signing up to our Platform.
            However, if you use the service, you may be charged for services
            provided during this 14-day period. Please note that this 14-day
            period begins when the service starts even if you are using a free
            trial. Users based in the European Union can exercise their
            cancellation right by emailing us at policy@ekSathi.com.{" "}
          </p>
          <h5 className="mb-2 mt-4">3.2 Fees Charged by Members. </h5>
          <p>
            Members may impose fees related to particular service you are
            taking. Eksathi do no hold any responsibility for the transactions
            made to any member directly or indirectly. Our platform do not offer
            any service of money transactions between users. We also do not
            provide any assurance for the services taken. User has to use his
            common sense for any transactions made or services use.
          </p>
          <h5 className="mb-2 mt-4">Tip: </h5>
          <p>
            You should use common sense in deciding whether to make a payment to
            any member. We cannot ensure that member will use payments as they
            promise or as you expect. You should check the refund policy with
            member directlybefore making any payments to amember.
          </p>
          <h5 className="mb-2 mt-4">3.3 Free Trials. </h5>
          <p>
            We may offer free trials of subscriptions and other products on our
            Platform. We will inform you of the length of the free trial, your
            renewal period, and the date and amount of your first payment. After
            your free trial ends, your paid subscription will begin and we will
            automatically bill you for each renewal period until cancellation.
            You can cancel automatic subscription renewals at any time according
            to the terms and procedures described above. Users based in the
            European Union are entitled to cancel with a full refund within 14
            days after signing up to our Platform by emailing us at
            policy@ekSathi.com. Please note that this 14-day period begins when
            the free trial starts.
          </p>
          <h3>4. Your Content and Privacy</h3>
          <h5 className="mb-2 mt-4">Summary: </h5>
          <p>
            You are responsible for the Content you post on our Platform or send
            to us. You give us a license to use this Content to operate,
            improve, promote, and protect EkSathi and our Platform. Our Privacy
            Policy explains how we collect and use information you provide about
            yourself and the information that may be associated with you, and
            explains the limited ways we may share this information.
          </p>
          <h5 className="mb-2 mt-4">4.1 Your Content. </h5>
          <p>
            You are responsible for the Content that you post to the Platform or
            otherwise provide to EkSathi. We use the word “Content” to mean the
            information, material, and any other content that you post to the
            Platform or otherwise send to us. Examples of your Content include:
          </p>
          <li>
            The material that organizers and members typically post to the
            Platform, such as information about EkSathi groups and EkSathi
            events, comments, and photos;
          </li>
          <li>
            All feedback, suggestions, and other communications that you send or
            direct to EkSathi.
          </li>
          <p>
            By being responsible for your Content, you agree, among other
            things, that:
          </p>
          <p>
            You have all the permissions, rights, and licenses needed (including
            under copyrights, trademarks, contract rights, privacy rights, or
            publicity rights) to provide the Content to the Platform and to
            EkSathi;
          </p>
          <p>
            Your Content does not include personal, private or confidential
            information belonging to others; and
          </p>
          <p>
            Your Content does not otherwise violate the rights of any individual
            or entity.
          </p>
          <p>
            You also agree that we may remove the metadata associated with your
            Content.
          </p>
          <h5 className="mb-2 mt-4">Tip: </h5>
          <p>
            Search engines can see public areas of the Platform. Your Content
            within these areas, such as the name and location that you provide
            during registration, and your membership in public EkSathi groups,
            may appear in search results.
          </p>
          <h5 className="mb-2 mt-4">4.2 Content License from You. </h5>
          <p>
            We do not claim ownership of your Content. However, to enable us to
            operate, improve, promote, and protect EkSathi and our Platform, and
            to ensure we do not violate any rights you may have in your Content,
            you hereby grant EkSathi a non-exclusive, worldwide, perpetual,
            irrevocable, royalty-free, sublicensable, transferable right and
            license (including a waiver of any moral rights) to use, host,
            store, reproduce, modify, publish, publicly display, publicly
            perform, distribute, and create derivative works of, your Content
            and to commercialize and exploit the copyright, trademark,
            publicity, and database rights you have in your Content. This
            license would permit your Content to remain on the Platform, even
            after you cease to be a member of a EkSathi group or of the
            Platform.
          </p>
          <h5 className="mb-2 mt-4">Tip: </h5>
          <p>
            This license doesn’t give us ownership of any of your Content. It
            simply gives us and members the right to use it on or related to the
            Platform. This license continues even if you close your account,
            because it’s necessary for us to operate the Platform.
          </p>
          <h5 className="mb-2 mt-4">4.3 Privacy. </h5>
          <p>
            EkSathi collects registration and other information about you
            through our Platform. Please refer that you agree for details on how
            we collect, use, and disclose this information. These policies do
            not govern use of information that you provide to third parties,
            such as to organizers and other members of EkSathi’s Platform.
          </p>
          <h5 className="mb-2 mt-4">Tip: </h5>
          <p>
            EkSathi has no control over how other members may use information
            that you provide to them, so you should exercise common sense and
            good judgment when sharing information with others on our Platform.
          </p>
          <h3>5. Your Use of Our Platform</h3>
          <p>
            Summary: We require that you follow our policies and guidelines when
            using our Platform. We have no responsibility for Content that
            anyone posts to our Platform.
          </p>
          <h5 className="mb-2 mt-4">5.1 Our Policies, Guidelines and Applicable Laws. </h5>
          <p>
            You also agree to comply with all applicable laws, rules and
            regulations, and to not violate or infringe the rights of any third
            party. In addition, your access to and use of some areas or features
            of the Platform may be subject to additional terms, policies,
            standards or guidelines (“Additional Terms”). You may be required to
            accept these Additional Terms before you can access these Platform
            areas and features. If there is a conflict between the Terms of
            Service and the Additional Terms, those Additional Terms will govern
            your access to and use of that Platform area or feature, unless
            otherwise specified in the Additional Terms. When the Platform uses
            third party services to provide certain features and services for
            our members, our members may be required to comply with the terms of
            service that apply to these features and services. For example, some
            areas of our Platform feature mapping services provided by Google
            Maps and Google Earth. When you use these mapping services on the
            Platform, you agree to be bound by the Google Maps/Google Earth
            Additional Terms of Service. If you do not comply, we may modify,
            suspend, or terminate your account or access to the Platform, in our
            sole discretion, and we will provide you with reasons for the
            modification, suspension, or termination. Please email
            policy@ekSathi.com if you believe the modification, suspension, or
            termination has occurred in error.
          </p>
          <p>
            <h5 className="mb-2 mt-4">5.2 Content of Others. </h5>EkSathi does not control the Content of other
            members. When we become aware of inappropriate Content on our
            Platform, we reserve the right to investigate and take appropriate
            action, but we do not have any obligation to monitor, nor do we take
            responsibility for, the Content of other members.
          </p>
       
        </div>
      </div>
    </>
  );
}

export default Termsofservice;
