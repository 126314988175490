import React from 'react';

const EmployerCoC = () => {
    return (
        <>
            <div className="container p-5"><div><h1 className='my-3 fw-bold'>Employer TnCs/Employer Code of Conduct</h1>
                <hr />
                    <p><strong>User Agreement with Employers</strong></p>
                    <p>This is a user agreement between 'EkSathi' and the Individual (hereinafter referred to as ‘employer’) posting a job on the EkSathi employer platform - (employer.eksathi.com). Our services are designed to promote economic opportunity for the users by helping them find the existing job vacancies.</p>
                    <h2 className='my-3 fw-bold'>1. Introduction</h2>
                    <h3 className='my-3 fw-bold'>1.1 Contract</h3>
                    <p>You may use our Services only if you agree to the terms contained hereunder. Your use of our Services is also subject to <a href="http://eksathi.com/privacy">Privacy Policy</a>, which covers how we collect, use, share, and store your personal information.
                        You agree that by clicking "Post a Job" or similar buttons indicating your willingness to use the EkSathi platform, registering, accessing or using our services (described below), <strong>you are agreeing to enter into a legally binding contract</strong> with EKSATHI (even if you are using our Services on behalf of a company). If you do not agree to this contract ("Contract" or "User Agreement"), do <strong>not</strong> click "Post a Job" (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
                    <h3 className='my-3 fw-bold'>Services</h3>
                    <p>This Contract applies to EKSATHI.com, EKSATHI-branded apps and other EKSATHI-related sites, apps, communications and other services that state that they are offered under this Contract ("Services"), including the offsite collection of data for those Services, such as our ads and the "Apply with EKSATHI" and "Share with EKSATHI" plugins. Registered users of our Services are "Members" and unregistered users are "Visitors".</p>
                    <h3 className='my-3 fw-bold'>EKSATHI</h3>
                    <p>You are entering into this Contract with EKSATHI (also referred to as "we" and "us").
                        We use the term "Designated Country" to refer to India.
                        If you reside in the "Designated Country", you are entering into this Contract with Govardhan Learning Cloud Pvt Ltd. ("EKSATHI India") and EKSATHI India will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services.
                        This Contract applies to Members and Visitors.
                        As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this <a href="http://eksathi.com/privacy">Privacy Policy</a> (which includes other documents referenced in this Privacy Policy) and updates.</p>
                    <h3 className='my-3 fw-bold'>1.2 Members and Visitors</h3>
                    <p>When you register and join the EKSATHI Service or become a registered user, you become a Member. If you have chosen not to register for our Services, you may access certain features as a "Visitor."</p>
                    <h3 className='my-3 fw-bold'>1.3 Modification</h3>
                    <p>We may modify this Contract, and our Privacy Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.</p>
                    <h2 className='my-3 fw-bold'>2. Obligations</h2>
                    <h3 className='my-3 fw-bold'>2.1 Service Eligibility</h3>
                    <p>You're eligible to enter into this Contract and you are at least our "Minimum Age."
                        The Services are not for use by anyone under the age of 18.
                        To use the Services, you agree that: (1) you must be the "<em>Minimum Age</em>" (described below) or older; (2) your account must be in your real name and your own phone number; and (3) you are not already restricted by EKSATHI from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 18.
                        "Minimum Age" means 18 years old. However, if law requires that you must be older in order for EKSATHI to lawfully provide the Services to you without parental consent (including using of your personal data) then the Minimum Age is such older age.
                        You also confirm you are an authorised representative of the ‘Company’ on behalf of whom you are hiring and are not an imposter.</p>
                    <h3 className='my-3 fw-bold'>2.2 Your Account</h3>
                    <p>Your account should be kept confidential at all times and should be compromised in any manner.
                        You are responsible for anything that happens through your account unless you close it or report misuse.
                        As between you and others (including your employer), your account belongs to you. However, if the Services were purchased by another party for you to use (e.g. Recruiter seat bought by your employer), the party paying for such Service has the right to control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account.</p>
                    <h3 className='my-3 fw-bold'>2.3 Notices and Messages</h3>
                    <p>You're okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.
                        You agree that we will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.</p>
                    <h3 className='my-3 fw-bold'>2.4 Sharing</h3>
                    <p>When you share information on our Services, others can see, copy and use that information.
                        Our Services allow messaging and sharing of information in many ways, such as your profile, articles, group posts, links to news articles, job postings, messages and one one one messages. Information and content that you share or post may be seen by other Members, Visitors or others (including off of the Services). Where we have made settings available, we will honor the choices you make about who can see content or information (e.g., message content to your addressees, sharing content only to EKSATHI connections, restricting your profile visibility from search engines, or opting not to notify others of your EKSATHI profile update). For job searching activities, we default to not notifying your connections network or the public. So, if you apply for a job through our Service or opt to signal that you are interested in a job, our default is to share it only with the job poster.
                        We are not obligated to publish any information or content on our Service and can remove it with or without notice.</p>
                    <h2 className='my-3 fw-bold'>3. Rights and Limits</h2>
                    <h3 className='my-3 fw-bold'>3.1. Your License to EKSATHI</h3>
                    <p>You own all of the content, feedback and personal information you provide to us, but you also grant us a non-exclusive license to it.
                        We'll honor the choices you make about who gets to see your information and content, including how it can be used for ads.
                        As between you and EKSATHI, you own the content and information that you submit or post to the Services, and you are only granting EKSATHI and our <em>affiliates</em> the following non-exclusive license:
                        A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:</p>
                    <ol>
                        <li>You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</li>
                        <li>We will not include your content (excluding content shared in groups) in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your <em>social actions</em> may be visible and included with ads, as noted in the Privacy Policy. If you use a Service feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.</li>
                        <li>We will get your consent if we want to give others the right to publish your content beyond the Services. However, if you choose to share your post as "<em>public</em>", we will enable a feature that allows other Members to embed that public post onto third-party services, and we enable search engines to make that public content findable though their services.</li>
                        <li>While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</li>
                        <li>Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others, including under the terms of a <a href="https://creativecommons.org/choose/">Creative Commons license</a>.</li>
                    </ol>
                    <p>You and EKSATHI agree that if content includes personal data, it is subject to our Privacy Policy.
                        You and EKSATHI agree that we may access, store, process and use any information and personal data that you provide in accordance with, the terms of the <a href="http://eksathi.com/privacy">Privacy Policy</a> and your choices (including settings).
                        By submitting suggestions or other feedback regarding our Services to EKSATHI, you agree that EKSATHI can use and share (but does not have to) such feedback for any purpose without compensation to you.
                        You promise to only provide information and content that you have the right to share, and that your EKSATHI profile will be truthful.
                        You agree to only provide content or information that does not violate the law nor anyone's rights (including intellectual property rights). You also agree that your profile information will be truthful. EKSATHI may be required by law to remove certain information or content in certain countries.</p>
                    <h3 className='my-3 fw-bold'>3.2 Service Availability</h3>
                    <p>We may change or end any Service or modify our prices prospectively.
                        We may change, suspend or discontinue any of our Services. We may also modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.
                        We don't promise to store or keep showing any information and content that you've posted. EKSATHI is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</p>
                    <h3 className='my-3 fw-bold'>3.3 Other Content, Sites and Apps</h3>
                    <p>Your use of others' content and information posted on our Services, is at your own risk.
                        Others may offer their own products and services through our Services, and we aren't responsible for those third-party activities.
                        In order to engage proactively with all the users, we have put in place a system where at all times, either bots or customer service personnel ("Facilitator") are available to address the concerns of the users.
                        By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. EKSATHI generally does not review content provided by our Members or others. You agree that we are not responsible for others' (including other Members') content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or your organization may be mistakenly associated with content about others when we let connections and followers know you or your organization were mentioned in the news.
                        EKSATHI may help connect Members offering their services (career coaching, accounting, etc.) with Members seeking services. EKSATHI does not perform nor employs individuals to perform these services. You must be at least 18 years of age to offer, perform or procure these services. You acknowledge that EKSATHI does not supervise, direct, control or monitor Members in the performance of these services and agree that (1) EKSATHI is not responsible for the offering, performance or procurement of these services, (2) EKSATHI does not endorse any particular Member's offered services, and (3) nothing shall create an employment, agency, or joint venture relationship between EKSATHI and any Member offering services. If you are a Member offering services, you represent and warrant that you have all the required licenses and will provide services consistent with our Professional Community Guidelines.
                        Similarly, EKSATHI may help you register for and/or attend events organized by Members and connect with other Members who are attendees at such events. You agree that (1) EKSATHI is not responsible for the conduct of any of the Members or other attendees at such events, (2) EKSATHI does not endorse any particular event listed on our Services, (3) EKSATHI does not review and/or vet any of these events, and (4) that you will adhere to these terms and conditions that apply to such events.</p>
                    <h3 className='my-3 fw-bold'>3.4 Limits</h3>
                    <p>We may restrict, suspend, or terminate your account if you breach this Contract or the law or are misusing the Services (e.g., violating any of the Dos and Don'ts or Professional Community Guidelines).</p>
                    <h3 className='my-3 fw-bold'>3.5 Intellectual Property Rights</h3>
                    <p>We're providing you notice about our intellectual property rights.
                        EKSATHI reserves all of its intellectual property rights in the Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. EKSATHI, and "in" logos and other EKSATHI trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of EKSATHI.</p>
                    <h3 className='my-3 fw-bold'>3.6 Automated Processing</h3>
                    <p>We use data and information about you to make relevant suggestions to you and others.
                        We use the information and data that you provide and that we have about Members to make recommendations for connections, content and features that may be useful to you. For example, we use data and information about you to recommend jobs to you and you to recruiters. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.</p>
                    <h3 className='my-3 fw-bold'>3.7 Contacting You</h3>
                    <p>By posting your job with EkSathi, you authorise us to contact you as and when we feel the need on the phone number(s) and email provided by you. These modes of communication can be through a call, SMS, WhatsApp or any other third party service.</p>
                    <h3 className='my-3 fw-bold'>3.8 Daily WhatsApp for Business Notifications</h3>
                    <p>We may contact you to share notifications, updates and instructions regarding your job posting through our WhatsApp for Business account. These messages will directly delivered to your WhatsApp registered number. You give us explicit permission to contact you on your WhatsApp-registered number as per our discretion.</p>
                    <h2 className='my-3 fw-bold'>4. Disclaimer and Limit of Liability</h2>
                    <h3 className='my-3 fw-bold'>4.1 No Warranty</h3>
                    <p>This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.
                        EKSATHI AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, EKSATHI AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THE USER FEEDS INFORMATION/DATA INTO THE NETWORK AND AS SUCH EKSATHI TIME HAS TO CONDUCTED ANY SEARCH TO CHECK WHETHER THIS CONTAINS ANY FALSE, DISTORTED, MANIPULATED, FRAUDULENT OR MISLEADING FACTS.</p>
                    <h3 className='my-3 fw-bold'>4.2 Exclusion of Liability</h3>
                    <p>These are the limits of legal liability we may have to you.
                        TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS EKSATHI HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), EKSATHI, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
                        EKSATHI AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO EKSATHI FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $100.</p>
                    <h3 className='my-3 fw-bold'>4.3 Basis of the Bargain; Exclusions</h3>
                    <p>The limitations of liability in this Section 4 are part of the basis of the bargain between you and EKSATHI and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if EKSATHI or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.
                        These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.</p>
                    <h2 className='my-3 fw-bold'>5. Termination</h2>
                    <p>We can each end this Contract, but some rights and obligations survive.
                        Both you and EKSATHI may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. The following shall survive termination:</p>
                    <ul>
                        <li>Our rights to use and disclose your feedback;</li>
                        <li>Members and/or Visitors' rights to further re-share content and information you shared through the Services;</li>
                        <li>Sections 4, 6, 7, and 8.2 of this Contract;</li>
                        <li>Any amounts owed by either party prior to termination remain owed after termination.</li>
                    </ul>
                    <h2 className='my-3 fw-bold'>6. Governing Law and Dispute Resolution</h2>
                    <p>In case of a dispute you and EKSATHI agree to resolve it in California courts using California law, or India courts using Indian law.
                        This section shall not deprive you of any mandatory consumer protections under the law of the country to which we direct Services to you, where you have your habitual residence. <strong>If you live in the</strong> <em><strong>Designated Country</strong></em>: You and EKSATHI India agree that the laws of India, excluding conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services. You and EKSATHI India agree that claims and disputes can be litigated only in India, and we each agree to personal jurisdiction of the courts located in India.</p>
                    <h2 className='my-3 fw-bold'>7. General Terms</h2>
                    <p>Here are some important details about the Contract.
                        In case any provision of the Contract is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such invalidity, illegality or unenforceability.
                        EkSathi does not monitor the communication between you and any prospective jobseekers - all users on EkSathi including but not limited to candidates seeking jobs or participating in the general community and employers seeking to hire candidates - are responsible for their own actions and EkSathi does not accept any responsibility
                        This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
                        If we don't act to enforce a breach of this Contract, that does not mean that EKSATHI has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that EKSATHI may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.
                        You agree that the only way to provide us legal notice is at the addresses provided in Section 10.</p>
                    <h2 className='my-3 fw-bold'>8. EKSATHI "Dos and Don'ts"</h2>
                    <h3 className='my-3 fw-bold'>8.1. Dos</h3>
                    <p><strong>You agree that you will:</strong></p>
                    <ol>
                        <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</li>
                        <li>Provide accurate information to us and keep it updated;</li>
                        <li>Use the Services in a professional manner.</li>
                    </ol>
                    <h3 className='my-3 fw-bold'><strong>8.2. Don'ts</strong></h3>
                    <p><strong>You agree that you will</strong> <strong><em>not</em>:</strong></p>
                    <ol>
                        <li>Create a fake profile on EKSATHI, misrepresent your identity, impersonate anyone, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another's account;</li>
                        <li>Directly or indirectly scrape the Services or otherwise copy profiles and other data from the Services;</li>
                        <li>Try to circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);</li>
                        <li>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of EKSATHI;</li>
                        <li>Breach your confidentiality obligations by disclosing information you do not have the consent to disclose;</li>
                        <li>Violate anyone's intellectual property rights;</li>
                        <li>Violate the intellectual property or other rights of EKSATHI;</li>
                        <li>Post anything that contains software viruses, worms, or any other harmful code;</li>
                        <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;</li>
                        <li>Imply or state that you are affiliated with or endorsed by EKSATHI without our express consent (e.g., representing yourself as an accredited EKSATHI trainer);</li>
                        <li>Monetize in any manner Services or related data or access to the same, without EKSATHI's consent;</li>
                        <li>Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without EKSATHI's consent;</li>
                        <li>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;</li>
                        <li>Monitor the Services' availability, performance or functionality for any competitive purpose;</li>
                        <li>Engage in "framing," "mirroring," or otherwise simulating the appearance or function of the Services;</li>
                        <li>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);</li>
                        <li>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or</li>
                        <li>Violate the Professional Community Policies or any additional terms concerning a specific Service that are provided when you sign up for or start using such Service, where applicable.</li>
                        <li>Act as a consultant or middleman or in any similar capacity or charge any fee for providing recruitment or related services.</li>
                        <li>Willingly participate in any harassment or fraud</li>
                    </ol>
                    <h2 className='my-3 fw-bold'>9. Complaints Regarding Content</h2>
                    <p>We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties.</p>
                    <h2 className='my-3 fw-bold'>10. Refunds</h2>
                    <p>EkSathi reserves the right to reject companies that are trying to commit fraud or hiring for Multi-Level Marketing schemes. Any money paid in this case will not be refunded.</p>
                    <h2 className='my-3 fw-bold'>11. How To Contact Us</h2>
                    <p>We have authorized representatives who oversee all kinds of queries, issues and complaints. However, these queries, issues, and complaints will be meticulously examined and scrutinized before we revert. For general inquiries and legal notices - you may write us at these <a href="mailto:support@eksathi.com">support@eksathi.com</a>.</p>
                    <h2 className='my-3 fw-bold'>EKSATHI Hire Guidelines for Employers</h2>
                    <p>We request you to ensure that your actions do not negatively impact the users in any manner.</p>
                    <p>These Professional Community Guidelines ensure that the exchange on our platform is free of inappropriate and unwanted content or behavior. These Guidelines, which we continue to develop, provide guidance and rules for the use of our services.
                        You are requested to act responsibly. We request you to bring to our attention anything that you may believe violates our policies. You are requested to report such actions on <a href="mailto:support@eksathi.com">support@eksathi.com</a>. These reports, along with our <strong>automated defenses</strong>, help us identify and prevent abuse and misbehavior. Please use the reporting tools responsibly and only for their intended purposes and not for unnecessary harassing other users.
                        A violation of these policies may result in us taking enforcement actions. Depending on the severity of the violation and a member's behavior or account history, we may block your account permanently.</p>
                    <ol>
                        <li><strong>Be trustworthy</strong>. Please do not use fake profiles. We request you to provide only real name and accurate information. Please do not use any information which may be considered misleading or deceptive.</li>
                        <li><strong>Be professional</strong>. Please do not communicate in a manner which may be considered offensive or inappropriate. When creating content, we ask that you keep it professional, relevant, and on-topic. We request you to not use the services to shock or intimidate others. Sharing graphic, obscene, or pornographic content on the services is prohibited.</li>
                        <li><strong>Be safe.</strong> We strive to maintain a civil and friendly community for our members. The services shouldn't be used to harm others or their careers or business prospects or to air personal grievances and disputes. Please do not harass, abuse, or send other unwelcome communications to people (e.g., romantic advances, sexually explicit content, junk mail, spam, chain letters, phishing schemes). We do not allow hate speech, hate groups, terrorists, or those who engage in violent crimes on the services.</li>
                        <li><strong>Respect others'</strong> <strong>rights and follow the law</strong>. Do not violate any third-party intellectual property rights. he services should not be used for illegal activities, promoting illegal products, or violating the rights of others. Don't use the services to commit fraud or to try to trick others. Also, don't use the services to intentionally distribute viruses, worms, or other software that can destroy or interrupt others' data or computer devices.</li>
                        <li><strong>Respect EKSATHI's rights.</strong> Don't violate the rights of EKSATHI. For more information about what this means, see our User Agreement. It's not okay to suggest that you're affiliated with or endorsed by EKSATHI when you're not. Don't violate our intellectual property rights, scrape the services, or interfere with or disrupt the services.</li>
                    </ol>
            </div></div>
        </>
    )
}

export default EmployerCoC;