import { Button, Divider, FormLabel, Input } from "@mui/joy";
import React, { useState } from "react";
import { Link, redirect, useHref } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useGlobalContext } from "global/context";
import OtpInstitute from "./OtpInstitute";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import { Popup } from "../../../layout/Popup";
import { TextField } from "@mui/material";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
});
function SignInstitute() {
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const { setUser, setToken, token, api, apiAuth } = useGlobalContext();
  const [registeredId, setRegisteredId] = useState();
  const endpoint =
    process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000/";
  const [email, setEmail] = useState("");
  // const [otp, setOTP] = useState([""]);
  const [timeRemaining, setTimeRemaining] = useState();
  const inputRefs = useRef([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // const href = useHref();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contect: "",
      password: "",
      cpassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
      cpassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      try {
        // console.log("Form Data", values);
        const res = await api.post("/v1/signup", {
          name: values.name,
          email: values.email,
          mobile: values.contect,
          password: values.password,
        });
        // console.log(res);
        if (res.status == 200) {
          setRegisteredId(res?.data?.results?.insertId);
          setState(true);
          // navigate(`/auth/institute/login`);
          toast.success("Register Successfully");
        }
      } catch (error) {
        console.error("An error occurred during form submission:", error);
        // alert("User Already Register")
        // Handle the error here, for example, by displaying an error message to the user
        toast.error("Already Registered");
      }
    },
  });

  const OtpFormik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: async (values, action) => {
      Popup("loading", "Submitting...");

      try {
        const res = await api.post(`/app/auth/verify-institute-otp`, {
          ...values,
          userId: registeredId,
        });
        // console.log(res);
        if (res.status == 200) {
          navigate("/onboarding", { state: { registeredId } });
        }
      } catch (error) {
        alert("Wrong Otp", error.response.data.message);
      } finally {
        Popup("close");
      }
    },
  });

  const resendOTP = async () => {
    try {
      const res = await api.post(endpoint + "app/auth/send-otp", {
        email,
      });
      // console.log(res);
      if (res.status === 200) {
        Popup("success", res.data.message);
        // setExpirationTime(res.data.expirationTime);
        // setTimeRemaining(res.data.expirationTime);
        startTimeCounter(res.data.expirationTime);
      }
    } catch (error) {
      console.log(error);
      Popup("error", error.response.data.message);
    }
  };

  const startTimeCounter = (expirationTime) => {
    const interval = setInterval(() => {
      // Calculate time remaining
      const now = new Date().getTime();
      const expiration = new Date(expirationTime).getTime();
      const timeDiff = expiration - now;
      const newTimeRemaining = Math.max(0, timeDiff);

      // Calculate remaining time in minutes and seconds
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      // Update state with new time remaining
      setTimeRemaining({ minutes, seconds });

      // Stop interval if time has expired
      if (newTimeRemaining === 0) {
        clearInterval(interval);
      }
      // console.log("Time remaining: ", newTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  };
  //   For Otp Form

  // const handleChange = (e, index) => {
  //   const { value } = e.target;
  //   const newOTP = [...otp];
  //   newOTP[index] = value;
  //   setOTP(newOTP);

  //   if (value !== "") {
  //     if (index < 5) {
  //       inputRefs.current[index + 1].focus();
  //     } else {
  //       inputRefs.current[index].blur();
  //     }
  //   } else {
  //     if (index > 0) {
  //       inputRefs?.current[index - 1]?.focus();
  //     }
  //   }
  // };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === "Backspace" && otp[index] === "") {
  //     inputRefs?.current[index - 1]?.focus();
  //   }
  // };

  const handleGoogleOAuth = async () => {
    // console.log("Href:", href);
    try {
      toast.loading("Authenticating Google...");
      // const res = await api.get('/oauth2');
      // if (res.status === 200) {
      //   console.log('Google', res?.data);
      //   toast.dismiss();
      // }
      // href(`http://localhost:5000/oauth2`);
      window.location.replace(
        `${
          process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000/"
        }oauth2`
      );
    } catch (e) {
      console.log("Error authenticating Google: ", e);
      toast.dismiss();
    }
  };

  return (
    <>
      <div className="container  ">
        <div className="row">
          {!registeredId && (
            <div className="col-12 col-lg-6">
              <div className=" " style={{ marginTop: "12%" }}>
                <div
                  className="rounded w-75 w-lg-50  align-items-center m-auto p-1 my-3"
                  style={{}}
                >
                  <h3 className="text-start mb-2 font-weight-bold">
                    Create account
                  </h3>
                  <p className="text-start mb-3 fs-13 text-dark font-weight-bold ">
                    Start your 30-day free trial. Cancel anytime.
                  </p>

                  <div className="d-flex flex-column justify-content-center mb-3 ">
                    <Button
                      variant="outlined"
                      startDecorator={
                        <img
                          className=""
                          src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png"
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                        />
                      }
                      onClick={handleGoogleOAuth}
                    >
                      Sign up with Google
                    </Button>
                  </div>

                  <Divider className="my-4">OR</Divider>
                  <form onSubmit={formik.handleSubmit}>
                    <FormLabel className="font-weight-bold">
                      Institute Name*
                    </FormLabel>
                    <Input
                      placeholder="Enter institute name ..."
                      variant="outlined"
                      color="white"
                      className="border mb-2"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error text-danger font-weight-bold mt-1">
                        {formik.errors.name}
                      </div>
                    ) : null}
                    <FormLabel className="font-weight-bold">Email*</FormLabel>
                    <Input
                      placeholder="Enter your email"
                      variant="outlined"
                      color="white"
                      className="border mb-2"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error text-danger font-weight-bold mt-1">
                        {formik.errors.email}
                      </div>
                    ) : null}

                    <FormLabel className="font-weight-bold">
                      Contect Number*
                    </FormLabel>
                    <Input
                      placeholder="Enter contect number ..."
                      variant="outlined"
                      color="white"
                      className="border mb-2"
                      {...formik.getFieldProps("contect")}
                    />
                    {formik.touched.contect && formik.errors.contect ? (
                      <div className="error text-danger font-weight-bold mt-1">
                        {formik.errors.contect}
                      </div>
                    ) : null}
                    <FormLabel className="font-weight-bold">
                      Password*
                    </FormLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      variant="outlined"
                      color="white"
                      className="border mb-2"
                      {...formik.getFieldProps("password")}
                      endDecorator={
                        <i
                          className={`bi ${
                            showPassword ? "bi-eye-slash" : "bi-eye"
                          }`}
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      }
                    />

                    <FormLabel className="font-weight-bold">
                      Confirm Password*
                    </FormLabel>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Enter confirm password"
                      variant="outlined"
                      color="white"
                      className="border"
                      {...formik.getFieldProps("cpassword")}
                      endDecorator={
                        <i
                          className={`bi ${
                            showConfirmPassword ? "bi-eye-slash" : "bi-eye"
                          }`}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      }
                    />
                    {formik.touched.cpassword && formik.errors.cpassword ? (
                      <div className="error text-danger font-weight-bold mt-1">
                        {formik.errors.cpassword}
                      </div>
                    ) : null}
                    <p className="mb-3 fs-14 text-dark font-weight-bold">
                      Must be at least 8 characters.
                    </p>
                    <Button
                      type="submit"
                      className="text-white mb-3"
                      color="info"
                      fullWidth
                    >
                      Create Account
                    </Button>
                  </form>
                  <p className="text-center text-dark ">
                    Already have an account?{" "}
                    <strong style={{ textDecoration: "underline" }}>
                      <Link to="/auth/institute/login">Log in</Link>
                    </strong>
                  </p>
                  <p className="text-center">
                    Not a Institute?{" "}
                    <span>
                      <Link to="/auth/register">Click here</Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {registeredId && (
            <>
              <div className="col-12 col-lg-6">
                <div
                  className=" "
                  style={{
                    marginTop: "12%",
                    border: "2px solid blue",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="rounded w-75 w-lg-50  align-items-center m-auto p-1 my-3"
                    style={{}}
                  >
                    <form
                      className="form-action-wrapper py-5"
                      onSubmit={OtpFormik.handleSubmit}
                    >
                      <div className="form-group">
                        <h3 className="fs-22 pb-3 fw-bold">Verify Otp</h3>
                        <div className="divider">
                          <span></span>
                        </div>
                      </div>
                      <div className="form-group">
                        {/* <label className="fs-14 text-black fw-medium lh-18">
              One Time Password
            </label> */}
                        <TextField
                          fullWidth
                          label={"Enter your OTP"}
                          id="code"
                          name="code"
                          placeholder="Your 6 digit Secure OTP, Recieved on registered email."
                          value={OtpFormik.values.code}
                          onChange={OtpFormik.handleChange}
                          error={
                            OtpFormik.touched.code &&
                            Boolean(OtpFormik.errors.code)
                          }
                          helperText={
                            OtpFormik.touched.code && OtpFormik.errors.code
                          }
                        />
                      </div>
                      {/* <p>Time remaining: {Math.floor(timeRemaining / 1000)} seconds</p> */}
                      {/* <p>Time remaining: <span className="text-danger">{`${timeRemaining?.minutes}:${timeRemaining?.seconds}`}</span></p> */}
                      <Button
                        type="submit"
                        className="text-white mb-3"
                        color="info"
                        fullWidth
                      >
                        Verify
                      </Button>
                    </form>
                    <div className="text-center">
                      {timeRemaining?.seconds >= 0 ? (
                        <p>
                          <span className="text-danger">{`${timeRemaining?.minutes}:${timeRemaining?.seconds}`}</span>{" "}
                          left
                        </p>
                      ) : (
                        <button
                          disabled={timeRemaining?.seconds <= 0 ? false : true}
                          type="button"
                          className="btn btn-link"
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </button>
                      )}
                      {/* OTP not recieved?  */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col p-0">
            <div className=" w-100">
              <img
                className="w-100"
                src="https://images.unsplash.com/photo-1560461396-ec0ef7bb29dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=464&q=80"
                alt="logo"
                style={{ height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignInstitute;
