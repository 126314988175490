import React from "react";

function CookiePolicy() {
  return (
    <>
      <div className="container">
        <div className="p-2">
          <h1 className="mb-4 font-weight-bold">Cookie Policy</h1>
          <h5 className="mb-2 font-weight-bold">Last updated: July 1, 2023</h5>
          <p className="mb-2">
            This Cookie Policy explains how Eksathi, a question-and-answer
            website, uses cookies and similar technologies when you visit or
            interact with our website. By using Eksathi, you agree to the use of
            cookies as described in this policy.
          </p>

          <h3 className="mb-2 font-weight-bold">What are Cookies?</h3>
          <p className="mb-2">
            Cookies are small text files that are placed on your device
            (computer, smartphone, or tablet) when you visit a website. They
            help the website recognize your device and remember certain
            information about your preferences or actions.
          </p>
          <h3 className="mb-2 font-weight-bold">Types of Cookies We Use:</h3>
          <p className="mb-2">
            <strong className="text-dark">2.1. Essential Cookies: </strong>{" "}
            <br />
            These cookies are necessary for the functioning of Eksathi and allow
            you to navigate the website and use its features. They are essential
            for providing the services you requested, and they do not collect
            personal information.
          </p>
          <p className="mb-3">
            <strong className="text-dark">
              2.2. Performance and Analytics Cookies:
            </strong>{" "}
            <br /> We use these cookies to collect information about how
            visitors use our website, such as which pages are visited most
            frequently and any error messages encountered. This data helps us
            improve the performance and functionality of Eksathi.
          </p>

          <p className="mb-2">
            <strong className="text-dark">2.3. Functionality Cookies:</strong>{" "}
            <br /> These cookies enable us to remember your preferences and
            customize your experience on Eksathi. For example, they may remember
            your language preference or the region you are in. These cookies do
            not track your browsing activity on other websites.
          </p>
          <p className="mb-2">
            <strong className="text-dark">
              2.4. Advertising and Targeting Cookies:
            </strong>{" "}
            <br /> We may use these cookies to deliver personalized
            advertisements and content that are relevant to your interests. They
            help us measure the effectiveness of our advertising campaigns and
            track the performance of advertisements displayed on Eksathi.
          </p>
          <h3 className="mb-2 font-weight-bold">Third-Party Cookies:</h3>
          <p className="mb-2">
            We may allow third-party service providers, such as analytics or
            advertising companies, to place cookies on Eksathi. These providers
            have their own privacy policies and may collect information about
            your online activities across different websites or services. We
            encourage you to review the privacy policies of these third parties
            for more information about their data practices.
          </p>
          <h3 className="mb-2 font-weight-bold">Your Cookie Choices:</h3>
          <p className="mb-3">
            <strong className="text-dark">4.1. Cookie Consent:</strong> <br />
            When you visit Eksathi for the first time, we will request your
            consent to use cookies, except for essential cookies, which are
            necessary for the proper functioning of the website. By continuing
            to use our website without changing your cookie settings, you
            consent to the use of cookies as described in this policy.
          </p>

          <p className="mb-2">
            <strong className="text-dark">
              4.2. Managing Cookie Preferences:
            </strong>{" "}
            <br />
            You can manage your cookie preferences and opt-out of non-essential
            cookies by adjusting your browser settings. Please note that
            disabling certain cookies may affect the functionality and
            performance of Eksathi.
          </p>
          <p className="mb-2">
            <strong className="text-dark">4.3. Browser Settings:</strong> <br />
            Most web browsers allow you to control cookies through their
            settings. You can usually find these settings in the "Options,"
            "Preferences," or "Settings" menu of your browser. Each browser may
            have different cookie settings, so we recommend referring to the
            help menu of your specific browser for more information.
          </p>
          <h3 className="mb-2 font-weight-bold">Changes to this Cookie Policy:</h3>
          <p className="mb-3">
            We may update this Cookie Policy from time to time to reflect
            changes in our use of cookies or legal requirements. We will notify
            you of any material changes by posting the updated policy on our
            website. Your continued use of Eksathi after such changes
            constitutes your acceptance of the updated Cookie Policy.
          </p>
          <h3 className="mb-2 font-weight-bold">Contact Us:</h3>
          <p className="mb-3">
            If you have any questions, concerns, or suggestions regarding this
            Cookie Policy or our data practices, please contact us at [insert
            contact information]. Thank you for using Eksathi. We hope this
            Cookie Policy clarifies how we use cookies and provides you with a
            better understanding of your cookie choices while using our website.
          </p>
          
        </div>
      </div>
    </>
  );
}

export default CookiePolicy;
