import React, { useMemo } from "react";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import { Avatar, Box, Chip, Table, Typography } from "@mui/joy";
import { styled } from "styled-components";
import { Button } from "@mui/joy";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
// Styled component to handle the scrollbar
const ScrollbarHidden = styled("div")({
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 0,
  },
  "&:hover::-webkit-scrollbar": {
    width: 8,
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
});

export const CreateDepartmentModal = ({
  openmodal,
  setOpenmodal,
  handleSubmit,
  departmentName,
  handleDepartmentNameChange,
}) => {
  return (
    <>
      <Modal
        open={openmodal}
        onClose={() => {
          setOpenmodal(false);
        }}
      >
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            Create new department
          </Typography>

          <form onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <FormControl>
                <FormLabel>Department Name</FormLabel>
                <Input
                  variant="soft"
                  autoFocus
                  required
                  name="departmentName"
                  value={departmentName}
                  onChange={handleDepartmentNameChange}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    padding: "0.9rem",
                  }}
                />
              </FormControl>

              <Button variant="outlined" color="info" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};
export const CreateTeacherModal = ({
  open,
  setOpen,
  handleSubmit2,
  currentDepartment,
  newTeacher,
  handleInputChange,
  errors,
}) => {
  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            Create new Teacher
          </Typography>

          <ScrollbarHidden>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit2(currentDepartment);
              }}
              style={{
                marginRight: 6,
              }}
            >
              <Stack spacing={1}>
                <FormControl>
                  {/* <FormLabel>Teacher Name</FormLabel> */}
                  <Input
                    variant="soft"
                    autoFocus
                    required
                    name="name"
                    value={newTeacher.name}
                    onChange={handleInputChange}
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: "0.9rem",
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Designation</FormLabel>
                  {/* dropdown of designation */}
                  <Select
                    name="designation"
                    value={newTeacher.designation}
                    onChange={handleInputChange}
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.8)",
                    }}
                  >
                    <MenuItem value="Teacher">Teacher</MenuItem>
                    <MenuItem value="Assistant">Assistant</MenuItem>
                    <MenuItem value="Head-Of-Department">
                      Head-Of-Department
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Specialization</FormLabel>
                  <Input
                    variant="soft"
                    required
                    name="specialization"
                    value={newTeacher.specialization}
                    onChange={handleInputChange}
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: "0.9rem",
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    variant="soft"
                    required
                    name="email"
                    value={newTeacher.email}
                    onChange={handleInputChange}
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: "0.9rem",
                    }}
                  />
                  {errors.email && (
                    <span style={{ color: "red" }}>{errors.email}</span>
                  )}
                </FormControl>
                <FormControl className="mb-2">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: "0.9rem",
                    }}
                    variant="soft"
                    required
                    name="phone"
                    value={newTeacher.phone}
                    onChange={handleInputChange}
                  />
                  {errors.phone && (
                    <span style={{ color: "red" }}>{errors.phone}</span>
                  )}
                </FormControl>
                <Button variant="outlined" color="info" type="submit">
                  Submit
                </Button>
              </Stack>
            </form>
          </ScrollbarHidden>
        </ModalDialog>
      </Modal>
    </>
  );
};

export const ChangeDepartmentModal = ({
  changedepartmentModal,
  setchangedepartmentModal,
  currentDepartment,
  handleDepartmentNameChange,
  handleEditdepartment,
  departmentName,
}) => {
  return (
    <>
      <Modal
        open={changedepartmentModal}
        onClose={() => {
          setchangedepartmentModal(false);
        }}
      >
        <ModalDialog>
          <Typography
            id="basic-modal-dialog-title"
            level="h5"
            sx={{ fontSize: "1.2rem" }}
            fontWeight="bold"
          >
            Change Your Department
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEditdepartment(currentDepartment);
            }}
          >
            <Stack spacing={1}>
              <FormControl>
                <FormLabel sx={{ textAlign: "center" }}>
                  Department Name
                </FormLabel>
                <Input
                  variant="outlined"
                  autoFocus
                  required
                  name="departmentName"
                  value={departmentName}
                  onChange={handleDepartmentNameChange}
                />
              </FormControl>

              <Button variant="solid" color="info" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};
export const ChangeTeacherModal = ({
  changeteacherModal,
  setchangeteacherModal,
  handleInputChange,
  newTeacher,
  handleEditTeacher,
  currentDepartment,
  errors,
}) => {
  return (
    <>
      <Modal
        open={changeteacherModal}
        onClose={() => setchangeteacherModal(false)}
      >
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <Typography id="basic-modal-dialog-title" level="h2">
            Change Teacher's information
          </Typography>
          <form onSubmit={() => handleEditTeacher(currentDepartment)}>
            <Stack spacing={1}>
              <FormControl>
                <FormLabel>Teacher Name</FormLabel>
                <Input
                  variant="soft"
                  autoFocus
                  required
                  name="name"
                  value={newTeacher.name}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Designation</FormLabel>
                {/* dropdown of designation */}
                <select
                  variant="soft"
                  required
                  name="designation"
                  value={newTeacher.designation}
                  onChange={handleInputChange}
                  className="p-2"
                >
                  <option value="none">
                    <em>None</em>
                  </option>
                  <option value="Teacher">Teacher</option>
                  <option value="Assistant">Assistant</option>
                  <option value="Head-Of-Department">Head-Of-Department</option>
                </select>
              </FormControl>
              <FormControl>
                <FormLabel>Specialization</FormLabel>
                <Input
                  variant="soft"
                  required
                  name="specialization"
                  value={newTeacher.specialization}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  variant="soft"
                  required
                  name="email"
                  value={newTeacher.email}
                  onChange={handleInputChange}
                />
                {errors.email && <span>{errors.email}</span>}
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  variant="soft"
                  required
                  name="phone"
                  value={newTeacher.phone}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Button variant="outlined" color="info" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};
export const DeleteDepartmentModal = ({
  departmentloading,
  setdepartmentloading,
  handleDeletedepartment,
  currentDepartmentId,
}) => {
  return (
    <>
      <Modal
        open={departmentloading}
        onClose={() => {
          setdepartmentloading(false);
        }}
      >
        <ModalDialog sx={{ maxWidth: "500px" }}>
          <Typography level="h5">
            Are you sure you want to delete this Department?
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              onClick={() => {
                setdepartmentloading(false);
              }}
            >
              Cancel
            </Button>
            <button
              //delete button
              onClick={() => {
                handleDeletedepartment(currentDepartmentId);
              }}
              className="btn btn-danger my-2"
            >
              Confirm
            </button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
};
export const DeleteTeacherModal = ({
  teacherloading,
  setteacherloading,
  handleDeleteTeacher,
  currentTeacherId,
}) => {
  return (
    <>
      <Modal
        open={teacherloading}
        onClose={() => {
          setteacherloading(false);
        }}
      >
        <ModalDialog sx={{ maxWidth: "500px" }}>
          <Typography level="h5">
            Are you sure you want to delete this Teacher
          </Typography>
          <button
            onClick={() => {
              handleDeleteTeacher(currentTeacherId);
            }}
            className="btn btn-danger my-2"
          >
            Confirm
          </button>
          <Button
            onClick={() => {
              setteacherloading(false);
            }}
          >
            Cancel
          </Button>
        </ModalDialog>
      </Modal>
    </>
  );
};
