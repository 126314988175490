import React from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Container, Row } from "react-bootstrap";

const BarChart = () => {
  const donutChartOptions = {
    options: {
      chart: {
        type: 'donut',
      },
      labels: ["A", "B", "C", "D", "E"],
    },
    series: [44, 55, 41, 17, 15],
  };

  const barChartOptions = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ],
    },
    title: {
      text: "Monthly Data",
      align: "center",
    },
  };

  const barSeries = [
    {
      name: "Series 1",
      data: [30, 40, 45, 50, 49, 60, 70, 91, 125, 134, 145, 158],
    },
  ];

  const lineChartOptions = {
    chart: {
      id: "basic-line",
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ],
    },
    title: {
      text: "Yearly Data",
      align: "center",
    },
  };

  const lineSeries = [
    {
      name: "Series 1",
      data: [300, 400, 450, 500, 490, 600, 700, 910, 1250, 1340, 1450, 1580],
    },
  ];

  return (
    <Container className="my-4">
      <Row className="text-white">
        <Col className="donut-chart" xs={12} md={6}>
          <ReactApexChart options={donutChartOptions.options} series={donutChartOptions.series} type="donut" />
        </Col>
        <Col className="line-chart" xs={12} md={6}>
          <ReactApexChart options={lineChartOptions} series={lineSeries} type="line" />
        </Col>
      </Row>
    </Container>
  );
};

export default BarChart;
