import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box, TextField, Typography, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";
import { useGlobalContext } from "global/context";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});
function AdminResetPassword() {
  const navigate = useNavigate();
  const { api } = useGlobalContext();
  const location = useLocation();

  const QueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      token: params.get("token"),
    };
  };

  const { token } = QueryParams(location.search);
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      await api.post(`/admin/auth/reset/${token}`, values);
      Popup("success", "Password reset successfully");
      navigate("/auth/admin/login");
    } catch (error) {
      if (error.response.status === 403) {
        Popup("error", "Link is expired");
        return
      }
      Popup("error", "Error resetting password");
    }
  };

  return (
    <Container fluid className="p-5">
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center"
        >
          <Typography
            variant="h4"
            gutterBottom
            textAlign={"center"}
            color="primary"
          >
            Reset Password
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <TextField
                    label="New Password"
                    name="newPassword"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </Box>
                <Box textAlign="center" my={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Typography variant="body2" className="text-center">
            Remembered your password?{" "}
            <strong>
              <Link to="/auth/admin/login">Login</Link>
            </strong>
          </Typography>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminResetPassword;
