import React, { useState, useEffect, useRef } from "react";
import MicIcon from "@mui/icons-material/Mic";
import EditIcon from "@mui/icons-material/Edit";
import Input from "@mui/joy/Input";
import SearchIcon from "@mui/icons-material/Search";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import PlaceIcon from "@mui/icons-material/Place";
import { Avatar, IconButton } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Stack } from "react-bootstrap";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import "./Css/Messages.css";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ClientMessages from "./ChatComponents/ClientMessages";
import OwnerMessages from "./ChatComponents/OwnerMessages";
import PinnedMessages from "./ChatComponents/PinnedMessages";
import { Checkbox, ListDivider, Menu, MenuItem } from "@mui/joy";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { DeleteForever, Edit, MoreVert } from "@mui/icons-material";
import { msgData } from "./ChatComponents/Data";
import { useGlobalContext } from "global/context";
import AllChats from "./ChatComponents/AllChats";
import ChatModal from "./ChatComponents/ChatModal";

function Chats({ number }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageCounts, setMessageCounts] = useState({});
  const { userData, api } = useGlobalContext();
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendClick = () => {
    const newMessage = {
      id: Date.now(),
      sender_id: userData?.id,
      content: message,
      timestamp: new Date().toISOString(),
      isRead: false,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    setMessageCounts((prevCounts) => ({
      ...prevCounts,
      [selectedUser.id]: (prevCounts[selectedUser.id] || 0) + 1,
    }));

    setMessage("");
    scrollToBottom();
  };

  const handleDeleteChat = () => {
    setMessages([]);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    // Handle file upload
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          console.error("Error retrieving location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowMessageModal(true);
  };

  const getHistory = async (roomId) => {
    try {
      const res = await api.get(`/app/chat/history?room=${roomId}`);
      if (res?.status === 200) {
        setMessages(res?.data?.history || []);
      }
    } catch (err) {
      console.error("Error fetching message history:", err);
    }
  };

  const handleMessageCounts = (data) => {
    setMessageCounts(data);
  };

  useEffect(() => {
    if (selectedUser) {
      setMessageCounts((prevCounts) => ({
        ...prevCounts,
        [selectedUser.id]: 0,
      }));
    }
  }, [selectedUser]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="container py-1">
        <div className="row">
          <div className="col-12 col-lg-4 rounded-3">
            <div className="p-2">
              <div className="d-flex justify-content-between pb-2">
                <h4>Messages</h4>
                <IconButton onClick={handleClick}>
                  <EditIcon />
                </IconButton>
              </div>
              <div className="form d-flex pb-2">
                <Input
                  fullWidth
                  placeholder="Type in here…"
                  variant="plain"
                  startDecorator={<SearchIcon />}
                />
              </div>
              <div className="fs-12">
                <div>
                  <MessageIcon sx={{ width: 13 }} /> People
                </div>
              </div>
              <div>
                <AllChats
                  onUserClick={handleUserClick}
                  messageCounts={messageCounts}
                  messagesHai={messages}
                  selectedUser={selectedUser}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 d-lg-block bg-light" ref={chatContainerRef}>
            {showMessageModal && selectedUser && (
              <ChatModal
                user={selectedUser}
                room={userData?.id + selectedUser?.id}
                handleMessageCounts={handleMessageCounts}
                getHistory={getHistory}
                messages={messages}
                setMessages={setMessages}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Chats;