import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Sidebar from './SideBar/Sidebar';
import Mainchat from './mainChat/Mainchat';
import { useGlobalContext } from "global/context";
import io from 'socket.io-client';
import styled from 'styled-components';

const PATH = 'http://localhost:5000';

const StyledMessagingContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 100px); /* Adjust based on footer height */
  }

  @media (max-width: 600px) {
    height: calc(100vh - 100px); /* Adjust based on footer height */
  }
`;


const Messaging = () => {
  const { userData, api, notification } = useGlobalContext();
  const socketRef = useRef();
  const [profileData, setProfileData] = useState([]);
  const [isConnect, setIsConnect] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allMsg, setAllMsg] = useState([]);
  const [roomData, setRoomData] = useState("");
  const [sender, setSender] = useState([]);
  const [msg, setMsg] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // Detect mobile view
  const [showChat, setShowChat] = useState(!isMobile);  // Default to showing chat if on mobile

  useEffect(() => {
    // Handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSendMsg = async (msg) => {
    if (socketRef.current.connected) {
      const data = {
        msg,
        receiver: roomData.receiver.id,
        sender: userData.id,
        room: userData.id + roomData.receiver.id,
      };
      socketRef.current.emit("SEND_MSG", data);
      setAllMsg(prevState => [...prevState, data]);
    }
  };

  const getAllMessages = async () => {
    try {
      const res = await api.get(`app/message/allmsg/${notification?.content_id}`);
      setAllMsg(res.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchProfileData = async (id) => {
    try {
      let res;
      if (userData.role !== 'institute') {
        res = await api.get(`/app/user/${userData?.id}`);
        if (res.status === 200) {
          setProfileData(res.data);
        }
      } else {
        res = await api.get(`/app/institutes/${id}`);
        if (res.status === 200) {
          setProfileData(res.data.institute);
        }
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const fetchSenderProfiles = async (uniqueSenders) => {
    try {
      const senderProfiles = await Promise.all(
        uniqueSenders.map(async (notification) => {
          const res = await api.get(`/app/user/${notification.sender_id}`);
          return res?.status === 200 ? res.data : null;
        })
      );
      setSender(senderProfiles.filter(profile => profile !== null));
    } catch (error) {
      console.log('Error fetching sender profiles:', error);
    }
  };

  const handleReply = (e) => {
    e.preventDefault();
    if (msg) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  useEffect(() => {
    if (notification && notification.length > 0) {
      const uniqueSenders = notification.reduce((acc, notification) => {
        if (!acc.some(item => item.sender_id === notification.sender_id)) {
          acc.push(notification);
        }
        return acc;
      }, []);
      fetchSenderProfiles(uniqueSenders);
    }
  }, [notification]);

  useEffect(() => {
    fetchProfileData(userData?.id);
    getAllMessages();
    const socket = io.connect(PATH);
    socketRef.current = socket;
    socket.on('connect', () => setIsConnect(true));
    socket.on('disconnect', () => setIsConnect(false));
  }, [userData?.id]);

  useEffect(() => {
    if (isConnect) {
      socketRef.current.emit('ADD_USER', userData);
      socketRef.current.on('USER_ADDED', (data) => {
        setOnlineUsers(data);
      });
      socketRef.current.on("RECIVED_MSG", (data) => {
        setAllMsg(prevState => [...prevState, data]);
      });
      return () => socketRef.current.disconnect();
    }
  }, [isConnect]);

  const handleUserClick = (user) => {
    setShowChat(true);
  };

  const handleBackToSidebar = () => {
    setShowChat(false);
  };

  if (!userData) return null;

  return (
    <StyledMessagingContainer className='detailed-view p-1 m-3 shadow-sm rounded-3 border border-success rounded'>
      <Paper square elevation={0} sx={{ height: "100%", display: "flex", flexGrow: 1 }}>
        {/* Conditional rendering based on screen size and chat view state */}
        {isMobile ? (
          showChat ? (
            <Mainchat
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
              onBack={handleBackToSidebar}
            />
          ) : (
            <Sidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
            />
          )
        ) : (
          <>
            <Sidebar
              sender={sender}
              notification={notification}
              pd={profileData}
              onlineUsers={onlineUsers}
              roomData={roomData}
              setRoomData={setRoomData}
              setAllMsg={setAllMsg}
              onUserClick={handleUserClick}
            />
            <Mainchat
              pd={profileData}
              roomData={roomData}
              handleSendMsg={handleSendMsg}
              allMsg={allMsg}
              handleReply={handleReply}
              msg={msg}
              setMsg={setMsg}
            />
          </>
        )}
      </Paper>
    </StyledMessagingContainer>
  );
};

export default Messaging;