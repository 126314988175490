import React from 'react'
import Teacherpoll from './Teacherpoll';
import Institutepoll from './Institutepoll';

const Pollindex = () => {
    const role = JSON.parse(localStorage.getItem('user')).role;
    console.log(role)
  return (
    <>
        {
            role == 'student' ? <Teacherpoll/> : <Institutepoll/>
        }
    </>
  )
}

export default Pollindex