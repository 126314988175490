import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import { FormControl, Button, ButtonGroup } from '@mui/material';
import { SearchTwoTone } from '@mui/icons-material';
import InstituteCard from '../Home/components/InstituteCard';
import { useGlobalContext } from 'global/context';

function Institutes() {
  const { api } = useGlobalContext();
  const [institutes, setInstitutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState()
  const [filteredInstitutes, setFilteredInstitutes] = useState([]);

  const getInstitutes = async () => {
    try {
      const response = await api.get(`/app/institutes`);
      setInstitutes(response?.data?.result || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInstitutes();
  }, [api]);

  useEffect(() => {
    setFilteredInstitutes(
      institutes.filter((institute) =>
        institute.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        institute.address.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        institute.city.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        institute.state.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    );
  }, [searchTerm, institutes]);

  useEffect(() => {
    if (sort) {
      let sortedInstitutes = [...filteredInstitutes];
      switch (sort) {
        case 'name':
          sortedInstitutes.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'rating':
          sortedInstitutes.sort((a, b) => b.rating - a.rating);
          break;
        // case 'location':
        //   sortedInstitutes.sort((a, b) => a.address.localeCompare(b.address));
        //   break;
        default:
          break;
      }
      setFilteredInstitutes(sortedInstitutes);
    }
  }, [sort, filteredInstitutes]);

  return (
    <div className="container">
      <FormControl fullWidth className="mb-3 mt-3 rounded shadow">
        <Input
          size="lg"
          variant="plain"
          color="info"
          id="search"
          type="text"
          className="rounded-4 px-3"
          name="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          endDecorator={
            <InputAdornment position="end">
              <IconButton
                style={{ borderRadius: '50%' }}
                variant="plain"
                color="info"
                aria-label="search"
                edge="end"
              >
                <SearchTwoTone />
              </IconButton>
            </InputAdornment>
          }
          placeholder="Search by name or address..."
        />
      </FormControl>
      <div className="row justify-content-between my-3 p-2 ml-2">
        <div>
          <h4 className="fw-bold mb-3">Suggested Institutes</h4>
        </div>
        <div className="d-flex align-items-center">
          <h6 className="mr-3">Sort By</h6>
          <ButtonGroup
            size="small"
            aria-label="small button group"
            className="shadow rounded"
          >
            {/* <Button
              variant="plain"
              size="sm"
              className="text-capitalize"
              // onClick={() => setSort('location')}
            >
              Location
            </Button> */}
            <Button
              variant="plain"
              size="sm"
              className="text-capitalize"
              onClick={() => setSort('rating')}
            >
              Rating
            </Button>
            <Button
              variant="plain"
              size="sm"
              className="text-capitalize"
              onClick={() => setSort('name')}
            >
              Name (a-z)
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div>
        <InstituteCard institute={filteredInstitutes} />
      </div>
    </div>
  );
}

export default Institutes;