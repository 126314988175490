import { Edit } from "@mui/icons-material";
import {
  AspectRatio,
  Box,
  Button,
  Divider,
  IconButton,
  ListDivider,
  Typography,
} from "@mui/joy";
import moment from "moment";
// import AddExperience from "pages/user/profile/components/Modals/AddExperience";
import AddResearch from "pages/user/profile/components/Modals/AddResearch";
import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "global/context";


function ResearchCard({ research, getResearch }) {
  const location = useLocation();
  const { userData } = useGlobalContext();

  const [openAddResearch, setOpenAddResearchModel] = useState(false);

  function getResearchDuration(start_date, end_date, is_working) {
    const startDate = moment(start_date);
    const endDate = is_working ? moment() : moment(end_date);

    const years = endDate.diff(startDate, "years");
    startDate.add(years, "years");

    const months = endDate.diff(startDate, "months");
    // console.log("Research === :",research)
    return `${years} years ${months} months`;
  }

  return (
    <>
      <div className="job-item  d-flex justify-content-between">
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            gap: 1,
            py: 1,
            overflow: "auto",
            width: "100%",
            scrollSnapType: "x mandatory",
            "& > *": {
              scrollSnapAlign: "center",
            },
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <AspectRatio ratio="1" sx={{ minWidth: 60 }}>
            <img
              src={`https://images.unsplash.com/photo-1685549925654-e86dcebdd21a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2081&q=80`}
              alt="img"
              className="rounded-3"
            />
          </AspectRatio>
          <Box sx={{ whiteSpace: "nowrap" }} className="w-100 ml-2">
            <div className="d-flex justify-content-between w-100">
              <div>
                <Typography  className='mb-1 fw-bold'>
                  {research?.research_name || "Climate Change Effects"}
                </Typography>
                <Typography className='fs-11 mb-2'>
                  {/* Climate Change Effects" investigates the environmental and <br></br>
                  societal consequences of global warming, emphasizing */}
                  {research?.description}
                </Typography>
              </div>
              {
                (location?.pathname === '/setting/profile/job-profile' || location?.pathname === '/profile') && 
                userData?.id == research?.user_id ?
                  <IconButton variant='plain' 
                    // sx={{position: 'absolute', right: '2.4rem'}}
                    sx={{
                      height: 30,
                      width: 30
                    }}
                    onClick={() => {
                      setOpenAddResearchModel(true);
                    }}
                  >
                    <Edit />
                  </IconButton> : null
              }
            </div>

           
            <Typography level="body2">

              {/* Sep , 2022 &nbsp; - &nbsp; Nov , 2022 */}
              {moment(research?.start_date).format("MMM, YYYY")} -{" "}
              {research?.is_working
                ? "Present"
                : moment(research?.end_date).format("MMM, YYYY")}
              {research?.is_working
                ? ` · ${moment(research?.start_date).fromNow(true)}`
                : ` · ${getResearchDuration(
                  research?.start_date,
                  research?.end_date,
                  research?.is_working
                  )}`}
            </Typography>

            <Typography level="body2">
              {/* {research?.location}, */}
              India · On-site
              <Button variant="plain" size="sm" className="ml-3"  >Link</Button>
            </Typography>
          </Box>
        </Box>
      </div>
      {/* <Divider/> */}
      <AddResearch
        open={openAddResearch}
        setOpen={setOpenAddResearchModel}
        edit={true}
        data={research}
        getResearch={getResearch}
      />
    </>
  );
}

export default ResearchCard;
