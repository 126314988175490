import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Card,
  CardContent,
  Typography as JoyTypography,
  Divider,
} from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useGlobalContext } from "global/context";
import { styled } from "@mui/system";
import Debounce from "pages/utilities/Debouncing"; // Ensure this is correctly imported
import { Popup } from "layout/Popup";
import { useNavigate } from "react-router-dom";

const GlassPaper = styled(Paper)(({ theme }) => ({
  background: "#EEF5DB",
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1), 0px 10px 10px #6c757d",
  border: "1px solid rgba(255, 255, 255, 0.3)",
}));

const AdminJobs = () => {
  const { api } = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const res = await api.get("/admin/jobs/getalljobs");
        setJobs(res.data);
        setFilteredJobs(res.data); // Set initial filtered jobs to all jobs
        setLoading(false);
      } catch (error) {
        if (error.response.status === 409) {
          Popup("error", "Access denied");
          navigate("/");
          return;
        }
        setError(error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, [api, navigate]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const debounceFilterJobs = useCallback(
    Debounce(() => {
      const filtered = jobs.filter(
        (job) =>
          job.job_title.toLowerCase().includes(search.toLowerCase()) ||
          job.institute_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredJobs(filtered);
    }, 1000), // Adjust debounce time as necessary
    [jobs, search]
  );

  useEffect(() => {
    debounceFilterJobs();
  }, [search, debounceFilterJobs]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  if (error)
    return (
      <Container>
        <Alert severity="error">Error loading jobs...refresh this page</Alert>
      </Container>
    );

  return (
    <Container>
      <Box mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search jobs..."
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer component={GlassPaper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#AF9FA5" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Job Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Institute</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Created At</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredJobs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((job) => (
                <TableRow key={job.id}>
                  <TableCell>{job.job_title}</TableCell>
                  <TableCell>{job.institute_name}</TableCell>
                  <TableCell>{job.createdAt?.slice(0, 10)}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        alert(`Viewing details for job ID: ${job.id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredJobs.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </TableContainer>
    </Container>
  );
};

export default AdminJobs;
