import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Swal from "sweetalert2";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import {
  Checkbox,
  Divider,
  FormHelperText,
  ModalClose,
  certifications,
  Option,
  Textarea,
} from "@mui/joy";
import Select, { selectClasses } from "@mui/joy/Select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormGroup } from "@mui/material";
import "./Modal.css";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
 
  certification_name: Yup.string()
    .required("Certificate Name is required"),
  issuing_organization: Yup.string()
    .required("Issue Organization is required"),
  issue_date: Yup.date()
    .required("Start date is required")
    .max(Yup.ref("expiration_date"), "Start date must be Before End date"),
  expiration_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("issue_date"), "End date must be after the start date")
    .max(new Date(), "End date must be today or Before"),
  certificate_id: Yup.string()
    .required("Certificate Id is required")
    .matches(/^[a-zA-Z0-9\s]*$/, "Certificate should not contain numbers"),
  certificate_url: Yup.string()
    .required("Url is required")
});

export default function AddCertification({
  open = false,
  setOpen,
  action,
  edit = false,
  certifications,
  data,
  getCertifications,
}) {
  const [filteredCertifications, setFilteredCertifications] = useState(certifications);
  const { userData, api } = useGlobalContext();
  // console.log("this is function = " ,getCertifications)
  
  // const validateDates = (values) => {
  //   const errors = {};
  //   if (new Date(values.issue_date) >= new Date(values.expiration_date)) {
  //     errors.issue_date = "Issue date must be earlier than expiration date";
  //   }
  //   if (
  //     typeof values.certification_name !== "string" ||
  //     !isNaN(values.certification_name)
  //   ) {
  //     errors.certification_name = "Name should not be number";
  //   }

  //   if (
  //     typeof values.issuing_organization !== "string" ||
  //     !isNaN(values.issuing_organization) 
  //   ) {
  //     errors.issuing_organization = "Organization name should not be number";
  //   }

  //   return errors;
  // };
  
  const formik = useFormik({
    initialValues: {
      certification_name: data?.certification_name || "",
      issuing_organization: data?.issuing_organization || "",
      issue_date: data?.issue_date || new Date().getDate(),
      expiration_date: data?.expiration_date || new Date().getDate(),
      certificate_id: data?.certificate_id || "",
      certificate_url: data?.certificate_url || "",
    },
    // validate: validateDates,
    validationSchema,
    onSubmit: async (values) => {
      values = { ...values, user_id: userData?.id };
      console.log("Add experience: ", values);
      const notify = toast.loading(edit ? "Updating certificate..." : "Adding certificate...");
      try {
        if (edit) {
          const res = await api.put(`/app/candidates/certifications/${data?.id}`, values);
          if (res?.status === 200) {
            setOpen(false);
            await getCertifications();
  // console.log("this is function put me hai = " ,getCertifications)

            toast.success("Certificate updated successfully");
            toast.dismiss(notify);
          } else {
            throw new Error("Failed to update certification");
          }
        } else {
          const res = await api.post(`/app/candidates/certifications/${userData?.id}`, values);
          if (res?.status === 201) {
            toast.dismiss(notify);
            toast.success("Certificate added successfully");
            setOpen(false);
            formik.resetForm();
            getCertifications();
  // console.log("this is function post me hai = " ,getCertifications)

          } else {
            throw new Error("Failed to add certification");
          }
        }
      } catch (error) {
        toast.dismiss(notify);
        toast.error(error.message || "Something went wrong");
      }
    },
  });

  const handleDelete = async (id, certification_name) => {
    setOpen(false)

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then ( async(result) => {
        if (result.isConfirmed) {
          try {

            const res = await api.delete(`/app/candidates/certifications/${id}`);
            if (res?.status === 200) {

              toast.success(`${certification_name} successfully deleted!`);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              getCertifications();
        }
          } catch (error) {
            console.error("Error In Deletion : ",error)
          }
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to delete");
    }
  };

  useEffect(() => {
    setFilteredCertifications(certifications);
  }, [certifications]);

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          layout="center"

          // sx={{ maxWidth: 500 }}
          // variant="plain"
        >
          <ModalClose />
          <div className="px-3 my-3">
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              fontWeight={"lg"}
            >
              {edit ? "Edit certification" : "Add new certification"}
            </Typography>
            <Typography
              id="basic-modal-dialog-description"
              textColor="text.tertiary"
              level="body3"
            >
              Share your awarded certification on this profile
            </Typography>
          </div>
          <FormGroup
            onSubmit={formik.handleSubmit}
            className="scroll-bar-hidden"
            sx={{
              overflow: "scroll",
              mx: "calc(-1 * var(--ModalDialog-padding))",
              px: "var(--ModalDialog-padding)",
            }}
            >
            <Stack spacing={2}>
              <div className="row px-3">
                <FormControl className="col">
                  <FormLabel>Name*</FormLabel>
                  <Input
                    autoFocus
                    required
                    placeholder="Eg - Oracle Certified Java Developer"
                    name="certification_name"
                    value={formik.values.certification_name}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.certification_name && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.certification_name &&
                          formik.errors.certification_name}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Issuing organization*</FormLabel>
                  <Input
                    required
                    name="issuing_organization"
                    placeholder="Eg - Oracle"
                    value={formik.values.issuing_organization}
                    onChange={formik.handleChange}
                  />
                  <div className="ml-3">
                    {formik.errors.issuing_organization && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.issuing_organization &&
                          formik.errors.issuing_organization}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Issue date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="issue_date"
                    placeholder="Issued Date"
                    value={formik.values.issue_date}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                  {formik.errors.issue_date && (
                    <FormHelperText error className="text-danger">
                      {formik.touched.issue_date && formik.errors.issue_date}
                    </FormHelperText>
                  )}
                  </div>
                </FormControl>
                <FormControl className="col ">
                  <FormLabel>Expiry date*</FormLabel>
                  <Input
                    type="date"
                    required
                    name="expiration_date"
                    placeholder="Expiry Date"
                    value={formik.values.expiration_date}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                  {formik.errors.expiration_date && (
                    <FormHelperText error className="text-danger">
                      {formik.touched.expiration_date && formik.errors.expiration_date}
                    </FormHelperText>
                  )}
                  </div>
                </FormControl>
              </div>
              <div className="row d-flex flex-wrap">
                <FormControl className="col">
                  <FormLabel>Certificate ID*</FormLabel>
                  <Input
                    required
                    placeholder="Eg - ABCDGS325256"
                    name="certificate_id"
                    value={formik.values.certificate_id}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.certificate_id && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.certificate_id &&
                          formik.errors.certificate_id}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
              <div className="row">
                <FormControl className="col ">
                  <FormLabel>Certifiacte URL*</FormLabel>
                  <Input
                    startDecorator={
                      <>
                        <Typography>https:// </Typography>
                      </>
                    }
                    required
                    name="certificate_url"
                    value={formik.values.certificate_url}
                    onChange={formik.handleChange}
                    onError={formik.handleError}
                  />
                  <div className="ml-3">
                    {formik.errors.certificate_url && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.certificate_url &&
                          formik.errors.certificate_url}
                      </FormHelperText>
                    )}
                  </div>
                </FormControl>
              </div>
            </Stack>
          </FormGroup>
              <div className="row g-2 d-flex p-3 justify-content-between">
              {/* {edit && ( <Button > Delete </Button> )} */}
              {edit && (
                        <Button onClick={() => handleDelete(data?.id, data?.certification_name)}>Delete</Button>
                    )}                
                  <Button 
                    type="submit"
                    onClick={()=>{
                      formik.handleSubmit()
                      console.log("Clicked", formik.errors)
                    }
                    }
                  >
                  {edit ? "Save changes" : "Add Certification"}
                </Button>
              </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
