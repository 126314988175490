import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const fontFamily = "'Quicksand', sans-serif";
const StyledBox = styled(Box)(({ theme }) => ({
 
  overflow: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const initialValues = {
  course: "",
  duration: "",
  startfrom: "",
  courseDuration: "",
  seats: "",
  fees: "",
  lastDate: "",
  courseMode: [],
  courseType: [],
};

const validationSchema = Yup.object({
  course: Yup.string().required("Course is required"),
  duration: Yup.string().required("Duration is required"),
  startfrom: Yup.date().required("Batch starting date is required"),
  courseDuration: Yup.string().required("Course duration is required"),
  seats: Yup.number().required("Seats are required").positive().integer(),
  fees: Yup.number().required("Fees are required").positive(),
  lastDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startfrom"), "End date should be greater than start date"),
  courseMode: Yup.array()
    .of(Yup.string())
    .min(1, "Select at least one mode")
    .required("Course mode is required"),
  courseType: Yup.array()
    .of(Yup.string())
    .min(1, "Select at least one type")
    .required("Course type is required"),
});

const courseModeOptions = ["online", "offline", "hybrid"];
const courseTypeOptions = ["work-integrated", "part-time", "full-time"];

const PostAdmission = () => {
  const { api, userData } = useGlobalContext();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = { ...values, id: userData?.id, role: userData?.role };
    try {
      await api.post("/v1/createadmission", data);
      navigate("/Institute/admissions");
      toast.success("Successfully created admission");
    } catch (error) {
      if(error.response.status === 403){
        toast.error("You are not a user of institute")
        return
      }
      toast.error("Error creating admission. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{
      height: "85vh",
      overflowY: "scroll",
      justifyContent: "flex-end",
}}>
    <StyledBox >
      <Container
        className="mt-lg-2 mb-5 p-5 bg-light shadow rounded rounded-sm-0 "
        style={{
          backgroundImage: "linear-gradient(45deg, #472fcb, #00a4a5)",
        }}
      >
        <h1 className="fs-60 fw-bold mb-3 text-center text-white">
          {" "}
          Post a new course{" "}
        </h1>
        <h5 className="fs-25 font-weight-bold text-bold mb-3 text-center text-white">
          Please fill out the following fields below <br /> to create a new
          course and submit it.
        </h5>
        <div className="d-flex align-items-end justify-content-center">
          <div className="text-right me-4 top-0 m-2 ">
            <Link
              to="/institute/dashboard"
              className="btn border-2  theme-btn bg-light text-dark rounded border me-4 font-weight-bold "
            >
              Go to Dashboard
            </Link>
          </div>
        </div>
      </Container>
      <Box sx={{ mt: 4, p: 2, bgcolor: "background.paper" }} borderRadius={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                {/* Course Name */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Course Name
                  </Typography>
                  <Field
                    as={TextField}
                    name="course"
                    label="Course"
                    fullWidth
                  />
                  <ErrorMessage
                    name="course"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Duration */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Duration
                  </Typography>
                  <Field
                    as={TextField}
                    name="duration"
                    label="Duration"
                    fullWidth
                  />
                  <ErrorMessage
                    name="duration"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Batch Starting From */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Batch Starting From
                  </Typography>
                  <Field
                    as={TextField}
                    name="startfrom"
                    label="Batch Starting From"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <ErrorMessage
                    name="startfrom"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Course Duration */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Course Duration
                  </Typography>
                  <Field
                    as={TextField}
                    name="courseDuration"
                    label="Course Duration"
                    fullWidth
                  />
                  <ErrorMessage
                    name="courseDuration"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Seats */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Seats
                  </Typography>
                  <Field
                    as={TextField}
                    name="seats"
                    label="Seats"
                    type="number"
                    fullWidth
                  />
                  <ErrorMessage
                    name="seats"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Fees */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Fees
                  </Typography>
                  <Field
                    as={TextField}
                    name="fees"
                    label="Fees"
                    type="number"
                    fullWidth
                  />
                  <ErrorMessage
                    name="fees"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Last Date of Application */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Last Date of Application
                  </Typography>
                  <Field
                    as={TextField}
                    name="lastDate"
                    label="Last Date of Application"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <ErrorMessage
                    name="lastDate"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Course Mode */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Course Mode
                  </Typography>
                  <Field
                    as={TextField}
                    name="courseMode"
                    label="Course Mode"
                    select
                    fullWidth
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                    }}
                  >
                    {courseModeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="courseMode"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                {/* Course Type */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    fontWeight="bold"
                  >
                    Course Type
                  </Typography>
                  <Field
                    as={TextField}
                    name="courseType"
                    label="Course Type"
                    select
                    fullWidth
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                    }}
                  >
                    {courseTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="courseType"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  marginBottom={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Post
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </StyledBox>
    </Box>
  );
};

export default PostAdmission;
