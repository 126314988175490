import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Swal from "sweetalert2";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import {
  Checkbox,
  Divider,
  FormHelperText,
  ModalClose,
  Option,
  Textarea,
} from "@mui/joy";
import Select, { selectClasses } from "@mui/joy/Select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormGroup } from "@mui/material";
import "./Modal.css";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { width } from "@mui/system";

const validationSchema = Yup.object().shape({
  
  award_name: Yup.string()
    .required("Award is required")
    .matches(/^[a-zA-Z\s]*$/, "Award should not contain numbers"),
  description: Yup.string()
    .required("Describe something About your Award."),
  issuing_organization: Yup.string()
    .required("Issue Organization Name is required"),
    // .matches(/^[a-zA-Z\s]*$/, "Organization Name should not contain numbers"),
  issuer: Yup.string()
    .required("Issuer's Name is required")
    .matches(/^[a-zA-Z\s]*$/, "Issuer's Name should not contain numbers"),
  issue_date: Yup.date()
    .required("Start date is required")
    .max(new Date(), "Start date must be today or before"),
});

function AddAwards({
  open = false,
  setOpen,
  action,
  edit = false,
  data,
  award,
  getAward,
}) {
  const { userData, api } = useGlobalContext();
  const [filteredAward , setFilteredAward] = useState(award)
  const formik = useFormik({
    initialValues: {
      award_name: data?.award_name || "",
      description: data?.description || "",
      issuing_organization: data?.issuing_organization || "",
      issuer: data?.issuer || "",
      issue_date: data?.issue_date || new Date().getDate()
    },
    // validate: validateDates,
    validationSchema,
    onSubmit: async (values) => {
      values = { ...values, user_id: userData?.id };
      console.log("Add Award: ", values);
      let notify = toast.loading("Adding Award...");
      try {
        if (edit) {
          const res = await api.put(
            `/app/candidates/award/${data?.id}`,
            values
          );
          if (res?.status === 200) {
            console.log("Successfully Updated");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Saved");
            getAward();
          }
        } else {
          const res = await api.post(
            `/app/candidates/award/${userData?.id}`,
            values
          );
          if (res?.status === 201) {
            console.log("Successfully Added");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Award Added");
            formik.resetForm(); // Reset form values
            getAward();
          }
        }
      } catch (error) {
        console.log(error);
        toast.dismiss(notify);
        toast.error("Something went wrong!");
      }
    },
  });

  const handleDelete = async (id, award_name) => {
    setOpen(false)
    console.log("Clicked")
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then ( async(result) => {
        if (result.isConfirmed) {
          try {

            const res = await api.delete(`/app/candidates/award/${id}`);
            if (res?.status === 200) {

              toast.success(`${award_name} successfully deleted!`);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              getAward();
        }
          } catch (error) {
            console.error("Error In Deletion : ",error)
          }
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to delete");
    }
  };

  useEffect(() => {
    setFilteredAward(award);
  }, [award]);

  return (
    <>
      <React.Fragment>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            layout="center"
            style={{width:"500px"}}
            // sx={{ maxWidth: 500 }}
            // variant="plain"
          >
            <ModalClose />
            <div className="px-3 my-3">
              <Typography
                id="basic-modal-dialog-title"
                component="h2"
                fontWeight={"lg"}
              >
                {edit ? "Edit About Award" : "Add new Award"}
              </Typography>
              <Typography
                id="basic-modal-dialog-description"
                textColor="text.tertiary"
                level="body3"
              >
                Share something about your Award
              </Typography>
            </div>
            <FormGroup
              onSubmit={formik.handleSubmit}
              className="scroll-bar-hidden"
              sx={{
                overflow: "scroll",
                mx: "calc(-1 * var(--ModalDialog-padding))",
                px: "var(--ModalDialog-padding)",
              }}
            >
              <Stack spacing={2}>
                <div className="row px-3">
                  <FormControl className="col">
                    <FormLabel> Award Name*</FormLabel>
                    <Input
                      autoFocus
                      required
                      placeholder="Eg - Quantum Computing Algorithms  "
                      name="award_name"
                      value={formik.values.award_name}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {formik.errors.award_name && (
                        <FormHelperText error className="text-danger">
                          {formik.touched.award_name && 
                            formik.errors.award_name}
                        </FormHelperText>
                      )}
                    </div>
                  </FormControl>
                </div>
                <FormControl className="row">
                  <div className="col">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      minRows={3}
                      placeholder="Describe About your Award"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {formik.errors.description && (
                        <FormHelperText error className="text-danger">
                          {formik.touched.description && 
                            formik.errors.description}
                        </FormHelperText>
                      )}
                    </div>
                    {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                  </div>
                </FormControl>
                <FormControl className="row">
                  <div className="col">
                    <FormLabel>Issuing Organization</FormLabel>
                    <Input
                      autoFocus
                      required
                      placeholder="issuing organization.."
                      name="issuing_organization"
                      value={formik.values.issuing_organization}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {formik.errors.issuing_organization && (
                        <FormHelperText error className="text-danger">
                          {formik.touched.issuing_organization && 
                            formik.errors.issuing_organization}
                        </FormHelperText>
                      )}
                    </div>
                    {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                  </div>
                </FormControl>
                <FormControl className="row">
                  <div className="col">
                    <FormLabel>Issuer</FormLabel>
                    <Input
                      autoFocus
                      required
                      placeholder="issuer Who Issue"
                      name="issuer"
                      value={formik.values.issuer}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {formik.errors.issuer && (
                        <FormHelperText error className="text-danger">
                          {formik.touched.issuer && 
                            formik.errors.issuer}
                        </FormHelperText>
                      )}
                    </div>
                    {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                  </div>
                </FormControl>
                <div className="row d-flex flex-wrap">
                  <FormControl className="col">
                    <FormLabel>issue date*</FormLabel>
                    <Input
                      type="date"
                      required
                      name="issue_date"
                      placeholder="Issued Date"
                      value={formik.values.issue_date}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                    {formik.errors.issue_date && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.issue_date && formik.errors.issue_date}
                      </FormHelperText>
                    )}
                    </div>
                  </FormControl>
                </div>
              </Stack>
            </FormGroup>
                <div className="row g-2 d-flex p-3 justify-content-between">
              {/* {edit && ( <Button > Delete </Button> )} */}
              {edit && (
                        <Button onClick={() => handleDelete(data?.id, data?.award_name)}>Delete</Button>
                    )}                 
                    <Button type="submit"
                      onClick={()=>{
                        formik.handleSubmit()
                        console.log("Clicked ", formik.errors)
                      }}
                    >
                    {edit ? "Save changes" : "Add Award"}
                  </Button>
                </div>
          </ModalDialog>
        </Modal>
      </React.Fragment>
    </>
  );
}

export default AddAwards;
