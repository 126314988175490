import React from "react";

const CallToAction2 = () => {
  return (
    <section
      className="get-started-area py-5 bg-gray"
      id="for-businesses"
    >
      <div className="container">
        <div className="text-center">
          <h2 className="section-title pb-3">Services Specific To Institutes</h2>
          <p className="section-desc w-50 mx-auto">
            We offer few special services specifically designed to cater institutes needs.
          </p>
        </div>
        <div className="row pt-50px d-flex ">
          <div className="col-lg-4 responsive-column-half " >
            <div className="media media-card align-items-center hover-s rounded-4 shadow " style={{ height: "90%" }}>
              <div className="icon-element mr-3 bg-1 d-flex align-items-center justify-content-center">
                <svg
                  className="svg-icon-color-white"
                  width={32}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 480 480"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M240,0c-26.51,0-48,21.49-48,48s21.49,48,48,48c26.499-0.026,47.974-21.501,48-48C288,21.49,266.51,0,240,0z M240,80
                                            c-17.673,0-32-14.327-32-32s14.327-32,32-32c17.673,0,32,14.327,32,32S257.673,80,240,80z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M272,104h-1.888l-1.688,0.84c-17.901,8.913-38.947,8.913-56.848,0l-1.688-0.84H208c-22.08,0.026-39.974,17.92-40,40v48
                                            c0,13.255,10.745,24,24,24h96c13.255,0,24-10.745,24-24v-48C311.974,121.92,294.08,104.026,272,104z M296,192c0,4.418-3.582,8-8,8
                                            h-96c-4.418,0-8-3.582-8-8v-48c0.002-12.592,9.735-23.042,22.296-23.936c21.375,9.92,46.034,9.92,67.408,0
                                            c12.56,0.894,22.294,11.344,22.296,23.936V192z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M408,264c-26.51,0-48,21.49-48,48c0,26.51,21.49,48,48,48c26.51,0,48-21.49,48-48
                                            C455.974,285.501,434.499,264.026,408,264z M408,344c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32
                                            c17.673,0,32,14.327,32,32C440,329.673,425.673,344,408,344z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M440,368h-1.888l-1.688,0.8c-17.901,8.912-38.947,8.912-56.848,0l-1.688-0.8H376c-22.08,0.026-39.974,17.92-40,40v48
                                            c0,13.255,10.745,24,24,24h96c13.255,0,24-10.745,24-24v-48C479.974,385.92,462.08,368.026,440,368z M464,456c0,4.418-3.582,8-8,8
                                            h-96c-4.418,0-8-3.582-8-8v-48c0.002-12.592,9.735-23.042,22.296-23.936c21.375,9.92,46.033,9.92,67.408,0
                                            C454.265,384.958,463.998,395.408,464,408V456z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M72,264c-26.51,0-48,21.49-48,48c0,26.51,21.49,48,48,48s48-21.49,48-48C119.974,285.501,98.499,264.026,72,264z M72,344
                                            c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32s32,14.327,32,32C104,329.673,89.673,344,72,344z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M104,368h-1.888l-1.688,0.8c-17.901,8.912-38.947,8.912-56.848,0l-1.688-0.8H40c-22.08,0.026-39.974,17.92-40,40v48
                                            c0,13.255,10.745,24,24,24h96c13.255,0,24-10.745,24-24v-48C143.974,385.92,126.08,368.026,104,368z M128,456c0,4.418-3.582,8-8,8
                                            H24c-4.418,0-8-3.582-8-8v-48c0.002-12.592,9.735-23.042,22.296-23.936c21.375,9.92,46.033,9.92,67.408,0
                                            C118.265,384.958,127.998,395.408,128,408V456z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M319.372,406.276c-0.004-0.009-0.008-0.018-0.012-0.028c-1.732-4.065-6.431-5.956-10.495-4.224c0,0,0,0,0,0
                                            c-36.125,15.14-76.245,17.902-114.104,7.856l7.488-2.2c4.242-1.242,6.674-5.686,5.432-9.928s-5.686-6.674-9.928-5.432l-32,9.384
                                            c-0.12,0-0.208,0.136-0.32,0.176c-0.741,0.276-1.437,0.662-2.064,1.144c-0.278,0.15-0.545,0.318-0.8,0.504
                                            c-0.844,0.753-1.513,1.681-1.96,2.72c-0.434,1.046-0.641,2.172-0.608,3.304c0.195,1.083,0.462,2.152,0.8,3.2
                                            c0.048,0.112,0,0.24,0.088,0.352l16,30.616c2.046,3.919,6.881,5.438,10.8,3.392s5.438-6.881,3.392-10.8l-6.512-12.448
                                            c43.014,12.93,89.195,10.417,130.552-7.104C319.189,415.039,321.093,410.345,319.372,406.276z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.656,210.344c-3.124-3.123-8.188-3.123-11.312,0l-11.32,11.32c-6.624-70.07-51.111-130.876-115.888-158.4
                                            c-4.065-1.732-8.764,0.159-10.496,4.224c-1.732,4.065,0.159,8.764,4.224,10.496c58.11,24.697,98.504,78.669,105.816,141.384
                                            l-9.024-9.024c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116l24,24c3.12,3.128,8.186,3.135,11.314,0.014
                                            c0.005-0.005,0.01-0.01,0.014-0.014l24-24C456.791,218.528,456.784,213.464,453.656,210.344z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M175.768,69.272c-0.026-0.467-0.095-0.93-0.208-1.384c0-0.072-0.088-0.12-0.12-0.2c-0.032-0.08,0-0.136,0-0.2
                                            c-0.218-0.389-0.47-0.759-0.752-1.104c-0.259-0.469-0.565-0.911-0.912-1.32c-0.473-0.419-0.997-0.776-1.56-1.064
                                            c-0.281-0.239-0.581-0.456-0.896-0.648l-32-14.616c-4.087-1.678-8.761,0.275-10.439,4.362c-1.587,3.865,0.068,8.301,3.799,10.182
                                            l16.568,7.576C86.875,104.237,47.955,169.256,48,240c0,4.418,3.582,8,8,8s8-3.582,8-8c-0.04-62.293,32.901-119.952,86.584-151.552
                                            l-5.784,12.088c-1.907,3.977-0.236,8.747,3.736,10.664c1.079,0.527,2.263,0.801,3.464,0.8c3.072-0.004,5.87-1.767,7.2-4.536
                                            l16-33.384c0-0.08,0-0.168,0.064-0.248c0.208-0.547,0.353-1.116,0.432-1.696c0.137-0.431,0.239-0.872,0.304-1.32
                                            C175.973,70.295,175.896,69.778,175.768,69.272z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="media-body">
                <h5 className="pb-2">
                  <a href="/">Private Q&amp;A</a>
                </h5>
                {/* <p>
                  Quickly find and share internal knowledge with Private Q&amp;A
                </p> */}
                <p>
                  Easy search for discussions specific to your institute, option for making them private or public.
                </p>
              </div>
            </div>
          </div>
          {/* end col-lg-4 */}
          <div className="col-lg-4 responsive-column-half ">
            <div className="media media-card align-items-center hover-s rounded-4 shadow" style={{ height: "90%" }}>
              <div className="icon-element mr-3 bg-2 d-flex align-items-center justify-content-center">
                <svg
                  className="svg-icon-color-white"
                  width={32}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M500.71,446.149l-92.7-92.7c2.548-3.879,2.118-9.141-1.293-12.552c-3.41-3.41-8.674-3.841-12.552-1.293l-24.737-24.737
                                        c18.489-27.447,30.128-59.238,33.495-92.395c4.611-45.412-6.37-91.445-30.92-129.622c-2.987-4.645-9.174-5.988-13.818-3.002
                                        c-4.644,2.987-5.988,9.174-3.002,13.818c45.911,71.392,35.6,166.844-24.517,226.961c-70.95,70.95-186.394,70.95-257.345,0
                                        c-70.95-70.949-70.95-186.393,0-257.344c60.181-60.181,152.594-70.842,224.73-25.931c4.686,2.917,10.853,1.485,13.772-3.203
                                        c2.919-4.687,1.484-10.853-3.203-13.772C270.552,6.677,224.901-3.693,180.068,1.174C134.551,6.117,91.62,26.705,59.18,59.143
                                        c-78.746,78.747-78.746,206.878,0,285.624c39.374,39.374,91.093,59.06,142.813,59.06c39.403,0,78.798-11.44,112.741-34.292
                                        l24.613,24.613c-3.698,3.918-3.639,10.089,0.195,13.924c1.953,1.953,4.511,2.929,7.07,2.929c2.468,0,4.93-0.917,6.854-2.733
                                        l92.563,92.563c7.45,7.45,17.244,11.169,27.065,11.169c9.911,0,19.85-3.791,27.423-11.364
                                        C515.591,485.56,515.679,461.117,500.71,446.149z M335.366,361.889l26.402-26.402l18.11,18.11l-26.402,26.402L335.366,361.889z
                                         M486.376,486.496c-7.28,7.279-19.036,7.367-26.207,0.194l-92.553-92.553l26.402-26.402l92.552,92.553
                                        C493.742,467.461,493.655,479.217,486.376,486.496z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M345.044,59.381l-0.217-0.217c-3.892-3.916-10.223-3.935-14.14-0.043c-3.916,3.892-3.936,10.223-0.043,14.14l0.26,0.261
                                        c1.953,1.953,4.511,2.929,7.07,2.929s5.118-0.976,7.07-2.929C348.948,69.618,348.948,63.287,345.044,59.381z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M201.993,47.981c-84.902,0-153.975,69.073-153.975,153.975s69.073,153.975,153.975,153.975
                                        s153.975-69.073,153.975-153.975S286.895,47.981,201.993,47.981z M201.993,335.934c-73.876,0-133.978-60.102-133.978-133.978
                                        S128.117,67.978,201.993,67.978s133.978,60.102,133.978,133.978S275.869,335.934,201.993,335.934z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M240.041,188.963c6.228-8.096,9.944-18.22,9.944-29.201v-13.096c0-26.463-21.529-47.992-47.992-47.992
                                        s-47.992,21.529-47.992,47.992v13.096c0,10.981,3.715,21.106,9.944,29.201c-31.8,5.997-55.937,33.966-55.937,67.484v5.166
                                        c0,5.522,4.476,9.998,9.998,9.998h167.972c5.522,0,9.998-4.476,9.998-9.998v-5.166
                                        C295.978,222.93,271.841,194.961,240.041,188.963z M173.998,146.666c0-15.436,12.559-27.995,27.995-27.995
                                        s27.995,12.559,27.995,27.995v13.096c0,15.436-12.559,27.995-27.995,27.995s-27.995-12.559-27.995-27.995V146.666z
                                         M128.243,251.614c2.434-24.589,23.236-43.86,48.455-43.86h50.591c25.219,0,46.021,19.271,48.455,43.86H128.243z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M442.305,11.987c-5.522,0-9.998,4.476-9.998,9.998v12.428c0,5.521,4.476,9.998,9.998,9.998s9.998-4.476,9.998-9.998
                                        V21.986C452.303,16.463,447.827,11.987,442.305,11.987z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M442.305,71.547c-5.522,0-9.998,4.476-9.998,9.998v12.428c0,5.522,4.476,9.998,9.998,9.998s9.998-4.476,9.998-9.998
                                        V81.546C452.303,76.024,447.827,71.547,442.305,71.547z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M478.299,47.981h-12.428c-5.522,0-9.998,4.476-9.998,9.998s4.476,9.998,9.998,9.998h12.428
                                        c5.522,0,9.998-4.476,9.998-9.998S483.821,47.981,478.299,47.981z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M418.738,47.981H406.31c-5.522,0-9.998,4.476-9.998,9.998s4.476,9.998,9.998,9.998h12.428
                                        c5.522,0,9.998-4.476,9.998-9.998S424.261,47.981,418.738,47.981z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M61.937,412.008c-5.522,0-9.998,4.476-9.998,9.998v12.428c0,5.522,4.476,9.998,9.998,9.998s9.998-4.476,9.998-9.998
                                        v-12.428C71.935,416.484,67.459,412.008,61.937,412.008z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M61.937,471.568c-5.522,0-9.998,4.476-9.998,9.998v12.428c0,5.522,4.476,9.998,9.998,9.998s9.998-4.476,9.998-9.998
                                        v-12.428C71.935,476.044,67.459,471.568,61.937,471.568z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M97.931,448.002H85.503c-5.522,0-9.998,4.476-9.998,9.998s4.476,9.998,9.998,9.998h12.428
                                        c5.522,0,9.998-4.476,9.998-9.998S103.453,448.002,97.931,448.002z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M38.371,448.002H25.943c-5.522,0-9.998,4.476-9.998,9.998s4.476,9.998,9.998,9.998h12.428
                                        c5.522,0,9.998-4.476,9.998-9.998S43.893,448.002,38.371,448.002z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="media-body">
                <h5 className="pb-2">
                  <a href="talent.html">Talent solutions</a>
                </h5>
                {/* <p>
                  Find the perfect candidate for your growing technical team
                </p> */}
                <p>
                  Easy search for the candidate of your choice, AI based recommendation.
                </p>
              </div>
            </div>
          </div>
          {/* end col-lg-4 */}
          <div className="col-lg-4 responsive-column-half ">
            <div className="media media-card align-items-center hover-s rounded-4 shadow" style={{ height: "90%" }}>
              <div className="icon-element mr-3 bg-3 d-flex align-items-center justify-content-center">
                <svg
                  className="svg-icon-color-white"
                  width={32}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 396.821 396.821"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M394.583,8.054c-0.422-3.413-3.11-6.101-6.522-6.523c-30.748-3.803-62.477-0.488-91.767,9.583
                                                c-29.293,10.072-56.355,26.973-78.258,48.876l-49.983,49.983l-72.149,9.305c-1.645,0.212-3.172,0.963-4.345,2.135l-69.563,69.563
                                                c-1.969,1.969-2.685,4.868-1.858,7.528c0.825,2.66,3.058,4.643,5.796,5.15l52.597,9.742l10.335,10.335l-22.003,11.915
                                                c-2.082,1.127-3.51,3.172-3.851,5.515s0.444,4.709,2.118,6.383l83.438,83.438c1.417,1.417,3.329,2.197,5.304,2.197
                                                c0.358,0,0.72-0.026,1.08-0.078c2.343-0.341,4.388-1.769,5.515-3.851l11.916-22.003l10.335,10.335l9.742,52.597
                                                c0.508,2.739,2.49,4.971,5.15,5.797c0.731,0.227,1.48,0.337,2.224,0.337c1.96,0,3.876-0.769,5.305-2.197l69.563-69.563
                                                c1.172-1.172,1.923-2.7,2.135-4.344l9.306-72.15l49.983-49.984c21.903-21.903,38.804-48.964,48.876-78.257
                                                C395.072,70.528,398.385,38.795,394.583,8.054z M79.674,198.355l-36.989-6.851l57.673-57.675l50.332-6.491L79.674,198.355z
                                                 M152.065,313.268L82.846,244.05l17.085-9.252l61.385,61.386L152.065,313.268z M262.285,295.756l-57.674,57.674l-6.852-36.988
                                                l71.017-71.017L262.285,295.756z M325.517,167.471l-135.85,135.85l-96.874-96.874l135.85-135.851
                                                c19.738-19.739,44.002-35.076,70.287-44.49c3.395,17.492,11.948,33.719,24.654,46.424c12.705,12.706,28.931,21.259,46.424,24.655
                                                C360.593,123.468,345.255,147.732,325.517,167.471z M374.523,82.774c-15.203-2.593-29.345-9.863-40.333-20.85
                                                c-10.988-10.987-18.257-25.13-20.85-40.333c21.741-5.859,44.579-7.857,66.99-5.807C382.381,38.195,380.382,61.033,374.523,82.774
                                                z"
                        />
                        <path
                          d="M221.325,110.443c-17.74,17.741-17.74,46.606,0,64.347c8.871,8.871,20.521,13.305,32.174,13.305
                                                c11.649,0,23.304-4.436,32.173-13.305h0.001c17.74-17.74,17.74-46.606-0.001-64.347
                                                C267.931,92.703,239.065,92.704,221.325,110.443z M275.066,164.183c-11.894,11.893-31.244,11.891-43.134,0
                                                c-11.893-11.892-11.893-31.242,0-43.134c5.945-5.946,13.756-8.918,21.566-8.918c7.811,0,15.621,2.973,21.566,8.918
                                                C286.957,132.941,286.957,152.291,275.066,164.183z"
                        />
                        <path
                          d="M98.365,299.165c-2.93-2.929-7.678-2.929-10.607,0L23.41,363.512c-2.929,2.929-2.929,7.678,0,10.606
                                                c1.465,1.464,3.385,2.197,5.304,2.197s3.839-0.732,5.304-2.197l64.347-64.347C101.293,306.843,101.293,302.094,98.365,299.165z"
                        />
                        <path
                          d="M108.263,319.671l-28.991,28.991c-2.929,2.929-2.929,7.678,0,10.606c1.465,1.464,3.385,2.197,5.304,2.197
                                                s3.839-0.732,5.304-2.197l28.991-28.991c2.929-2.929,2.929-7.678,0-10.606C115.941,316.742,111.193,316.742,108.263,319.671z"
                        />
                        <path
                          d="M69.123,361.919c-3.138,0-6.002,2.024-7.062,4.973c-1.078,2.998-0.075,6.441,2.416,8.416
                                                c2.547,2.02,6.266,2.13,8.928,0.265c2.84-1.99,3.992-5.81,2.639-9.024C74.931,363.774,72.099,361.919,69.123,361.919z"
                        />
                        <path d="M76.044,366.549C76.234,367,75.864,366.099,76.044,366.549L76.044,366.549z" />
                        <path
                          d="M47.91,380.025l-3.992,3.992c-2.93,2.929-2.93,7.678-0.001,10.607c1.465,1.464,3.384,2.197,5.304,2.197
                                                c1.919,0,3.839-0.732,5.303-2.196l3.992-3.992c2.93-2.929,2.93-7.678,0.001-10.606C55.588,377.099,50.838,377.096,47.91,380.025z
                                                "
                        />s
                        <path
                          d="M42.502,314.014c-2.93-2.929-7.678-2.929-10.607,0L2.904,343.005c-2.929,2.929-2.929,7.678,0,10.606
                                                c1.465,1.464,3.385,2.197,5.304,2.197s3.839-0.732,5.304-2.197l28.991-28.991C45.431,321.692,45.431,316.943,42.502,314.014z"
                        />
                        <path
                          d="M54.472,311.136c3.043-0.765,5.327-3.417,5.644-6.537c0.311-3.055-1.369-6.049-4.096-7.427
                                                c-2.895-1.464-6.523-0.853-8.769,1.494c-2.405,2.513-2.752,6.426-0.852,9.335c-0.06-0.09-0.106-0.156,0.015,0.029
                                                c0.126,0.185,0.083,0.118,0.023,0.029C48.204,310.626,51.429,311.901,54.472,311.136z"
                        />
                        <path
                          d="M73.867,293.257l3.991-3.992c2.929-2.929,2.929-7.678-0.001-10.606c-2.932-2.93-7.681-2.929-10.606,0.001l-3.991,3.992
                                                c-2.929,2.929-2.929,7.678,0.001,10.606c1.465,1.464,3.384,2.196,5.303,2.196C70.483,295.454,72.403,294.722,73.867,293.257z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="media-body">
                <h5 className="pb-2">
                  <a href="advertising.html">Advertising platform</a>
                </h5>
                {/* <p>Accelerate the discovery of your products or services</p> */}
                <p>Easy showcasing of teaching methodology and unique features of your institute, get recommendations.</p>
              </div>
            </div>
          </div>
          {/* end col-lg-4 */}
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </section >
  );
};

export default CallToAction2;
