import React, { useEffect, useState } from "react";
import { Box, Collapse } from "@mui/material";
import {
  Dashboard,
  Home,
  Work,
  People,
  Assessment,
  ExpandLess,
  ExpandMore,
  NotificationAdd,
  EmojiPeople,
  School,
  Poll,
  Logout,
} from "@mui/icons-material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  List,
} from "@mui/joy";
import { useGlobalContext } from "global/context";
import { Col, Row } from "react-bootstrap";

const fontFamily = "'Quicksand', sans-serif";

const SidebarItem = ({
  index,
  selectedIndex,
  setSelectedIndex,
  to,
  icon,
  label,
  color,
}) => (
  <Link to={to}>
    <ListItem className="mb-1">
      <ListItemButton
        selected={selectedIndex === index}
        variant={selectedIndex === index ? "solid" : "plain"}
        color={selectedIndex === index ? color : undefined}
        onClick={() => setSelectedIndex(index)}
        className="rounded mb-1"
      >
        <ListItemDecorator className="rounded" sx={{ p: 2.2 }}>
          {icon}
        </ListItemDecorator>
        <ListItemContent sx={{ fontFamily: fontFamily, fontWeight: "bold" }}>
          {label}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  </Link>
);

const SidebarCollapseItem = ({
  index,
  selectedIndex,
  icon,
  label,
  open,
  handleClick,
  children,
}) => (
  <>
    <ListItem>
      <ListItemButton
        selected={selectedIndex === index}
        variant={selectedIndex === index ? "solid" : "plain"}
        color={selectedIndex === index ? "primary" : undefined}
        onClick={handleClick}
        className="rounded mb-1"
      >
        <ListItemDecorator className="p-2 rounded">{icon}</ListItemDecorator>
        <ListItemContent sx={{ fontFamily: fontFamily, fontWeight: "bold" }}>
          {label}
        </ListItemContent>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div">{children}</List>
    </Collapse>
  </>
);

const Adminsidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [reportsOpen, setReportsOpen] = useState(false);
  const { removeToken, removeUser } = useGlobalContext();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const path = location?.pathname;
  useEffect(() => {
    if (path.startsWith("/admin/dashboard")) setSelectedIndex(1);
    else if (path.startsWith("/admin/notification")) setSelectedIndex(2);
    else if (path.startsWith("/admin/instituteData")) setSelectedIndex(3);
    else if (path.startsWith("/admin/users")) setSelectedIndex(4);
    else if (path.startsWith("/admin/reports/pollresults")) setSelectedIndex(5);
    else if (path.startsWith("/admin/reports/jobcategory")) setSelectedIndex(6);
  }, [path]);

  const handleLogout = () => {
    localStorage.removeItem("isLogin");
    removeToken();
    removeUser();
    navigate("/auth/admin/login");
  };

  return (
    <Box sx={{ backgroundColor: "#f8f9fa", width: 250, height: "100dvh" }}>
      <Row className="p-3 rounded">
        <Col>
          <SidebarItem
            index={1}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            to="/admin/dashboard"
            icon={<Dashboard />}
            label="Dashboard"
            color="primary"
          />
          <SidebarItem
            index={2}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            to="/admin/notification"
            icon={<NotificationAdd />}
            label="Notifications"
            color="info"
          />
          <SidebarItem
            index={3}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            to="/admin/instituteData"
            icon={<School />}
            label="Institutes"
            color="success"
          />
          <SidebarItem
            index={4}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            to="/admin/users"
            icon={<People />}
            label="Users"
            color="neutral"
          ></SidebarItem>
          <SidebarCollapseItem
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            icon={<Assessment />}
            label="Reports"
            open={reportsOpen}
            handleClick={() => setReportsOpen(!reportsOpen)}
          >
            <SidebarItem
              index={5}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              to="/admin/reports/pollresults"
              icon={<Poll />}
              label="Poll Results"
              color="primary"
            />
            <SidebarItem
              index={6}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              to="/admin/reports/jobcategory"
              icon={<Poll />}
              label="Job Category"
              color="info"
            />
          </SidebarCollapseItem>
          <ListItem className="mb-1">
            <ListItemButton
              variant="plain"
              color={selectedIndex === 9 ? "primary" : undefined}
              onClick={handleLogout}
              className="rounded mb-1"
            >
              <ListItemDecorator className="p-2 rounded">
                <Logout />
              </ListItemDecorator>
              <ListItemContent
                sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
              >
                Logout
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </Col>
      </Row>
    </Box>
  );
};

export default Adminsidebar;
