import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarRounded from "@mui/icons-material/StarRounded";
import { useGlobalContext } from "global/context";

const labels = {
  0.5: "JustStarted",
  1: "justStarted+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function Header({ color, profile, profileData }) {
  const formattedSkills = profile?.skills.join(", ");
  const { userData } = useGlobalContext();
  const [value, setValue] = React.useState(5);
  const [hover, setHover] = React.useState(-1);

  return (
    <>
      <div style={{ backgroundColor: color }}>
        <div className="container">
          <div className="row border-bottom">
            <div className="p-5" style={{ marginLeft: "-15px" }}>
            <div className="d-flex justify-content-end">
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="d-flex col text-white text-end">
                    Rating
                    <span className="text-white fs-18 fw-bold">
                      {profile?.rating || 0}&nbsp;
                    </span>
                    <Rating
                      name="text-feedback"
                      value={profile?.rating || 0}
                      readOnly={
                        profile?.id === userData?.id || userData?.id === undefined
                          ? true
                          : false
                      }
                      precision={0.5}
                      icon={<StarRounded fontSize="inherit" />}
                      emptyIcon={
                        <StarRounded style={{ opacity: 0.55 }} fontSize="inherit" />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 1 }}>
                        {labels[Math.round(profile?.rating) || 0.5]}
                      </Box>
                    )}
                  </div>
                </Box>
              </div>
              <div className="mb-3">
                <h1 className="fs-60 font-weight-bold text-white">
                  {profile?.name}
                </h1>
              </div>
              <div className="d-flex mb-3">
                <i className="bi bi-envelope-at-fill text-white">
                  &nbsp; {profile?.email}
                </i>
                &nbsp;&nbsp;&nbsp;
                <i className="bi bi-telephone-fill text-white">
                  &nbsp; {profileData?.phone}
                </i>
                &nbsp;&nbsp;&nbsp;
                <i className="bi bi-geo-alt-fill text-white">
                  &nbsp;{profile?.presentWork?.location}
                </i>
                &nbsp;&nbsp;&nbsp;
              </div>

              <div className="mb-3">
                <i className="text-white">&nbsp;Skills - {formattedSkills}</i>
              </div>
              <div className="mb-2 d-flex justify-content-end">
                <h6 className="text-white" style={{ wordWrap: "break-word" }}>
                  Profile Link{" - "}
                  <a
                    href={window.location.origin + "/" + profile?.username}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    {window.location.origin + "/" + profile?.username}
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
