import React, { useMemo } from "react";
import Add from "@mui/icons-material/Add";
import { Avatar, Box, Chip, Table, Typography } from "@mui/joy";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button } from "@mui/joy";
import { toast } from "react-hot-toast";
import { useGlobalContext } from "global/context";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Createteacher from "./Createteacherprofile";
import {
  ChangeDepartmentModal,
  ChangeTeacherModal,
  CreateDepartmentModal,
  CreateTeacherModal,
  DeleteDepartmentModal,
  DeleteTeacherModal,
} from "./Modals";
// import { styled } from '@mui/system';
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhone = (phone) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phone);
};

const Departments = React.memo(() => {
  const { api, userData } = useGlobalContext();
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [teachersData, setTeachersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const [changedepartmentModal, setchangedepartmentModal] = useState(false);
  const [changeteacherModal, setchangeteacherModal] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [departments, setDepartments] = useState([]);
  const [teacherloading, setteacherloading] = useState(false);
  const [departmentloading, setdepartmentloading] = useState(false);
  const [currentTeacherId, setcurrentTeacherId] = useState(null);
  const [currentDepartmentId, setcurrentDepartmentId] = useState(null);
  const [openCreateTeachermodal, setOpenCreateTeachermodal] = useState(false);
  const [currentTeacher, setcurrentTeacher] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  //validate phonenumber and email function
  const validate = () => {
    const newErrors = {};
    if (!isValidEmail(newTeacher.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!isValidPhone(newTeacher.phone)) {
      newErrors.phone = "Invalid phone number format";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleDepartmentNameChange = (e) => {
    setDepartmentName(e.target.value);
  };
  //get the department when user selected particular department
  const handleDepartmentBox = (department) => {
    setOpen(true);
    setCurrentDepartment(department);
    setNewTeacher({
      name: "",
      designation: "",
      specialization: "",
      email: "",
      phone: "",
    });
  };
  const handleChangeDepartmentBox = (department) => {
    setchangedepartmentModal(true);
    setCurrentDepartment(department);
    if (department) {
      setDepartmentName(department.name);
    } else {
      setDepartmentName(""); // Clear departmentName when creating new department
    }
  };
  const handleChangeTeacherBox = (teacher) => {
    setchangeteacherModal(true);
    setCurrentDepartment(teacher);
    setNewTeacher({
      name: teacher.name,
      designation: teacher.designation,
      specialization: teacher.specialization,
      email: teacher.email,
      phone: teacher.phone,
    });
  };
  const handleDeleteTeacherBox = (id) => setcurrentTeacherId(id);

  const handleDeleteDepartmentBox = (id) => setcurrentDepartmentId(id);
  //get departments and teachers functionalities
  const fetchData = useMemo(
    () => async () => {
      setloading(true);
      try {
        const res = await api.get(`/v1/getdepartments/${userData.id}`);
        setDepartments(res?.data?.data);
        res?.data?.data.forEach(async (teacher) => {
          try {
            const teacherRes = await api.get(`/v1/getteachers/${teacher.id}`);
            const teachersData = teacherRes.data;
            setTeachersData((prev) => ({
              ...prev,
              [teacher.id]: teachersData,
            }));
          } catch (err) {
            console.error(
              "Error fetching teachers for department",
              teacher.id,
              ":",
              err
            );
          }
        });
        setloading(false);
      } catch (err) {
        console.error("Error fetching department", err);
      }
    },
    [api, userData.id]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  // create departments functionalities
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!departmentName) {
      toast.error("Please enter department name");
      return;
    }
    const newDepartment = {
      name: departmentName,
      id: userData?.id,
      role:userData?.role
    };
    try {
      const res = await api.post("/v1/postdepartments", newDepartment);
      fetchData();
      toast.success(res?.data);
      setDepartmentName("");
    } catch (err) {
      if (err?.response?.status == 400) {
        toast.error("Department already exists");
        return;
      }
      if(err?.response?.status === 403){
        toast.error("You are not a user of institute")
        return
      }
      toast.error("Error submitting department", err);
    }
    setOpenmodal(false);
  };

  const [newTeacher, setNewTeacher] = useState({
    name: "",
    designation: "",
    specialization: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTeacher((prevTeacher) => ({ ...prevTeacher, [name]: value }));
  };

  //create teacher functionalities
  const handleSubmit2 = async (department) => {
    if (
      !newTeacher.name ||
      !newTeacher.email ||
      !newTeacher.phone ||
      !newTeacher.designation ||
      !newTeacher.specialization
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    if (validate()) {
      try {
        const res = await api.post("/v1/postteacher", {
          teacher: {
            ...newTeacher,
            department_id: department.id, // Include department_id
          },
          id: [userData?.id],
        });
        fetchData();
        toast.success(res?.data?.message);
        setOpen(false);
        setNewTeacher({
          name: "",
          designation: "",
          specialization: "",
          email: "",
          phone: "",
        });
      } catch (err) {
        if (err?.response?.status == 400) {
          toast.error(err?.response?.data);
          return;
        }
        toast.error("Error creating teacher");
      }
    }
  };
  //Edit the departmentName
  const handleEditdepartment = async (department) => {
    try {
      await api.put(`/v1/updatedepartment/${department.id}`, {
        name: departmentName,
      });
      fetchData();
      toast.success("Department updated successfully");
    } catch (err) {
      toast.error("Error updating department");
    }
    setchangedepartmentModal(false);
  };
  //Edit the teachersData
  const handleEditTeacher = async (teacher) => {
    try {
      await api.put(`/v1/updateteacher/${teacher.id}`, {
        name: newTeacher.name,
        designation: newTeacher.designation,
        specialization: newTeacher.specialization,
        email: newTeacher.email,
        phone: newTeacher.phone,
      });
      fetchData();
      toast.success("Teacher updated successfully");
    } catch (err) {
      toast.error("Error updated department");
    }
    setchangeteacherModal(false);
  };
  //delete the department
  const handleDeletedepartment = async (id) => {
    try {
      await api.delete(`/v1/deletedepartment/${id}`);
      fetchData();
      toast.success("Department deleted successfully");
    } catch (err) {
      toast.error("Error deleted department");
    }
    setdepartmentloading(false);
  };
  //delete the teacher
  const handleDeleteTeacher = async (id) => {
    try {
      await api.delete(`/v1/deleteteacher/${id}`);
      fetchData();
      toast.success("Teacher deleted successfully");
    } catch (err) {
      toast.error("Error deleted teacher");
    }
    setteacherloading(false);
  };
  //Teacher Invite button functionality
  const Invitebtn = (teacher) => {
    setOpenCreateTeachermodal(true);
    console.log(teacher)
    setcurrentTeacher(teacher);
  };
  const ScrollerStyle = useMemo(
    () => ({
      width: "100%",
      overflowY: "scroll",
    }),
    []
  );
  if (loading) {
    return (
      <Box sx={{ position: "absolute", top: "50%", left: "55%" }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <div className="scroll-bar-hidden p-3" style={ScrollerStyle} key={departments.id}>
        <div className="" style={{height:"70vh"}}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="mb-3">
              <h3 className="font-weight-bold mt-3">Departments</h3>
            </div>
            <div>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={() => {
                  setOpenmodal(true);
                  setDepartmentName("");
                }}
                sx={{ backgroundColor: "rgba(25,103,210,.1)" }}
              >
                New Department
              </Button>
            </div>
          </div>
          {departments.map((department, index) => (
            <div className="p-1 mb-2">
              <TableContainer key={index} component={Paper}>
                <div className="d-flex align-items-center p-2 justify-content-between">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: "0.5rem",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "10px",
                      boxShadow:
                        "-2px -2px 10px #bebebe,10px 10px 10px #ffffff",
                    }}
                  >
                    <Typography
                      className="font-weight-bold text-capitalize"
                      sx={{
                        backgroundColor:
                          "linear-gradient(135deg, #ff7e5f, #feb47b)",
                        borderRadius: "8px",
                        color: "#262626",
                        letterSpacing: "0.05rem",
                        flex: 1,
                        fontSize: "0.9rem",
                      }}
                    >
                      {department.name} Department
                    </Typography>
                    <ModeEditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDepartmentBox(department);
                      }}
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "#fff",
                        padding: "0.5rem",
                        borderRadius: "50px",
                        fontSize: "2rem",
                        cursor: "pointer",
                        transition: "all 0.5s ease",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          transform: "scale(1.1)",
                          color: "#262626",
                        },
                      }}
                    />
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setdepartmentloading(true);
                        handleDeleteDepartmentBox(department.id);
                      }}
                      sx={{
                        backgroundColor: "#FC3F3E",
                        color: "#fff",
                        padding: "0.5rem",
                        borderRadius: "50px",
                        fontSize: "2rem",
                        cursor: "pointer",
                        transition: "all 0.5s ease",
                        "&:hover": {
                          backgroundColor: "#FA6867",
                          transform: "scale(1.1)",
                          color: "#262626",
                        },
                      }}
                    />
                  </div>

                  <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<Add />}
                    onClick={() => handleDepartmentBox(department)}
                    sx={{ backgroundColor: "rgba(25,103,210,.1)" }}
                  >
                    New Teacher
                  </Button>
                </div>
                <Table
                  className="rounded-3 p-2"
                  sx={{
                    minWidth: 650,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "50px",
                    overflow: "hidden",
                  }}
                  aria-label="impressive table"
                >
                  <TableHead
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: 2,
                        backgroundColor: "#1b98d4",
                        color: "#fff",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <h6
                          className="font-weight-bold"
                          style={{
                            color: "#fff",
                            letterSpacing: "0.04rem",
                          }}
                        >
                          Teachers Name
                        </h6>
                      </TableCell>
                      <TableCell>
                        <h6
                          className="font-weight-bold"
                          style={{
                            color: "#fff",
                            letterSpacing: "0.04rem",
                          }}
                        >
                          Designation
                        </h6>
                      </TableCell>
                      <TableCell align="center">
                        <h6
                          className="font-weight-bold"
                          style={{
                            color: "#fff",
                            letterSpacing: "0.04rem",
                          }}
                        >
                          Specialization
                        </h6>
                      </TableCell>
                      <TableCell>
                        <h6
                          className="font-weight-bold"
                          style={{
                            color: "#fff",
                            letterSpacing: "0.04rem",
                          }}
                        >
                          Action
                        </h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <Box width="75vw" padding={2} display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {teachersData[department.id] &&
                        teachersData[department.id].map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:nth-of-type(odd)": {
                                backgroundColor: "rgba(245, 245, 245, 0.9)",
                              },
                              "&:nth-of-type(even)": {
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                              },
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                              },
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                letterSpacing: "0.04rem",
                              }}
                            >
                              {item?.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: "capitalize",
                                letterSpacing: "0.04rem",
                              }}
                            >
                              {item?.designation}
                            </TableCell>
                            <TableCell>
                              <Chip
                                variant="outlined"
                                color="info"
                                className="text-dark font-weight-bold"
                                sx={{
                                  borderColor: "#1976D2",
                                  color: "#1976D2",
                                }}
                              >
                                {item.specialization}
                              </Chip>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "start",
                                  flexWrap: "wrap",
                                }}
                              >
                                {item?.status ===
                                "active" ? null : item?.status === "pending" ? (
                                  <Button color="info" variant="outlined">
                                    Pending
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    variant="outlined"
                                    onClick={() => Invitebtn(item)}
                                  >
                                    Invite
                                  </Button>
                                )}
                                <ModeEditIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeTeacherBox(item);
                                  }}
                                  sx={{
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                    color: "#fff",
                                    padding: "8px",
                                    borderRadius: "50px",
                                    marginLeft: "0.4rem",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0,0,0,0.3)",
                                      transform: "scale(1.03)",
                                      color: "#262626",
                                    },
                                    transition: "all 0.5s ease",
                                  }}
                                />
                                <DeleteIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setteacherloading(true);
                                    handleDeleteTeacherBox(item.id);
                                  }}
                                  sx={{
                                    backgroundColor: "#FC3F3E",
                                    color: "#fff",
                                    padding: "8px",
                                    borderRadius: "50px",
                                    marginLeft: "0.4rem",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "#FA6867",
                                      transform: "scale(1.03)",
                                      color: "#262626",
                                    },
                                    transition: "all 0.5s ease",
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          ))}
        </div>
      </div>
      {/* create new department modal box */}
      <CreateDepartmentModal
        openmodal={openmodal}
        setOpenmodal={setOpenmodal}
        handleSubmit={handleSubmit}
        departmentName={departmentName}
        handleDepartmentNameChange={handleDepartmentNameChange}
      />
      {/* model of changing departmentName */}
      <ChangeDepartmentModal
        changedepartmentModal={changedepartmentModal}
        setchangedepartmentModal={setchangedepartmentModal}
        currentDepartment={currentDepartment}
        handleDepartmentNameChange={handleDepartmentNameChange}
        handleEditdepartment={handleEditdepartment}
        departmentName={departmentName}
      />
      {/* create new Teacher modal box */}
      <CreateTeacherModal
        open={open}
        setOpen={setOpen}
        newTeacher={newTeacher}
        handleSubmit2={handleSubmit2}
        currentDepartment={currentDepartment}
        handleInputChange={handleInputChange}
        errors={errors}
      />
      {/* This is delete Teacher modal */}
      <DeleteTeacherModal
        teacherloading={teacherloading}
        setteacherloading={setteacherloading}
        handleDeleteTeacher={handleDeleteTeacher}
        currentTeacherId={currentTeacherId}
      />
      {/*This is change teacher information modal */}
      <ChangeTeacherModal
        changeteacherModal={changeteacherModal}
        setchangeteacherModal={setchangeteacherModal}
        handleInputChange={handleInputChange}
        newTeacher={newTeacher}
        handleEditTeacher={handleEditTeacher}
        currentDepartment={currentDepartment}
        errors={errors}
      />
      {/* This is delete department modal */}
      <DeleteDepartmentModal
        departmentloading={departmentloading}
        setdepartmentloading={setdepartmentloading}
        handleDeletedepartment={handleDeletedepartment}
        currentDepartmentId={currentDepartmentId}
      />
      {/* create new profile as teacher  */}
      <Createteacher
        teacher={currentTeacher}
        openCreateTeachermodal={openCreateTeachermodal}
        setOpenCreateTeachermodal={setOpenCreateTeachermodal}
        fetchData={fetchData}
      />
    </>
  );
});

export default Departments;
