import React from 'react';
import { CheckCircleTwoTone, DeleteTwoTone } from '@mui/icons-material';
import { Avatar, Button, IconButton } from '@mui/joy';
import { Rating } from '@mui/material';
import { useGlobalContext } from 'global/context';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ConnectionRequestItem = ({ user, removeRequest, requestType }) => {
    const { api } = useGlobalContext();

    const handleAction = async (action) => {
        console.log("Clicked");
        try {
            let proceed = true;

            if (action === 'reject') {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, cancel!'
                });

                proceed = result.isConfirmed;
            }

            if (proceed) {
                const endpoint = requestType === 'received' && `/app/connections/action/${user?.connectionId}`;
                const res = await api.put(endpoint, { action });

                if (res?.status === 200) {
                    console.log("Request Action: ", res?.data);
                    toast.success(res?.data?.message);
                    removeRequest(user?.id, requestType);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.error);
        }
    };

    return (
        <div className="rounded-4 shadow p-4 hover-bg" style={{ height: '100%' }}>
            <div className="d-flex justify-content-center flex-wrap mb-3">
                <Avatar
                    alt={user?.first_name}
                    src={user?.avatar_url || 'https://scontent.fdel27-1.fna.fbcdn.net/v/t39.30808-6/371930944_611998674434984_8150669947556767484_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=49d041&_nc_ohc=SmhOUZd6RPwAX-FEJEw&_nc_ht=scontent.fdel27-1.fna&oh=00_AfDhjGC1QW0oq22Vs7L3ZRZeTRQMaUsu_pePAXz7ThUwow&oe=64F8D9F4'}
                    size="lg"
                    sx={{ height: 100, width: 100 }}
                />
            </div>
            <Link to={`/${user?.username}`} className="d-flex flex-column align-items-center mb-3">
                <h4 className='text-center'>{user?.first_name}</h4>
                <h4 className='text-center mb-2'>{user?.last_name}</h4>
                <Rating
                    name="text-feedback"
                    value={user?.rating || 0}
                    readOnly
                    precision={0.5}
                />
            </Link>
            <div className="d-flex justify-content-between">
                <div className="right-controls mr-4">
                    {requestType === 'received' && (
                        <IconButton
                            variant='soft'
                            color='danger'
                            className='text-capitalize'
                            onClick={() => handleAction('reject')}
                        >
                            <DeleteTwoTone />
                        </IconButton>
                    )}
                </div>
                <div className="left-controls">
                    {requestType === 'received' && (
                        <Button
                            variant='soft'
                            color='success'
                            className='text-capitalize'
                            onClick={() => handleAction('accept')}
                            disabled={user?.status === 'pending' ? false : true}
                        >
                            <CheckCircleTwoTone sx={{ fontSize: "16px" }} />
                            &nbsp;{user?.status === 'pending' ? 'Accept' : 'Accepted'}
                        </Button>
                    )}
                    {requestType === 'sent' && (
                        <span className='text-capitalize text-secondary'>
                            {user?.status === 'pending' ? 'Pending' : 'Accepted'}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConnectionRequestItem;



// import React from 'react'
// import { CheckCircleOutlineTwoTone, CheckCircleTwoTone, DeleteOutlineTwoTone, DeleteRounded, DeleteTwoTone, Image, StarRounded } from '@mui/icons-material'
// import { Avatar, Button, IconButton } from '@mui/joy'
// import { ListItemAvatar, ListItemText, Rating } from '@mui/material'
// import { Popup } from 'layout/Popup'
// import { useGlobalContext } from 'global/context'
// import { toast } from 'react-hot-toast'
// import { Link } from 'react-router-dom'
// import Swal from 'sweetalert2';

// const ConnectionRequestItem = ({ user, removeRequest, requestType }) => {
//     const { api } = useGlobalContext();

//     const handleAction = async (action) => {

//         console.log("Clicked")
//         try {
//             let proceed = true;

//             if (action === 'reject') {
//                 const result = await Swal.fire({
//                     title: 'Are you sure?',
//                     text: "You won't be able to revert this!",
//                     icon: 'warning',
//                     showCancelButton: true,
//                     confirmButtonColor: '#3085d6',
//                     cancelButtonColor: '#d33',
//                     confirmButtonText: 'Yes, delete it!',
//                     cancelButtonText: 'No, cancel!'
//                 });

//                 proceed = result.isConfirmed;
//             }
//             if (proceed) {
//                 const endpoint = requestType === 'received' && `/app/connections/action/${user?.connectionId}`;
//                 const res = await api.put(endpoint, { action });

//                 if (res?.status === 200) {
//                     console.log("Request Action: ", res?.data);
//                     toast.success(res?.data?.message);
//                 }
//                 removeRequest(user?.id, requestType);
//             }
//         } catch (error) {
//             console.error(error);
//             toast.error(error?.response?.data?.error);
//         }
//         removeRequest(user?.id, requestType);
//     };

//     return (
//         // <div className='shadow px-4 py-2 rounded'>
//         //     <div className='d-flex flex-column rounded p-0 py-2 align-items-start justify-content-center'>
//         //         <Avatar className='mb-3' src={user?.avatar_url} sx={{ height: 60, width: 60 }} />
//         //         <div className='lh-1 mb-2 d-flex flex-column align-items-between'>
//         //             <p className='text-dark text-center fw-bold mb-3'>{user?.first_name + " " + user?.last_name}</p>
//         //             {/* <small className='fw-semibold truncate'>{user?.bio}</small> */}
//         //             <div className='d-flex justify-content-between'>
//         //                 <Button variant='soft' size='sm' color='success' className='text-capitalize' onClick={() => handleAction('accept')}
//         //                     disabled={user?.status === 'pending' ? false : true}
//         //                 >
//         //                     <CheckCircleOutlineTwoTone sx={{ fontSize: "16px" }} />&nbsp;{user?.status === 'pending' ? 'Accept' : 'Accepted'}
//         //                 </Button>
//         //                 <Button variant='soft' size="sm" color='danger' className='text-capitalize' onClick={() => handleAction('accept')}>
//         //                     <DeleteOutlineTwoTone sx={{ fontSize: "16px" }} />&nbsp;Reject
//         //                 </Button>
//         //             </div>
//         //         </div>

//         //     </div>
//         // </div>
//         <>
//          <div className="rounded-4 shadow p-4 hover-bg" style={{ height: '100%' }}>
//             <div className="d-flex justify-content-center flex-wrap mb-3">
//                 <Avatar
//                     alt={user?.first_name}
//                     src={user?.avatar_url || 'https://scontent.fdel27-1.fna.fbcdn.net/v/t39.30808-6/371930944_611998674434984_8150669947556767484_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=49d041&_nc_ohc=SmhOUZd6RPwAX-FEJEw&_nc_ht=scontent.fdel27-1.fna&oh=00_AfDhjGC1QW0oq22Vs7L3ZRZeTRQMaUsu_pePAXz7ThUwow&oe=64F8D9F4'}
//                     size="lg"
//                     sx={{ height: 100, width: 100 }}
//                 />
//             </div>
//             <Link to={`/${user?.username}`} className="d-flex flex-column align-items-center mb-3">
//                 <h4 className='text-center'>{user?.first_name}</h4>
//                 <h4 className='text-center mb-2'>{user?.last_name}</h4>
//                 <Rating
//                     name="text-feedback"
//                     value={user?.rating || 0}
//                     readOnly
//                     precision={0.5}
//                 />
//             </Link>
//             <div className="d-flex justify-content-between">
//                 <div className="right-controls mr-4">
//                     {requestType === 'received' && (
//                         <IconButton variant='soft' color='danger' className='text-capitalize' onClick={() => handleAction('reject')}>
//                             <DeleteTwoTone />
//                         </IconButton>
//                     )}
//                 </div>
//                 <div className="left-controls">
//                     {requestType === 'received' && (
//                         <Button
//                             variant='soft'
//                             color='success'
//                             className='text-capitalize'
//                             onClick={() => handleAction('accept')}
//                             disabled={user?.status === 'pending' ? false : true}
//                         >
//                             <CheckCircleTwoTone sx={{ fontSize: "16px" }} />
//                             &nbsp;{user?.status === 'pending' ? 'Accept' : 'Accepted'}
//                         </Button>
//                     )}Add
//                     {requestType === 'sent' && (
//                         <span className='text-capitalize text-secondary'>
//                             {user?.status === 'pending' ? 'Pending' : 'Accepted'}
//                         </span>
//                     )}
//                 </div>
//             </div>
//         </div>
//         </>
//     )
// }

// export default ConnectionRequestItem;