import React, { useState, useEffect } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { NavLink } from "react-router-dom";
import { useGlobalContext } from "global/context";

function MobileFooter() {
    const [value, setValue] = useState('home');
    const { userData } = useGlobalContext();

    useEffect(() => {
        if (userData?.id !== undefined) {
            // Your logic here
        }
    }, [userData?.id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const containerStyle = {
        marginTop: "50px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "white",
    };

    return (
        <div style={containerStyle}>
            <BottomNavigation
                style={{ width: "100%" }}
                value={value}
                onChange={handleChange}
                showLabels
            >
                <BottomNavigationAction
                    label="Home"
                    value="home"
                    icon={<HomeIcon />}
                    component={NavLink}
                    to="/"
                    exact
                />
                <BottomNavigationAction
                    label="Job"
                    value="job"
                    icon={<WorkIcon />}
                    component={NavLink}
                    to="/careers"
                />
                <BottomNavigationAction
                    label="Institute"
                    value="institute"
                    icon={<SchoolIcon />}
                    component={NavLink}
                    to="/institutes"
                />
                <BottomNavigationAction
                    label="Profile"
                    value="profile"
                    icon={<AccountCircleIcon />}
                    component={NavLink}
                    to="/profile"
                />
            </BottomNavigation>
        </div>
    );
}

export default MobileFooter;
