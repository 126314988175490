import React, { useState } from "react";
import { useGlobalContext } from "global/context";
import { TextField, Button, Typography, Box, } from "@mui/material";
import Modal from "@mui/joy/Modal";
import { styled } from "@mui/system";
import toast from "react-hot-toast";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});

const FormContainer = styled(Box)({
  width: "100%",
  maxWidth: "400px",
  marginTop: "20px",
});

const StyledButton = styled(Button)({
  marginTop: "20px",
});

const Createteacher = ({
  teacher,
  openCreateTeachermodal,
  setOpenCreateTeachermodal,
  fetchData,
}) => {
  const { api } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("/v1/createTeacherProfile", {
        name: teacher?.name,
        email: teacher?.email,
        phone: teacher?.phone,
      });
      fetchData();
      toast.success(response.data.message);
      setOpenCreateTeachermodal(false)
    } catch (error) {
      console.error("Error creating profile:", error);
      toast.error("Failed to create profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openCreateTeachermodal}
      onClose={() => {
        setOpenCreateTeachermodal(false);
      }}
    >
      <CenteredBox>
        <Typography variant="h4" gutterBottom>
          Create Teacher Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <TextField
              label="Name"
              variant="outlined"
              value={teacher?.name}
              required
              fullWidth
              margin="normal"
            />
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Profile"}
            </StyledButton>
            <StyledButton
              variant="contained"
              color="error"
              sx={{ marginLeft: 2 }}
              onClick={() => setOpenCreateTeachermodal(false)}
            >
              Cancel
            </StyledButton>
          </FormContainer>
        </form>
      </CenteredBox>
    </Modal>
  );
};

export default Createteacher;
