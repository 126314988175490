import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  
} from "@mui/material";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { useGlobalContext } from "global/context";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";
import Debounce from "pages/utilities/Debouncing";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const AdminUsers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [roleFilters, setRoleFilters] = useState({
    students: true,
    teachers: true,
    professionals: true,
  });
  const { api } = useGlobalContext();
  const [page, setPage] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [selected, setSelected] = useState([]);

  const columns = useMemo(() => {
    if (users && users.length > 0) {
      return [
        ...Object.keys(users[0]).map((header) => ({
          name: header,
          selector: (row) => row[header],
          sortable: true, // Enable sorting
          wrap: true, // Enable text wrapping
          style: {
            maxWidth: "180px",
            whiteSpace: "normal",
            wordWrap: "break-word",
            fontSize: "12px",
            padding: "8px",
          },
          cell: (row) => {
            if (isEditing && editingRow && editingRow.id === row.id) {
              return (
                <input
                  type="text"
                  value={editingRow[header]}
                  onChange={(e) =>
                    setEditingRow({ ...editingRow, [header]: e.target.value })
                  }
                  style={{
                    padding: "4px",
                    border: "1ps solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                />
              );
            } else if (header === "contact_info") {
              const email = row[header];
              const isValidEmail = validateEmail(email);
              return (
                <span
                  style={{
                    color: !isValidEmail ? "red" : "",
                    padding: "2px",
                    padding: "2px",
                    borderRadius: "4px",
                    ...(isValidEmail ? {} : { cursor: "pointer" }), // or 'pointer' if you want a pointing hand
                  }}
                  onMouseOver={() => {
                    if (!isValidEmail) {
                      console.log("Hover");
                      toast.error("Invalid Email.");
                    }
                  }}
                >
                  {email}
                </span>
              );
            }
            return row[header];
          },
        })),
        {
          name: "Actions",
          cell: (row) => (
            <div style={{ display: "flex", gap: "3px" }}>
              {isEditing && editingRow && editingRow.id === row.id ? (
                <>
                  <CheckIcon
                    fontSize="small"
                    color="primary"
                    onClick={SaveUpdatedRow}
                  />
                  <CloseIcon
                    fontSize="small"
                    color="secondary"
                    onClick={CancelEdit}
                  />
                </>
              ) : (
                <>
                  <CreateIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => EditRow(row)}
                  />
                  <DeleteForeverIcon
                    fontSize="small"
                    color="secondary"
                    onClick={() => DeleteRow(row)}
                  />
                </>
              )}
            </div>
          ),
          sortable: false,
          width: "150px",
          style: {
            textAlign: "center",
          },
        },
      ];
    }
    return [];
  }, [users, isEditing, editingRow]);

  const handleFilter = (event) => {
    const filterText = event.target.value.toLowerCase();
    setSearchTerm(filterText);
    const newData = users.filter((row) => {
      return Object.values(row).some((value) => {
        return value && value.toString().toLowerCase().includes(filterText);
      });
    });
    setFilteredUsers(newData);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const SaveUpdatedRow = () => {
    setIsEditing(false);
    // Make API call to update the row
    api
      .put(`admin/users/update/${editingRow.id}`, editingRow)
      .then((response) => {
        console.log("Row updated successfully!");
        fetchData(); // Refresh the teachers data
        setEditingRow(null); // Clear editing state
      })
      .catch((error) => {
        console.log("Error updating row:", error);
      });
  };

  const DeleteRow = async (row) => {
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with API call to delete row
        api
          .delete(`admin/users/delete/${row.id}`)
          .then((response) => {
            console.log("Row deleted successfully!");
            Swal.fire({
              title: "Deleted!",
              width: 400,
              text: "Your file has been deleted.",
              icon: "success",
            });
            fetchData(); // Refresh the teachers data
          })
          .catch((error) => {
            console.log("Error deleting row:", error);
            Swal.fire({
              title: "Error!",
              width: 400,
              text: "There was an error deleting the row.",
              icon: "error",
            });
          });
      }
    });
  };

  const EditRow = (row) => {
    setIsEditing(true);
    setEditingRow({ ...row });
  };

  const CancelEdit = () => {
    setIsEditing(false);
    setEditingRow(null);
  };

  const fetchData = async () => {
    try {
      const res = await api.get("admin/users");
      if (res.status === 200 && res.data) {
        setUsers(res.data);
        // setTotalUsers(res?.data?.userCount);
        toast.success("Get Teachers Data SuccessFully");
      }
    } catch (error) {
      if (error.response.status === 409) {
        Popup("error", "Access denied");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  };

  // Fetch user data
  useEffect(() => {
    fetchData();
  }, [api]);

  const filteredUsersByRole = users.filter((user) => {
    const roleVisible =
      (roleFilters.students && user.role === "student") ||
      (roleFilters.teachers && user.role === "teacher") ||
      (roleFilters.professionals && user.role === "professional");
    // ... other filter conditions ...
    return roleVisible;
  });

  // console.log("Users : ",users)
  const handleRoleFilterChange = (event) => {
    const role = event.target.name;
    const checked = event.target.checked;
    setRoleFilters((prevFilters) => ({ ...prevFilters, [role]: checked }));
  };

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: 1.5 }}>
      <Row>
        <Col className="d-flex flex-wrap align-items-center">
          <Box flexGrow={1}>
            <input
              type="text"
              onChange={handleFilter}
              placeholder="Search..."
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                width: "170px",
              }}
            />
          </Box>
          <Box flexGrow={1} className="d-flex justify-content-end">
            <FormControl
              component="fieldset"
              sx={{ boxShadow: 2, p: 0.5, pl: 2.6 }}
            >
              <FormGroup row>
                {["students", "teachers", "professionals"].map((role) => (
                  <FormControlLabel
                    key={role}
                    control={
                      <Checkbox
                        checked={roleFilters[role]}
                        onChange={handleRoleFilterChange}
                        name={role}
                      />
                    }
                    label={role.charAt(0).toUpperCase() + role.slice(1)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            columns={columns}
            data={searchTerm ? filteredUsers : filteredUsersByRole}
            fixedHeader
            pagination
            responsive
            dense={true}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#f0f0f0",
                  borderBottom: "0.5px solid #ddd",
                  padding: "2px", // Reduce padding by 5%
                },
              },
              headCells: {
                style: {
                  padding: "1px", // Reduce padding by 5%
                  fontWeight: "bold",
                  fontSize: "9px",
                },
              },
              rows: {
                style: {
                  borderBottom: "0.5px solid #ddd",
                  fontSize: "9px",
                  padding: "1px", // Reduce padding by 5%
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                },
              },
            }}
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              overflow: "hidden",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Col>
      </Row>
    </Box>
  );
};

export default AdminUsers;
