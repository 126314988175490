import { Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import GetStarted from "./GetStarted";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useGlobalContext } from '../../../../global/context'
import SmallJobCard from "./SmallJobCard";
import { display } from "@mui/system";
import GetStartedMobile from "./GetStartedMobile";

const Hero = () => {
  const navigate = useNavigate();
  const { api } = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const [searchType, setSearchType] = useState('question');
  const [placeHolder, setPlaceHolder] = useState(' Search by Question ....')
  const [keyword, setKeyword] = useState('');
  const [colorText, setColorText] = useState('Teachers & Students');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)

    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])


  const getJobs = async () => {
    try {
      const res = await api.get(`/app/jobs/active`);
      if (res?.status === 200) {
        // console.log("Job was successfully Fetchech: ", res?.data?.results);
        setJobs(res?.data?.results);
      }
    } catch (err) {
      console.log("Error getting jobs", err);
    }
  }




  useEffect(() => {
    getJobs();
  }, []);

  const handleSearch = () => {
    if (keyword && searchType === 'question') {
      console.log("Send Keyword from Home Page : ",keyword)
      navigate(`/questions`, { state: { keyword : keyword } });
    } else if (keyword && searchType === 'user') {
      console.log("Send Keyword from Home Page : ",keyword)
      navigate(`/users`, { state: { keyword : keyword } });
    }
  }
  const handleSearchTypeChange = (event) => {
    // const selectedType = event.target.value;
    // searchType(selectedType)
    if (searchType === "question") {
      setPlaceHolder(" Search By Question .....")
    }
    else {
      setPlaceHolder(" Search By User .....")
    }
  }

  useEffect(() => {
    handleSearchTypeChange()
  }, [searchType])
  useEffect(() => {
    setPlaceHolder(" Search By Question .....")
  }, [])


  const colors = [
    '#FFF1F1', '#FFFEC5', '#D0FB91', '#CAFEDC', '#E6F9F3',
    '#DEEAFC', '#E3E5FF', '#F6D4FE', '#FFEDF7', '#F0F0F0', '#E6FFDF'
  ];

  const generateRandomLightColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <section className="hero-area overflow-hidden bg-light py-2"
    // style={{
    //   backgroundImage: "url(https://img.freepik.com/free-vector/science-doodle-pattern_23-2147492328.jpg?1&w=826&t=st=1686820031~exp=1686820631~hmac=fc9b7676fa577ce7c94ea2f54201e23e80aaf833aaed72dfa0f6c55c423e7e05)",
    //   opacity: '0.4'
    // }}
    >
      <span className="icon-shape icon-shape-1 is-scale" />
      <span className="icon-shape icon-shape-2 is-bounce" />
      <span className="icon-shape icon-shape-3 is-swing" />
      <span className="icon-shape icon-shape-4 is-spin" />
      <span className="icon-shape icon-shape-5 is-spin" />
      <span className="icon-shape icon-shape-6 is-bounce" />
      <span className="icon-shape icon-shape-7 is-tilt" />
      <div className="container">
        {jobs && isMobile && jobs.length > 0 ? (<></>) : (
          <div style={{ backgroundColor: "#FFECEC", borderRadius: "5px", height: "110px" }}>
            <h5 style={{ textAlign: "left", marginLeft: "5px" }}>Current Vacancies</h5>
            <div>
              <marquee
                onMouseEnter={() => {
                  const marquee = document.querySelector('marquee');
                  marquee.stop();
                }}
                onMouseLeave={() => {
                  const marquee = document.querySelector('marquee');
                  marquee.start();
                }}
                behavior="scroll" direction="right" scrollamount="5" style={{ whiteSpace: 'nowrap' }}>
                {jobs.map((job) => (
                  <div
                    key={job.id}
                    style={{
                      display: 'inline-block',
                      backgroundColor: generateRandomLightColor(),
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                      marginLeft: '15px',
                      padding: '9px',
                      width: '130px',
                      height: '80px',
                    }}
                  >
                    <SmallJobCard jd={job} />

                  </div>
                ))}
              </marquee>
            </div>
          </div>
        )}
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="hero-content text-center py-lg-1">
              <h2
                className="section-title pb-0 text-black"
                style={{
                  fontSize: "50px",
                  lineHeight: "65px",
                  '@media(maxWidth: 767px)': {
                    fontSize: "30px",
                    lineHeight: "45px",
                  }
                }}
              >
                Join World's First Job Portal for
              </h2>
              <h2
                className="section-title pb-3 text-black linear-wipe"
                style={{
                  fontSize: "50px",
                  lineHeight: "65px",
                  '@media(maxWidth: 767px)': {
                    fontSize: "30px",
                    lineHeight: "45px",
                  }
                }}
              >
                {colorText}!
              </h2>

              <h5 className="lh-26 text-secondary mx-auto font-weight-bold text-dark" style={{ maxWidth: 600 }}>
                Biggest Community for Educators to help find answers to their queries and show case their skills
              </h5>

              <div className="mt-3 d-flex justify-content-center mt-5">
                {isMobile ? (<>
                  <div className="input-group bg-white p-2 rounded-pill shadow" style={{ maxWidth: 700 }}>
                    <input type="text" className="form-control  shadow-0 py-4"
                      placeholder={placeHolder}
                      onChange={(e) => setKeyword(e.target.value)}
                      style={{ border: 'none', boxShadow: 'none', outline: 'none' }}
                    />
                    <button
                      className="text-capitalize btn btn-success rounded-pill px-3"
                      onClick={handleSearch}
                    >
                      <Search style={{ fontSize: 18 }} /> Search
                    </button>
                  </div>

                </>) : (<>
                  <div className="input-group bg-white p-2 rounded-pill shadow" style={{ maxWidth: 700 }}>
                    <input type="text" className="form-control border-0 shadow-0 py-4"
                      placeholder={placeHolder}
                      onChange={(e) => setKeyword(e.target.value)}
                      style={{ border: 'none', boxShadow: 'none', outline: 'none' }}
                    />
                    <select name="" id="" className="form-control mb-0 h-100 mr-3 border-top-0 border-bottom-0"
                      style={{ maxWidth: "120px" }}
                      onChange={(e) => setSearchType(e.target.value)}
                    // onChange={handleSearchTypeChange}
                    >
                      <option value="question">Question</option>
                      <option value="user">User</option>
                    </select>
                    <button
                      className="text-capitalize btn btn-success rounded-pill px-3"
                      onClick={handleSearch}
                    >
                      <Search style={{ fontSize: 18 }} /> Search
                    </button>
                  </div>
                </>)}
              </div>
            </div>
            {/* end hero-content */}
          </div>
          {/* end col-lg-6 */}
        </div>
        {/* end row */}
      </div>

      {isMobile ? (<GetStartedMobile />) :
        <GetStarted />

      }

    </section>
  );
};

export default Hero;
