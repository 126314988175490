import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  Avatar,
  CircularProgress,
  CardActions,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import toast from "react-hot-toast";
import Autocomplete from "@mui/material/Autocomplete";
import { useGlobalContext } from "global/context";
import { Card, CardContent } from "@mui/joy";
import { Col, Row } from "react-bootstrap";
import { AdminContext } from "../Admin";
import { Link } from "react-router-dom";

// Memoized PollResults component
const PollResults = React.memo(({ poll, category }) => {
  const { fontFamily } = useContext(AdminContext);
  return (
    <Box mt={4}>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1b98d4", color: "#ffffff" }}>
              <TableCell>
                <Typography
                  fontFamily={fontFamily}
                  fontWeight="bold"
                  letterSpacing={0.3}
                  sx={{ color: "#ffffff" }}
                >
                  Name of{" "}
                  {category == "institutes"
                    ? "Institutions"
                    : category == "students"
                    ? "Students"
                    : category == "professionals"
                    ? "Professionals"
                    : "Teachers"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  fontFamily={fontFamily}
                  fontWeight="bold"
                  letterSpacing={0.3}
                  sx={{ color: "#ffffff" }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  fontFamily={fontFamily}
                  fontWeight="bold"
                  letterSpacing={0.3}
                  sx={{ color: "#ffffff" }}
                >
                  Location
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  fontFamily={fontFamily}
                  fontWeight="bold"
                  letterSpacing={0.3}
                  sx={{ color: "#ffffff" }}
                >
                  Mobile
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  fontFamily={fontFamily}
                  fontWeight="bold"
                  letterSpacing={0.3}
                  sx={{ color: "#ffffff" }}
                >
                  Rating
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {poll.map((result) => (
              <TableRow key={result.id}>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Link
                      to={
                        category == "institutes"
                          ? `/institutes/institute-profile/${result.institute_id}`
                          : `/${result?.username}`
                      }
                    >
                      <Avatar alt={result?.name} src={result?.logo} />
                      <Typography sx={{ wordBreak: "break-all" }}>
                        {result?.name} {result?.first_name} {result?.last_name}
                      </Typography>
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {result?.email && result?.email}
                    {result?.contact_info && result?.contact_info}
                  </Typography>
                </TableCell>
                <TableCell>
                  {result?.city}-{result?.state || result?.location}
                </TableCell>
                <TableCell>
                  {result?.mobile} {result?.contact}
                </TableCell>
                <TableCell>
                  {result?.rating
                    ? result?.rating?.toFixed(2)
                    : "Rating not provided"}{" "}
                  {result?.rating && "out of 5"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

const PollLists = () => {
  const { fontFamily } = useContext(AdminContext);
  const { api } = useGlobalContext();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("teachers");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [polls, setPolls] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentPollId, setCurrentPollId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePollId, setDeletePollId] = useState(null);
  const [nearbyPolls, setNearbyPolls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    fetchPolls();
  }, []);

  const fetchPolls = useCallback(async () => {
    try {
      const response = await api.get("/admin/polls");
      setPolls(response.data.polls);
      if (response.data.polls.length > 0) {
        toast.error("no polls found");
      }
      toast.success("Polls fetched");
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }, [api]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!title || !category || !location || !startDate || !endDate) {
      toast.error("All fields are required");
      setLoading(false);
      return;
    }
    if (endDate <= startDate) {
      toast.error("End date should be greater than start date");
      setLoading(false);
      return;
    }
    try {
      if (editMode) {
        await api.put(`/admin/polls/${currentPollId}`, {
          title,
          category,
          location: location.value,
          startDate,
          endDate,
        });
        fetchPolls();
      } else {
        await api.post("/admin/polls", {
          title,
          category,
          location: location?.value,
          startDate,
          endDate,
        });
        fetchPolls();
      }
      fetchPolls();
      setTitle("");
      setCategory("teachers");
      setLocation("");
      setStartDate("");
      setEndDate("");
      setEditMode(false);
      setCurrentPollId(null);
      handleClose();
      toast.success(editMode ? "Poll updated" : "Poll created");
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (poll) => {
    setTitle(poll.title);
    setCategory(poll.category);
    setLocation(poll.location);
    setStartDate(poll.startDate.slice(0, 10));
    setEndDate(poll.endDate.slice(0, 10));
    setEditMode(true);
    setCurrentPollId(poll.id);
    handleOpen();
  };

  const handleDelete = async (pollId) => {
    try {
      await api.delete(`/admin/polls/${pollId}`);
      fetchPolls();
      setDeleteModal(false);
      toast.success("Poll deleted");
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setTitle("");
    setCategory("teachers");
    setLocation("");
    setStartDate("");
    setEndDate("");
  };

  const handleDeleteOpen = (id) => {
    setDeleteModal(true);
    setDeletePollId(id);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const stateOptions = useMemo(
    () => [
      //select all states
      {
        value: "All States",
        label: "All States",
        disabled: false,
      },
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      { label: "Delhi", value: "Delhi" },
      { label: "New Delhi", value: "New Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "West Bengal", value: "West Bengal" },
    ],
    []
  );

  const locationOptions = useMemo(
    () => [{ label: "Select Location", value: "" }, ...stateOptions],
    [stateOptions]
  );
  const handleNearby = async (poll) => {
    setActiveId(poll?.id);
    console.log(poll)
    try {
      //get polls from state based on category
      const response = await api.get(`/admin/polls/nearby`, {
        params: {
          state: poll.location,
          category: poll.category,
        },
      });
      setCurrentCategory(response.data.category);
      setNearbyPolls(response.data.polls);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };
  return (
    <Container>
      <Paper elevation={3} sx={{ padding: "1rem", width: "100%" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{ color: "#1976d2" }}
          fontFamily={fontFamily}
        >
          Poll Management
        </Typography>

        <Box display="flex" justifyContent="center" mb={3}>
          <Button variant="contained" onClick={handleOpen}>
            Create New Poll
          </Button>
        </Box>

        <Row>
          {polls &&
            polls.map((poll, index) => (
              <Col sm={6} md={4} lg={3} key={poll.id}>
                <Card
                  sx={{
                    cursor: "pointer",
                    color: activeId === poll.id ? "#000" : "#fff",
                    backgroundColor:
                      activeId === poll.id ? "#C1CFDA" : "#1b98d4",
                    boxShadow:
                      activeId === poll.id ? "1px 1px 5px #000000" : "none",
                    marginBottom: "1rem",
                  }}
                >
                  <CardContent onClick={() => handleNearby(poll)}>
                    <Typography
                      variant="h6"
                      fontFamily={fontFamily}
                      fontWeight="bold"
                      letterSpacing={1}
                      sx={{ color: activeId === poll.id ? "#000" : "#fff" }}
                    >
                      {poll.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={fontFamily}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        Category:
                      </span>
                      {poll.category}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={fontFamily}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        Location:
                      </span>
                      {poll.location}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={fontFamily}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        Start Date:
                      </span>{" "}
                      {poll.startDate?.slice(0, 10)}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontFamily={fontFamily}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                        End Date:
                      </span>
                      {poll.endDate?.slice(0, 10)}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "flex-end" }}>
                    <IconButton onClick={() => handleEdit(poll)}>
                      <Edit
                        sx={{ color: activeId === poll.id ? "#000" : "#fff" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteOpen(poll.id)}
                      sx={{
                        backgroundColor:
                          activeId === poll.id ? "#1b98d4" : "#C1CFDA",
                      }}
                    >
                      <Delete sx={{ color: "#F23441" }} />
                    </IconButton>
                  </CardActions>
                </Card>
              </Col>
            ))}
        </Row>

        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle fontFamily={fontFamily}>
            {editMode ? "Edit Poll" : "Create New Poll"}
          </DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
              <TextField
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                required
              />
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value="teachers">Teachers</MenuItem>
                  <MenuItem value="students">Students</MenuItem>
                  <MenuItem value="professionals">Professionals</MenuItem>
                  <MenuItem value="institutes">Institutes</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                options={locationOptions}
                value={location}
                onChange={(event, newValue) => setLocation(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Location" required />
                )}
              />
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} />
              ) : editMode ? (
                "Update Poll"
              ) : (
                "Create Poll"
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteModal} onClose={handleDeleteClose}>
          <DialogTitle>Delete Poll</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this poll?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDelete(deletePollId)}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>

        {nearbyPolls && nearbyPolls.length > 0 ? (
          <PollResults poll={nearbyPolls} category={currentCategory} />
        ) : (
          <Box sx={{ textAlign: "center", marginBlock: "1rem" }}>
            <Typography variant="h5">No data Found</Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default PollLists;
