import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { CircularProgress } from "@mui/joy";
import { Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGlobalContext } from "global/context";

const fontFamily = "'Quicksand', sans-serif";

export const JobApplicationList = ({
  filteredapplicants,
  handleSearch,
  searchTerm,
}) => {
  return (
    <Row>
      <Col>
        {/* input search box */}
        <TextField
          label="Search..."
          variant="outlined"
          placeholder="Search by name and job"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          sx={{ my: 2 }}
        />
        {/* table */}
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b98d4" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Applications
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Applied on
                </TableCell>
                <TableCell sx={{ color: "#fff", fontFamily: fontFamily }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredapplicants &&
                filteredapplicants?.map((job, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {job?.users?.first_name} {job?.users?.last_name}
                    </TableCell>
                    <TableCell>{job?.job_descriptions?.job_title}</TableCell>
                    <TableCell>{job?.createdAt?.slice(0, 10)}</TableCell>
                    <TableCell>{job?.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
};

export const DepartmentList = ({
  departments,
  teachersData,
  setSelectedDepartment,
  selectedDepartment,
}) => {
  const handleClick = (department) => {
    if (selectedDepartment.department?.id === department.id) {
      setSelectedDepartment({
        department: null,
        teachers: null,
      });
      return;
    }
    setSelectedDepartment({
      department: department,
      teachers: teachersData[department.id],
    });
  };

  return (
    <Row>
      <Col>
        <Paper sx={{ padding: 2 }}>
          <List>
            {departments.map((department, index) => (
              <ListItem key={department.id}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong
                      className="text-capitalize"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(department)}
                    >
                      {index + 1}) {department.name} department
                    </strong>
                  </Grid>
                  {teachersData[department.id] &&
                  teachersData[department.id].length > 0 ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        <strong>
                          {teachersData[department.id].length} Teachers
                        </strong>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        No Teachers found in this department
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Col>
    </Row>
  );
};

export const NewApplications = ({ applicant }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredApplicants, setFilteredApplicants] = useState(applicant);
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredApplicants = applicant.filter((applicant) => {
      const applicantTitle =
        applicant.job_descriptions.job_title?.toLowerCase();
      const applicantName =
        applicant.users.first_name?.toLowerCase() +
        " " +
        applicant.users.last_name?.toLowerCase();
      return (
        applicantName.includes(searchValue) ||
        applicantTitle.includes(searchValue)
      );
    });
    setFilteredApplicants(filteredApplicants);
  };
  if (!applicant || applicant?.length === 0) {
    return (
      <>
        <ListItem>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                No New Applications Found
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </>
    );
  }
  return (
    <Box marginY={2}>
      <Typography variant="p" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        New Applications
      </Typography>
      <TextField
        label="Search..."
        variant="outlined"
        placeholder="Search by name..."
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ my: 2 }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#1b98d4" }}>
            <TableRow>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Applications
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold", fontSize: "1rem" }}
              >
                Applied on
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApplicants?.map((applicant, index) => (
              <TableRow key={index}>
                <TableCell>
                  {applicant?.users?.first_name}
                  {applicant?.users?.last_name}
                </TableCell>
                <TableCell>
                  <Typography key={index} variant="body2" color="textSecondary">
                    {applicant?.job_descriptions?.job_title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography key={index} variant="body2" color="textSecondary">
                    {applicant?.createdAt?.slice(0, 10)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const SelectedDepartment = ({ department, teachers }) => {
  return (
    <Box marginY={2}>
      <Typography variant="p" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        {department?.name} department
      </Typography>
      <Paper elevation={3} sx={{ padding: 1 }}>
        <TableContainer>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1b98d4" }}>
                <TableCell sx={{ color: "#fff" }}>Name</TableCell>
                <TableCell sx={{ color: "#fff" }}>Designation</TableCell>
                <TableCell sx={{ color: "#fff" }}>Specialization</TableCell>
              </TableRow>{" "}
            </TableHead>
            <TableBody>
              {teachers &&
                teachers.map((teacher) => (
                  <TableRow>
                    <TableCell>{teacher.name}</TableCell>
                    <TableCell>{teacher.designation}</TableCell>
                    <TableCell>{teacher.specialization}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
