import React, { useState, useRef, useEffect } from "react";
import { Avatar, IconButton, Stack } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import Input from "@mui/joy/Input";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Checkbox, Menu, MenuItem } from "@mui/joy";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useGlobalContext } from "global/context";
import io from "socket.io-client";
import axios from "axios";
import ClientMessages from "./ClientMessages";
import OwnerMessages from "./OwnerMessages";
import "../Css/Messages.css";
import { MoreVert } from "@mui/icons-material";

function ChatModal({
  user,
  room,
  handleMessageCounts,
  getHistory,
  messages,
  setMessages,
}) {
  //    const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [state, setState] = useState(false);
  const [reciever, setReciever] = useState(user);
  const { userData, api, socket, onlineUsers } = useGlobalContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const open = Boolean(anchorEl);
  const chatContainerRef = useRef(null);
  const [sendMessage, setSendMessage] = useState("");
  const [currUser, setCurrUser] = useState(null);
  const [isOnline, setIsOnline] = useState(false);

  const getUser = async (userId) => {
    try {
      const res = await api.get(`/app/user/${userId}`);
      if (res?.status === 200) {
        // console.log("Prev User: ", reciever);
        setReciever(res?.data);
        // console.log("Curr User: ", reciever);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    setSendMessage(event.target.value);
  };

  const handleSendClick = () => {
    const newMessage = {
      id: Date.now(),
      to: reciever?.id,
      sender_id: userData?.id,
      content: sendMessage,
      room: room || userData?.id + reciever?.id,
      timestamp: new Date().toISOString(), // Current timestamp
      isRead: false,
    };
    // console.log("Message Sent: ", newMessage);
    socket?.emit("send-message", newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setSendMessage("");
  };

  const handleDeleteChat = () => {
    //    setMessages([]);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

        },
        (error) => {
          console.log("Error occurred while retrieving location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    const newMessage = {
      id: Date.now(),
      to: reciever?.id,
      sender_id: userData?.id,
      content: sendMessage,
      room: room || userData?.id + reciever?.id,
      timestamp: new Date().toISOString(), // Current timestamp
      isRead: false,
    };
    // console.log("Message Sent: ", newMessage);
    socket?.emit("send-message", newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setSendMessage("");
  };

  const scrollToBottom = () => {
    chatContainerRef.current?.lastElementChild?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setReciever(user);
    setMessages([]);
    getHistory(userData?.id + user?.id);
    setIsOnline(false);
  }, [user]);

  useEffect(() => {
    const isLive = onlineUsers.find((online) => online.userId === user?.id);
    // console.log("Current User Status: ", isLive);
    // console.log("Current User ID: ", isLive?.username);
    setIsOnline(!!isLive);
  }, [onlineUsers, user?.id]);

  useEffect(() => {
    socket?.on("receive-message", (data) => {
      // console.log("Received message: ", data);
      handleMessageCounts(data);
      setCurrUser(data?.sender_id);
      setMessages((prevMessages) => [...prevMessages, data]);
      // console.log("Message : ", messages);
      if (!showMessage) {
        setShowMessage(true);
        setState(false);
      }
    });
    return () => {
      socket?.off("receive-message");
    };
  }, [socket]);

  useEffect(() => {
    socket?.emit("join-room", {
      room,
      userId: userData?.id,
    });
    socket?.on("user-joined", (data) => {
      // console.log("User Joined: ", data);
      getHistory(data.room);
    });
  }, []);

  useEffect(() => {
    if (currUser) {
      getUser(currUser);
      getHistory(userData?.id + reciever?.id);
    }
  }, [currUser]);

  return (
    <div>
      <div className="p-2">
        <div className="p-2 rounded-3">
          <div className=" mb-3">
            <div
              className="d-flex justify-content-between"
              onClick={() => {
                setState((prevState) => !prevState);
                // handleClickMin();
              }}
            >
              <div className="d-flex align-items-center overflow-hidden text-nowrap">
                <Avatar
                  alt={user?.first_name + " " + user?.last_name}
                  src={user?.avatar_url}
                  className="mr-1"
                  sx={{
                    width: "55px",
                    height: "55px",
                  }}
                />
                <div className="p-1">
                  <h5 className="fs-20 ">
                    {user?.first_name + " " + user?.last_name}
                  </h5>
                  <p className="overflow-hidden text-nowrap fs-12 text-success font-weight-bold">
                    {isOnline ? "Online " : "Offline"}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <Stack sx={{ color: "gray", width: "40px" }}>
                  <div>
                    <IconButton
                      id="positioned-demo-button"
                      aria-controls={open ? "positioned-demo-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="outlined"
                      color="neutral"
                      onClick={handleClick}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="positioned-demo-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="positioned-demo-button"
                      placement="bottom-end"
                    >
                      <MenuItem onClick={""}>
                        <Checkbox color="primary" /> Select All
                      </MenuItem>
                      <MenuItem
                        onClick={handleDeleteChat}
                        variant="soft"
                        color="danger"
                      >
                        <DeleteForever /> Delete Chat
                      </MenuItem>
                    </Menu>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
          <div className="  ">
            <div>
              <div
                className="bg-white  rounded-4  p-1 scroll-minibar"
                style={{
                  height: "77vh",
                  overflow: "auto",
                  overflowX: "hidden",
                  backgroundColor: "#e0efff",
                }}
              >
                <h6 className="text-center fs-12 mb-4 p-2">Today, Jun 7</h6>
                <div className="message-container" ref={chatContainerRef}>
                  {messages.map((msg) => {
                    if (msg.sender_id === userData?.id) {
                      return (
                        <OwnerMessages
                          data={msg}
                          avatar={userData?.profile}
                          key={msg.id}
                          open={open}
                          handleClose={handleClose}
                        />
                      );
                    } else {
                      return (
                        <ClientMessages
                          data={msg}
                          avatar={user?.avatar_url}
                          key={msg.id}
                        />
                      );
                    }
                  })}
                </div>
                <div className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          <div className="d-flex">
            <div
              className="input-group rounded-3 "
              style={{ position: "sticky", width: "100%", bottom: 10 }}
            >
              <Input
                fullWidth
                placeholder="Type in here..."
                variant="plain"
                value={sendMessage}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleSendClick();
                }}
                onChange={handleInputChange}
                endDecorator={
                  <div className="d-flex">
                    {/* <IconButton>
                     <label htmlFor="upload-photo">
                       <InsertPhotoIcon sx={{ color: "#007bff", width: "20px", height: "20px", marginTop: "5px" }} />
                     </label>
                     <input
                       id="upload-photo"
                       type="file"
                       accept="image/*"
                       style={{ display: 'none' }}
                       onChange={handlePhotoUpload}
                     />
                   </IconButton>
                   <IconButton onClick={handleLocationClick}>
                     <LocationOnIcon sx={{ color: "#007bff", width: "20px", height: "20px" }} />
                   </IconButton>
                   <IconButton>
                     <MicIcon sx={{ color: "#007bff", width: "20px", height: "20px" }} />
                   </IconButton> */}
                    <IconButton color="primary" onClick={handleSubmit}>
                      <SendIcon
                        sx={{ color: "#007bff", width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatModal;