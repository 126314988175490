import { Button } from '@mui/joy';
import React from 'react';
import { useState } from 'react';
import { useGlobalContext } from 'global/context';
import { useEffect } from 'react';
import ResearchCard from './ResearchCard';
import AddResearch from 'pages/user/profile/components/Modals/AddResearch';
import UserList from 'components/user/List/UserList';

function Research({userId}) {
  const { api, userData } = useGlobalContext();
  const [openAddResearch, setOpenAddResearchModel] = useState(false);
  const [research, setResearch] = useState([]);

  const getResearch = async () => {
    try {
        const res = await api.get(`/app/candidates/research/${userId || userData?.id}`);
        if (res?.status === 200) {
            console.log("research : ", res?.data?.results);
            setResearch(res?.data?.results);
        }
    } catch (e) {
        console.log(e);
        setResearch([]);
    }
}

useEffect(() => {
    getResearch();
}, [userId]);

  return (
    <>
    <div className="job-container">
        <div className="job-header mb-4 d-flex flex-wrap align-items-center justify-content-between">
            <h4>Research</h4>
            {
                userId === userData?.id ?
                <Button variant='soft' onClick={() => setOpenAddResearchModel(true)}>Add Research </Button>
                 :null   
            }
        </div>

                        <div className="job-list">
                            {
                                research?.map(val => (
                                    <ResearchCard 
                                    research={val} 
                                    key={research?.id} 
                                    setOpenAddResearchModel={setOpenAddResearchModel}
                                    getResearch={getResearch}
                                    />

                                ))
                            }
                            
                        </div> 
                
        <AddResearch 
        open={openAddResearch} 
        research={research} 
        getResearch={getResearch} 
        setOpen={setOpenAddResearchModel}  
        action='add' 
        />
        
    </div>
</>
  )
}

export default Research