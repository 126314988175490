import React, { useState } from "react";

import { useGlobalContext } from "global/context";
import { Popup } from "layout/Popup";
import { useEffect } from "react";
import QuestionList from "./QuestionList";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@mui/joy";
import { ArrowRightAltRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import { HelpOutlineTwoTone } from "@mui/icons-material";

const QuestionsTab = ({ profile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(location?.state?.open ? true : false);
  const { userData, api, apiAuth } = useGlobalContext();
  const [questions, setQuestions] = useState([]);
  const [ totalQuestions, setTotalQuestions] = useState([])
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3); // Number of questions per page
  const [sortBy, setSortBy] = useState("newest");

  const getQuestions = async () => {
    try {
      const res = await api.get(
        `/app/question/my?userId=${
          profile?.id ? profile?.id : userData?.id
        }&page=${page}&limit=${limit}`
      );
      if (res?.status === 200) {
        // console.log("User Questions: ", res?.data?.results);
        setQuestions(res?.data?.results);
      }
    } catch (err) {
      console.log("Error: ", err);
      Popup("error", err?.response?.data?.message);
    }
  };

  const getTotalQuestions = async () => {
    try {
        const res = await api.get(`/app/question/total/${userData?.id}`);
        if (res?.status === 200) {
          // console.log("Total Questions : ",res?.data?.questions.length)
            setTotalQuestions(res?.data?.questions);
        }
    } catch (err) {
        console.log("Error: ", err);
        Popup("error", err?.response?.data?.message);
    }
};

  const handleSort = (value) => {
    setSortBy(value);
    getQuestions();
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    getQuestions();
    getTotalQuestions();
  }, [userData, profile, page]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const totalPages = Math.ceil(totalQuestions.length / limit);

  return (
    <div className="user-panel-main-bar">
      {/* Questions */}
      <div className="user-panel mb-40px">
        <div className=" p-3 rounded-rounded d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div>
            <h3 className="fs-20 fw-bold">
                {profile?.id ? `${profile?.first_name}'s Questions` : "Questions"}
                <span> ({(page - 1) * limit + 1}-{Math.min(page * limit, totalQuestions.length)}/{totalQuestions.length})</span>
            </h3>
            </div>
            <div className="ml-5">
              <div className="mb-3">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={()=>navigate('/questions',{state: {open : true}})}
                  className="rounded py-3 fs-5 text-capitalize fw-bold shadow-lg"
                >
                  <HelpOutlineTwoTone />
                  &nbsp;Ask Question
                </Button>
              </div>
            </div>
          </div>
          <div className="filter-option-box flex-grow-1 d-flex align-items-center justify-content-end lh-1">
           <Stack direction="row" spacing={3}>
               <Button
                   variant="outlined"
                   onClick={handlePreviousPage}
                   disabled={page <= 1}
               >
                   <KeyboardArrowLeftRounded />
               </Button>
               <Button
                   variant="outlined"
                   onClick={handleNextPage}
                   disabled={page >= totalPages}
               >
                   <KeyboardArrowRightRounded />
               </Button>
           </Stack>
          </div>
          {/* <div className="filter-option-box flex-grow-1 d-flex align-items-center justify-content-end lh-1">
                        <label className="fs-14 fw-medium mr-2 mb-0">
                            Sort
                        </label>
                        <div className="w-100px">
                            <select className="select-container" defaultValue="Votes">
                                <option value="Votes">
                                    Votes
                                </option>
                                <option value="Activity">Activity</option>
                                <option value="Newest">Newest</option>
                            </select>
                        </div>
                    </div> */}
        </div>
        <div className="summary-panel">
          <div className="vertical-list">
            {questions?.length
              ? questions?.map((question) => (
                  <QuestionList key={question?.id} question={question} />
                ))
              : null}
            {/* end item */}
            {/* <div className="pager pt-30px">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination generic-pagination generic--pagination">
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            href="#"
                                            aria-label="Previous"
                                        >
                                            <span aria-hidden="true">
                                                <i className="la la-arrow-left" />
                                            </span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            1
                                        </a>
                                    </li>
                                    <li className="page-item active">
                                        <a className="page-link" href="#">
                                            2
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            3
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            4
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            href="#"
                                            aria-label="Next"
                                        >
                                            <span aria-hidden="true">
                                                <i className="la la-arrow-right" />
                                            </span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <p className="fs-13 pt-2">
                                Showing 1-5 of (1,979) results
                            </p>
                        </div> */}
          </div>
        </div>
      </div>
      {/* end Questions */}
    </div>
  );
};

export default QuestionsTab;
