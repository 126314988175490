import { Button, Chip } from "@mui/joy";
import { useGlobalContext } from "global/context";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-hot-toast";

const truncateTitle = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

const JobCard = ({ jd }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { userData, api, appliedJobs } = useGlobalContext();
  const user = JSON.parse(localStorage.getItem("user"));
  const [isApplied, setIsApplied] = useState(false);
  const [wishlisted, setWishlisted] = useState([]);
  const [jobStatusFetched, setJobStatusFetched] = useState(false);
  const getJobId = localStorage.getItem("jobId");
  const truncatedTitle = truncateTitle(jd?.jobTitle || jd?.job_title, 20);
  const truncatedDescription = truncateText(
    jd?.jobDescription || jd?.job_description,
    50
  );
  const truncatedSalary = truncateText(jd?.salary || jd?.salary_range, 10);
  //const [addWishList, setAddWishList] = useState(false);
  const getJobStatus = async (id) => {
    const endpoint = `/app/jobs/${userData?.id}/job/${jd?.id}/application-status`;
    try {
      const res = await api.get(endpoint);
      if (res?.status === 200) {
        setIsApplied(res.data.is_applied);
      }
    } catch (error) {
      if (error.response.status === 404) {
        // Job not in wishlist, set wishlisted to false or an empty array
        setIsApplied(false);
      } else {
        console.error("Error fetching wishlist:", error);
      }
    }
  };

  const AddWishlist = async () => {
    try {
      const res = await api.post(`/app/jobs/addwishlist`, {
        user_id: userData?.id,
        job_id: jd?.id,
      });

      if (res?.status === 201 || res?.status === 200) {
        setWishlisted((prevWishlisted) => [...prevWishlisted, jd?.id]);
        toast.success("Wishlisted");
      }
    } catch (err) {
      // Handle errors
      toast.error("Error adding job to wishlist.");
    }
  };


  const RemoveFromWishlist = async () => {
    try {
      const res = await api.put(`/app/jobs/removewishlist/${userData?.id}/${jd?.id}`);
      if (res?.status === 200) {
        setWishlisted((prevWishlisted) => prevWishlisted.filter((id) => id !== jd?.id));
        toast.success("Removed from wishlist");
      }
    } catch (err) {
      // Handle errors
      toast.error("Error removing job from wishlist.");
    }
  };


  const fetchWishlist = async () => {
    const endpoint = `/app/jobs/${userData?.id}/job/wishlist`;

    try {
      const res = await api.get(endpoint);

      if (res.status === 200) {
        setWishlisted(res?.data?.map((item) => item.job_id));
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setWishlisted([]);
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const transformStyle = isHovered ? { transform: "scale(0.1)" } : {};
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (jd && jd.expiry_date) {
      const expiryDate = jd.expiry_date;
      if (expiryDate === "0000-00-00 00:00:00") {
        setFormattedDate("N/A");
      } else {
        try {
          // Attempt to parse the ISO timestamp from jd.expiry_date
          const timestamp = new Date(jd.expiry_date);

          // Check if the parsed timestamp is valid
          if (!isNaN(timestamp)) {
            // Format the date as "YYYY-MM-DD"
            const formattedDate = timestamp.toISOString().split("T")[0];

            // Update the state with the formatted date
            setFormattedDate(formattedDate);
          } else {
            // Handle the case where jd.expiry_date is not a valid date
            console.error("Invalid date format:", jd.expiry_date);
          }
        } catch (error) {
          console.error("Error parsing date:", error);
        }
      }
    }
  }, [jd]);

  useEffect(() => {
    getJobStatus();
    fetchWishlist();
  }, []);

  const handleWishlistToggle = () => {
    if (wishlisted.includes(jd?.id)) {
      RemoveFromWishlist();
    } else {
      if (!userData?.email) {
        navigate("/auth/login")
      } else {
        AddWishlist();
      }
    }
  };

  const handleApplied = (id, slug) => {
    navigate(`/careers/job/${slug}`, {
      state: {
        id: id,
      },
    });
  };

  return (
    <>
      <div
        className="p-4 shadow rounded-4 mb-3 d-flex flex-column justify-content-between hover-y"
        style={{ minHeight: 250 }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4
              className="fw-bold text-info"
              onClick={() =>
                navigate(`/careers/job/${jd?.slug}`, {
                  state: {
                    id: jd?.id,
                  },
                })
              }
              style={{
                cursor: "pointer",
                transition: "transform 0.5s",
              }}
              onMouseOver={(e) => {
                e.target.style.transform = "scale(1.03)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "scale(1)";
              }}
            >
              {truncatedTitle}
              {/* {jd?.jobTitle || jd?.job_title} */}
            </h4>
            <div
              className="truncate"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                whiteSpace: "pre-wrap", // This ensures the text wraps onto the next line
                minHeight: "3em", // Adjust this based on the font-size to fit two lines
                maxHeight: "3em", // Same as minHeight to lock the height to two lines
              }}
              dangerouslySetInnerHTML={{
                __html: truncatedDescription,
              }}
            />
          </div>
          <div
            style={{
              color: wishlisted.includes(jd?.id) ? "#5f35ae" : "#ebdff8",
              cursor: "pointer",
            }}
            onClick={handleWishlistToggle}
          >
            <BookmarkIcon />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-end">
          <div>
            <div className="d-flex align-items-center   ">
              <Chip
                size="sm"
                color="neutral"
                variant="soft"
                className="rounded-2"
              >
                {jd?.job_location}
              </Chip>
            </div>
            <p>
              <b className="bi bi-clock text-warning">
                {" "}
                &nbsp;{jd?.empType || jd?.employment_type[0]}
              </b>
            </p>
            {/* &nbsp;&nbsp;&nbsp; */}
            <p>
              <b className="bi bi-currency-rupee text-info">
                {/* &nbsp;{jd?.salary || jd?.salary_range} */}
                &nbsp;{truncatedSalary}
              </b>
            </p>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-center ">
              <div className="fs-11 fw-bold">{formattedDate} </div>
            </div>
            <div>
              <Button
                variant="soft"
                color="info"
                disabled={isApplied == true ? true : false}
                onClick={() => handleApplied(jd?.id, jd?.slug)}
              // to="/careers/apply"
              // className="btn theme-btn bg-light text-dark rounded border me-4 font-weight-bold "
              >
                {isApplied ? "Applied" : "Apply Now"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCard;
