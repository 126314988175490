import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Avatar, Box, List, Tab, Tabs } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ChatIcon from "@mui/icons-material/Chat";
import styled from "styled-components";
import { useGlobalContext } from "global/context";

const SidebarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: calc(100% - 50%);
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    height: calc(100% - 50%);
  }

  .chat-box {
    flex-grow: 1;
    overflow-y: auto;
    height: 460px;

    @media (max-width: 768px) {
      height: calc(100% - 20%);
    }

    @media (max-width: 600px) {
      height: calc(100% - 20%);
    }

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for webkit browsers */
    }
  }

  .user-list {
    flex-grow: 1;
    overflow-y: auto;
    height: 400px; /* Default height for user list */

    @media (max-width: 768px) {
      height: calc(500px - 10px);
    }

    @media (max-width: 600px) {
      height: calc(600px - 10px);
    }
  }
`;
const Sidebar = ({ sender = [], notification, pd, onlineUsers, roomData, setRoomData, setAllMsg, onUserClick }) => {
  const { userData, api } = useGlobalContext();
  const [value, setValue] = useState(0);
  const [connections, setConnections] = useState([]);

  const handleUserClick = (user) => {
    console.log('User clicked:', user);  // Log to check if user is clicked
    onUserClick(user);  // Call the parent function to show chat
  };

  const handleRooms = (user) => {
    console.log("Fetching messages for room: ", user.id);
    console.log("Fetching messages for room: ", userData.id);

    const roomId = user.id + userData.id;
    console.log("Fetching messages for room: ", roomId);

    api.get(`app/message/allmsg/${roomId}`)
      .then((res) => {
        console.log("Fetched messages: ", res.data);
        setRoomData(roomId);  // Set the clicked user as the roomData
        setAllMsg(res.data);
      })
      .catch((error) => {
        console.error("Error fetching messages for room:", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getConnections = async () => {
    try {
      const res = await api.get(`/app/connections?userId=${userData?.id}`);
      if (res?.status === 200) {
        setConnections(res?.data?.connections);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      getConnections();
    }
  }, [userData]);

  return (
    <SidebarContainer>
      <div className='detailed-view p-3 m-1 shadow-sm rounded-3 border border-success rounded'>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Header pd={pd} />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Chat/User tabs"
            variant="fullWidth"
          >
            <Tab icon={<ChatIcon fontSize="small" />} iconPosition="start" label="Chat List" sx={{ minHeight: "inherit", color: '#22c55e' }} />
            <Tab icon={<PersonIcon fontSize="small" />} iconPosition="start" label="User List" sx={{ minHeight: "inherit", color: '#22c55e' }} />
          </Tabs>

          {/* Chat List */}
          {value === 0 && (
            <div className='chat-box'>
              {sender.length === 0 ? (
                <p>No chats available.</p>
              ) : (
                <List>
                  {sender.map((user) => (
                    <div key={user.id} className='container d-flex flex-wrap' onClick={() => handleUserClick(user)}>
                      <div className='notification-list'>
                        <div
                          className='d-flex justify-content-between align-items-center p-3 hover-s shadow-sm rounded-3 mb-3'
                          onClick={() => handleRooms(user)}
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            height: '80px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <div className='d-flex'>
                            <Avatar
                              alt={user.name}
                              src={user.avatar_url}
                              className="mr-3"
                              sx={{ width: "60px", height: "60px" }}
                            />
                            <div style={{ lineHeight: "20px" }}>
                              <p className='fs-14'>{user.first_name + ' ' + user.last_name}</p>
                              <p className='fs-11'>{user.email}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </List>
              )}
            </div>
          )}

          {/* User List */}
          {value === 1 && (
            <div className='user-list'>
              {/* Add your user connections display here */}
              <div>Show user connections here</div>
            </div>
          )}
        </Box>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
