import { Avatar, Box, Button, Chip, Modal, ModalDialog } from "@mui/joy";
import CareersDashboard from "../CareersDashboard"
import {
  Menu, MenuItem,
  IconButton,
  SwipeableDrawer,
  // SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGlobalContext } from "global/context";
import { useEffect } from "react";
import FastForwardIcon from "@mui/icons-material/FastForward";
import JobDetails from "./JobDetails";
import CoverLaterDetail from "./CoverLaterDetail";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function EmployeeDashboard() {
  const [ismobile, setIsmobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsmobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openApplication, setopenApplication] = useState(false);
  const { api, userData } = useGlobalContext();
  const [appliedJobs, setAppliedJobs] = useState([]);

  const getAppliedJobs = async () => {
    api
      .get(`/app/jobs/applied/${userData?.id}`)
      .then((res) => {
        // console.log("Applied Jobs", res);
        setAppliedJobs(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAppliedJobs();
  }, []);

  return (
    <div className="mb-4">
      <div>
        <div className="d-flex justify-content-between align-items-center  mb-3 ">
          <h3 className="font-weight-bold"> Applied Jobs </h3>
          <Link to="/careers">
            <Button variant="outlined" color="info">
              Apply Another Job
            </Button>
          </Link>
        </div>
      </div>
      <div className="job-table">
        {/* Desktop View */}
        <TableContainer component={Paper} className="rounded-4 desktop-view">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "rgb(25 244 116/17%)" }}>
              <TableRow>
                <TableCell><h6 className="font-weight-bold" style={{ color: '#ff7f50' }} >Apply For</h6></TableCell>
                <TableCell align="center"><h6 className="font-weight-bold" style={{ color: '#6a5acd' }}>Employment Type</h6></TableCell>
                <TableCell align="center"><h6 className="font-weight-bold" style={{ color: '#32cd32' }}>Status</h6></TableCell>
                <TableCell align="start"><h6 className="font-weight-bold" style={{ color: '#ff4500' }}>Apply Date</h6></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appliedJobs.map((item, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <div className="d-flex align-items-center">
                      <Avatar alt={item?.job_descriptions?.job_title} src={item?.job_descriptions?.institutes?.logo} sx={{ width: "40px", height: "40px" }} />
                      <div className="ml-2 fs-15 font-weight-bold">{item?.job_descriptions?.job_title}</div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Chip variant="soft" color="neutral" className="font-weight-bold" style={{ color: "#5f35ae" }} size="sm">
                      {item?.job_descriptions?.employment_type[0]}
                    </Chip>
                  </TableCell>
                  <TableCell align="center">
                    <Chip variant="soft" color={item?.status === "Interview" ? "success" : item?.status === "Rejected" ? "danger" : "success"} className="fw-bold text-capitalize">
                      {item?.status}
                    </Chip>
                  </TableCell>
                  <TableCell align="center"><p className="font-weight-bold">{moment(item?.createdAt).format("ll")}</p></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Mobile View */}

        <div className="mobile-view" style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ExpandMoreIcon />}
            onClick={handleClick}
            style={{ color: '#32cd32' }}
          >
            View Job Applications
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: '80vh',
                width: '100%',
                marginTop: '10px',
              },
            }}
            MenuListProps={{
              style: {
                width: '100%',
                overflowY: 'auto',
              },
            }}
          >
            <MenuItem disabled>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex justify-content-between w-100">
                  <div className="fw-bold" style={{ color: '#ff7f50' }}>Apply For</div>
                  <div className="fw-bold text-center" style={{ color: '#6a5acd' }}>Employment Type</div>
                  <div className="fw-bold text-center" style={{ color: '#32cd32' }}>Status</div>
                  <div className="fw-bold text-start" style={{ color: '#ff4500' }}>Apply Date</div>
                </div>

              </div>
            </MenuItem>
            {appliedJobs.map((item, index) => (
              <MenuItem key={index} onClick={handleClose} style={{ display: 'block', whiteSpace: 'normal' }}>
                <div className="d-flex align-items-center">
                  <Avatar alt={item?.job_descriptions?.job_title} src={item?.job_descriptions?.institutes?.logo} sx={{ width: "40px", height: "40px" }} />
                  <div className="ml-2">
                    <p className="font-weight-bold">{item?.job_descriptions?.job_title}</p>
                    <p>{item?.job_descriptions?.employment_type[0]}</p>
                    <p>{moment(item?.createdAt).format("ll")}</p>
                    <Chip variant="soft" color={item?.status === "Interview" ? "success" : item?.status === "Rejected" ? "danger" : "success"} className="fw-bold text-capitalize">
                      {item?.status}
                    </Chip>
                  </div>
                </div>
              </MenuItem>
            ))}
          </Menu>
        </div>

        {ismobile ? (
          <div className="careers-dashboard-wrapper" style={{ marginTop: Boolean(anchorEl) ? '30%' : '0px' }}>
            <CareersDashboard />
          </div>
        ) : (
          <h4></h4>
        )}

        <style>{`
  @media (max-width: 768px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
    .careers-dashboard-wrapper {
      transition: margin-top 0.3s ease-in-out; // Smooth transition for better UX
    }
  }
  @media (min-width: 769px) {
    .desktop-view {
      display: table;
    }
    .mobile-view {
      display: none;
    }
  }
`}</style>

      </div>

    </div>

  );
}

export default EmployeeDashboard;