import { useGlobalContext } from "global/context";
import { Popup } from "layout/Popup";
import React, { createContext, useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export const AdminContext = createContext();
export const AdminProvider = ({ children }) => {
  const { api } = useGlobalContext();
  const navigate=useNavigate()
  const [admins, setAdmins] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAdmins();
  }, []);
  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/superadmin/createadmins`);
      setAdmins(response?.data?.result);
      setIsLoading(false);
    } catch (error) {
      if(error.response.status === 409){
        Popup('error','Access denied')
        navigate('/')
        return
      }
      console.error("Error fetching admin:", error);
    }
  };

  const updatePrevileges = async (adminData) => {
    setIsLoading(true);
    try {
      await api.put(`/superadmin/createadmins/update/${adminData.id}`, adminData);
      fetchAdmins();
      toast.success("Admin updated successfully");
      setCurrentAdmin(adminData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating admin:", error);
      toast.error("Error updating previleges");
    }
  };
  const updateAdmin = async (adminData) => {
    try {
      await api.put(`/superadmin/createadmins/edit${adminData.id}`, adminData);
      fetchAdmins();
      toast.success("Admin updated successfully");
      setCurrentAdmin(adminData);
    } catch (error) {
      console.error("Error updating admin:", error);
    }
  };
  const removeAdmin = async (id) => {
    setIsLoading(true);
    try {
      await api.delete(`/superadmin/createadmins/delete/${id}`);
      fetchAdmins();
      toast.success("Admin removed successfully");
      setCurrentAdmin(null);
      setIsLoading(false);
    } catch (error) {
      console.error("Error removing admin:", error);
    }
  };

  return (
    <>
      <AdminContext.Provider
        value={{
          admins,
          updatePrevileges,
          updateAdmin,
          removeAdmin,
          currentAdmin,
          setCurrentAdmin,
          fetchAdmins,
          isLoading,
          setIsLoading,
        }}
      >
        {children}
      </AdminContext.Provider>
    </>
  );
};
