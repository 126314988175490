import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Avatar,
} from "@mui/material";
import { useGlobalContext } from "global/context";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const fontFamily = "'Poppins', sans-serif";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  tableLayout: "fixed",
  borderRadius: "8px",
  width:"100%",
  marginInline:"auto",
  boxShadow:
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  marginTop: "24px",
  fontFamily: fontFamily,
  scrollbarWidth: "none",
  '@media (min-width: 600px)': {
    width: "70%",
    marginInline:"auto",
    boxShadow:
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#343a40",
  fontFamily: fontFamily,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: "all 0.3s ease",
  fontWeight: "semi-bold",
  fontFamily: fontFamily,
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette?.action?.hover,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "1rem",
  wordBreak: "break-word",
  fontFamily: fontFamily,
  textAlign: "center",
  padding: { xs: "0px", md: "8px" },
  "@media (max-width: 600px)": {
    fontSize: "0.5rem",
  },
}));

const StyledLoader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "24px",
}));

const InstituteData = () => {
  const { api } = useGlobalContext();
  const [institutes, setInstitutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentInstitute, setCurrentInstitute] = useState(null);
  const [formState, setFormState] = useState({
    id: "",
    name: "",
    location: "",
    status: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [instituteToDelete, setInstituteToDelete] = useState(null);
  // const isMobile = useMediaQuery(theme => theme?.breakpoints?.down('sm'));

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    try {
      const res = await api.get("/admin/institutes");
      console.log(res.data.result);
      setInstitutes(res.data.result);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  const handleOpenDialog = (institute = null) => {
    setCurrentInstitute(institute);
    if (institute) {
      setFormState(institute);
    } else {
      setFormState({ id: "", name: "", location: "", status: "" });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentInstitute(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleDeleteConfirmation = (institute) => {
    setInstituteToDelete(institute);
    setDeleteModalOpen(true);
  };

  const handleFormSubmit = async () => {
    try {
      if (currentInstitute) {
        // Update institute
        await api.put(
          `/admin/institutes/edit/${currentInstitute.id}`,
          formState
        );
        toast.success("Institute updated successfully");
      } else {
        // Add new institute
        await api.post("/admin/institutes", formState);
        toast.success("Institute added successfully");
      }
      fetchInstitutes();
      handleCloseDialog();
    } catch (error) {
      toast.error("Error saving institute");
    }
  };

  const handleDeleteInstitute = async (id) => {
    try {
      await api.delete(`/admin/institutes/delete/${id}`);
      toast.success("Institute deleted successfully");
      fetchInstitutes();
    } catch (error) {
      toast.error("Error deleting institute");
    }
  };

  return (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        color="primary"
        sx={{ fontFamily: fontFamily }}
      >
        Institutes
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ fontFamily: fontFamily ,fontSize: { xs: "0.7rem", md: "0.9rem" ,lg: "1rem" } }}
      >
        Add Institute
      </Button>
      {loading ? (
        <StyledLoader>
          <CircularProgress />
        </StyledLoader>
      ) : (
        <StyledTableContainer component={Paper}>
          <Table aria-label="institutes table" sx={{ tableLayout: "fixed" }}>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    "@media (max-width: 600px)": { fontSize: "0.6rem" },
                  }}

                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    "@media (max-width: 600px)": { fontSize: "0.6rem" },
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    "@media (max-width: 600px)": { fontSize: "0.6rem" },
                  }}
                >
                  Phone
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    "@media (max-width: 600px)": { fontSize: "0.6rem" },
                  }}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "800",
                    "@media (max-width: 600px)": { fontSize: "0.6rem" },
                  }}
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {institutes.map((institute) => (
                <StyledTableRow key={institute.id}>
                  <StyledTableCell
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Link to={`/institutes/institute-profile/${institute.id}`}>
                      <Avatar src={institute.logo} />
                      <p style={{ marginLeft: { xs: "0px", md: "8px" } ,cursor: "pointer",}}>
                        {institute.name}
                      </p>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{institute.email}</StyledTableCell>
                  <StyledTableCell>{institute.mobile}</StyledTableCell>
                  <StyledTableCell>{institute.status}</StyledTableCell>
                  <StyledTableCell sx={{ display: "flex",flexDirection:{ xs:"column",md:"row" },justifyContent:"center" ,alignItems: "center",height: "100%" }}>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenDialog(institute)}
                    >
                      <EditIcon sx={{ color: "#6c757d",fontSize: { xs: "0.8rem", md: "1rem" ,lg: "1.2rem" } }} />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteConfirmation(institute)}
                    >
                      <DeleteIcon sx={{fontSize: { xs: "0.8rem", md: "1rem" ,lg: "1.2rem" } }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {currentInstitute ? "Edit Institute" : "Add Institute"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={formState.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            value={formState.location}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="status"
            label="Status"
            type="text"
            fullWidth
            value={formState.status}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {currentInstitute ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete modal */}
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this institute?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteInstitute(instituteToDelete.id);
              setDeleteModalOpen(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InstituteData;
