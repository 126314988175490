import React from "react";
import Rating from "@mui/material/Rating";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Avatar from "@mui/material/Avatar";
import Radio from "@mui/joy/Radio";
import Button from "@mui/joy/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import RadioGroup from "@mui/joy/RadioGroup";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Apartment from "@mui/icons-material/Apartment";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
// import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import { styled } from "styled-components";
import { Card, CardContent, Chip, Typography } from "@mui/joy";
import { useGlobalContext } from "global/context";
import Certification from "pages/static/Profile/components/Resume/Component/Certification";
import Education from "pages/static/Profile/components/Resume/Component/Education";
import Experience from "pages/static/Profile/components/Resume/Component/Experience";
import { useEffect } from "react";
function CoverLaterDetail({
  Coverlater,
  applyJob,
  setopenApplication,
  openApplication,
  setOpen,
}) {
  const [text, setText] = useState("");
  const [chips, setChips] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, api, OnboardingData } = useGlobalContext();
  // const [data, setData] = useState([]);
  // const { slug } = useParams();
  // const getJobs = async () => {
  //   var endpoint;
  //   if (slug) {
  //     endpoint = `/app/jobs/details/${slug}?userId=${userData?.id}`;
  //   } else {
  //     endpoint = `/app/jobs/post/${location?.state?.id}?userId=${userData?.id}`;
  //   }
  //   try {
  //     const res = await api.get(endpoint);
  //     if (res?.status === 200) {
  //       console.log("Job was successfully Fetched: ", res?.data?.result);
  //       setData(res?.data?.result);
  //     }
  //   } catch (err) {
  //     console.log("Error getting jobs", err);
  //   }
  // };

  // useEffect(() => {
  //   getJobs();
  // }, []);

  const handleClick = () => {
    if (text.trim() !== "") {
      setChips((prevChips) => [...prevChips, text]);
      setText("");
    }
  };

  const handleDelete = (chipToDelete) => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  // for qualification

  const [textQ, setTextQ] = useState(""); //
  const [qualifications, setQualifications] = useState([]);

  const handleClickQ = () => {
    if (textQ.trim() !== "") {
      setQualifications((prevQualifications) => [...prevQualifications, textQ]);
      setTextQ("");
    }
  };

  const handleDeleteQ = (qualificationToDelete) => {
    setQualifications((prevQualifications) =>
      prevQualifications.filter(
        (qualification) => qualification !== qualificationToDelete
      )
    );
  };
  // for certification
  const [textCer, setTextCer] = useState("");
  const [certification, setCertification] = useState([]);

  const handleClickCer = () => {
    if (textCer.trim() !== "") {
      setCertification((prevCertification) => [...prevCertification, textCer]);
      setTextCer("");
    }
  };

  const handleDeleteCer = (certificationToDelete) => {
    setCertification((prevCertification) =>
      prevCertification.filter(
        (certification) => certification !== certificationToDelete
      )
    );
  };
  // For Research Paper

  const [textRes, setTextRes] = useState("");
  const [ResearchPaper, setResearchPaper] = useState([]);

  const handleClickRes = () => {
    if (textRes.trim() !== "") {
      setResearchPaper((prevResearchPaper) => [...prevResearchPaper, textRes]);
      setTextRes("");
    }
  };

  const handleDeleteRes = (ResearchPaperToDelete) => {
    setResearchPaper((prevResearchPaper) =>
      prevResearchPaper.filter(
        (ResearchPaper) => ResearchPaper !== ResearchPaperToDelete
      )
    );
  };
  // Upload Resume

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    // Perform file upload logic here, e.g., using axios or fetch API
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      // Send formData to the server or perform the desired upload action
      console.log("Uploading file...", formData);
    } else {
      console.log("No file selected.");
    }
  };

  const ScrollerStyle = {
    maxHeight: "800px",
    width: "100%",
    overflowY: "scroll",
    justifyContent: "flex-end",
    // marginTop: "140px",
  };
  
  const ScrollHiddenDiv = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollMinibarDiv = styled.div`
  &::-webkit-scrollbar {
    width: 3px;
    background-color: black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(185, 182, 182);
  }
`;

  console.log("datauser", userData);
  return (
    <div>
      <div className="row  scroll-bar-hidden" style={ScrollerStyle}>
        <div className="col  ">
          <h5 className="font-weight-bold mb-2">Application</h5>
          <div className="p-3 d-flex">
            <div className="row row-cols-1 row-cols-lg-2  mb-2">
              <div className="col mb-2 d-flex ">
                Name:
                <p className="font-weight-bold ml-2">
                  {userData?.first_name + " " + userData?.last_name ||
                    userData?.name}{" "}
                </p>
              </div>

              <div className="col mb-2 d-flex ">
                Email:
                <p className="font-weight-bold ml-2">{userData?.email}</p>
              </div>
              <div className="col  mb-2 d-flex">
                Phone Number:
                <p className="font-weight-bold ml-2">{userData?.phone} </p>
              </div>
              <div className="col  mb-2 d-flex">
                Role:
                <p className="font-weight-bold ml-2">{userData?.role} </p>
              </div>
            </div>
          </div>
          <div className="p-3 mb-2">
            <p>Your Cover Letter</p>  
            <div className="d-flex align-items-baseline justify-content-between">
              <div className="w-100">
                <Card variant="soft">
                  <CardContent>
                    <Typography>{Coverlater}</Typography>
                  </CardContent>
                </Card>
              </div>{" "}
              &nbsp;
              <div>
                {/* <Button size="sm" variant="plain" color="info">
                  <EditOutlinedIcon onClick={() =>{
                    setopenApplication(false);
                  }} />
                </Button> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center p-3" style={{ gap: '20px' }}>

<div className="w-100 d-flex justify-content-between">
  <Button 
    variant="soft" 
    color="info" 
    className="button-custom me-2"
    onClick={() => navigate('/resume-maker')}
  >
    <i className="bi bi-filetype-pdf"></i> &nbsp; Resume
  </Button>

  <Button 
    variant="soft" 
    color="info" 
    className="button-custom ms-2"  
    onClick={() => navigate('/setting/profile')}
  >
    <i className="bi bi-cloud-arrow-down"></i> &nbsp; Profile
  </Button>
</div>

<div className="w-100 d-flex justify-content-between">
  <Button 
    variant="soft" 
    color="info" 
    className="button-custom me-2"
    onClick={() => setopenApplication(false)}
  >
    <i className="bi bi-filetype-pdf"></i> &nbsp; Cancel
  </Button>

  <Button
    component="a"
    variant="soft"
    color="info"
    className="button-custom ms-2"
    onClick={() => {
      applyJob();
      setOpen(false);
      setopenApplication(false);
    }}
  >
    Apply
  </Button>
</div>

</div>
        </div>
      </div>
    </div>
  );
}

export default CoverLaterDetail;
