import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Add, Person, School, ListAlt } from "@mui/icons-material";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";

const fontFamily = "'Quicksand', sans-serif";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme?.palette?.text?.secondary,
  fontFamily: fontFamily,
  height:150
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontFamily: fontFamily,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  fontFamily: fontFamily,
}));

const SupadminDashboard = () => {
  const { api } = useGlobalContext();
  const navigate=useNavigate()
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalInstitutes: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchDashboardData();
  }, [api]);

  const fetchDashboardData = async () => {
    try {
      const [usersRes, institutesRes] = await Promise.all([
        api.get("/superadmin/dashboard/totalusers"),
        api.get("/superadmin/dashboard/totalinstitute"),
      ]);
      setStats({
        totalUsers: usersRes.data.total_count,
        totalInstitutes: institutesRes.data.total_count,
      });
    } catch (error) {
      if(error.response.status === 409){
        Popup('error',"Access denied")
        navigate("/")
        return
      }
      toast.error("Error fetching dashboard data");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ fontFamily: fontFamily,fontWeight:600 }}>
        Super Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4">{stats.totalUsers}</Typography>
            <Button variant="contained" color="primary" startIcon={<Person />}>
              Manage Users
            </Button>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Typography variant="h6">Total Institutes</Typography>
            <Typography variant="h4">{stats.totalInstitutes}</Typography>
            <StyledButton variant="contained" color="primary" startIcon={<School />}>
              Manage Institutes
            </StyledButton>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6">Quick Actions</Typography>
            <StyledButton variant="contained" color="primary" startIcon={<Add />}  onClick={handleClick}>
              Add User
            </StyledButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
            >
              <Link to="/superadmin/subAdmins">
                <MenuItem>Admins</MenuItem>
              </Link>
              <MenuItem>Students</MenuItem>
              <MenuItem>Institutes</MenuItem>
              <MenuItem>Professionals</MenuItem>
            </Menu>
            <StyledButton variant="contained" color="primary" startIcon={<Add />}>
              Add Institute
            </StyledButton>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupadminDashboard;
