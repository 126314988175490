import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import BarChart from "./Charts/Barcharts";
import WorkIcon from "@mui/icons-material/Work";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import { CircularProgress, IconButton } from "@mui/joy";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";
import { Person } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "layout/Popup";
const fontFamily = "'poppins',sans-serif";

const StateCard = ({ title, value, percentage, icon }) => {
  const navigate = useNavigate();
  const handleOptions = () => {
    switch (title) {
      case "Total Users":
        navigate("/admin/users");
        break;
      case "Total Institutes":
        navigate("/admin/instituteData");
        break;
      case "Total Jobs":
        navigate("/admin/jobs");
        break;
    }
  };
  return (
    <Card
      style={{
        width: "18rem",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box>
        <Box display="inline-flex" alignItems="center" gap={2}>
          <IconButton onClick={handleOptions}>{icon}</IconButton>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            fontWeight="bold"
            fontFamily={fontFamily}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          color="GrayText"
          marginLeft={1}
          fontFamily={fontFamily}
        >
          {value}
        </Typography>
      </Box>
      <Box display="inline-flex">
        <CircularProgress determinate size="lg" value={percentage}>
          <Typography fontSize={13}>{percentage}%</Typography>
        </CircularProgress>
      </Box>
    </Card>
  );
};

const Dashboard = () => {
  const { api } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalInstitutes: 0,
    totalJobs: 0,
  });

  const fetchDashboardData = async () => {
    setloading(true);
    try {
      const usersCount = await api.get("/admin/dashboard/totalusers");
      const institutesCounts = await api.get("/admin/dashboard/totalinstitute");
      const jobsCount = await api.get("/admin/dashboard/totaljobs");
      setStats({
        totalUsers: usersCount.data.total_count,
        totalInstitutes: institutesCounts.data.total_count,
        totalJobs: jobsCount.data.total_count,
      });
    } catch (error) {
      if (error.response.status === 409) {
        Popup("error", "Access denied");
        navigate("/");
        return;
      }
      toast.error("Error fetching dashboard data");
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container maxWidth="lg">
      <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
        <Row className="p-2">
          <Col>
            <StateCard
              title="Total Users"
              value={stats.totalUsers}
              percentage={30}
              icon={<Person />}
            />
          </Col>
          <Col>
            <StateCard
              title="Total Institutes"
              value={stats.totalInstitutes}
              percentage={30}
              icon={<BusinessCenterIcon />}
            />
          </Col>
          <Col>
            <StateCard
              title="Total Jobs"
              value={stats.totalJobs}
              percentage={30}
              icon={<WorkIcon />}
            />
          </Col>
        </Row>
        <BarChart />
      </Paper>
    </Container>
  );
};

export default Dashboard;
