import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useGlobalContext } from "global/context";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  CircularProgress,
  CardActions,
  Avatar,
} from "@mui/material";
import {
  Email,
  Facebook,
  LinkedIn,
  LocationOn,
  Phone,
  StarBorder,
  Twitter,
} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import logo from "../assets/logo.png";
import { useParams } from "react-router-dom";
import { CardOverflow } from "@mui/joy";
const fontFamily = "'lexend', sans-serif";
const Institutepoll = () => {
  const { locate } = useParams();
  const { api } = useGlobalContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const userId = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    fetchNearbyInstitutes();
  }, [locate]);

  const fetchNearbyInstitutes = useCallback(async () => {
    try {
      const response = await api.get(`/app/user/institutes/nearby/${locate}`);
      console.log(response?.data);
      setData(response?.data?.institutes);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("no institutes found in your area");
      }
      console.error("Error fetching nearby institutes:", error);
    }
    setLoading(false);
  }, []);

  const vote = async (itemId, rating) => {
    try {
      await api.post(`/app/user/institutes/vote/${itemId}`, {
        rating,
        user_id: userId,
      });
      fetchNearbyInstitutes();
      toast.success("Successfully voted!");
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("You have already voted for this institute");
      }
      if (error.response.status === 404) {
        toast.error("This institute does not exist");
      }
      if (error.response.status === 500) {
        toast.error("Something went wrong");
      }
    }
  };
  const getColor = (index, rating) => {
    if (index < rating) {
      switch (rating) {
        case 1:
          return "#ef476f";
        case 2:
          return "#ee9b00";
        case 3:
          return "#073b4c";
        case 4:
          return "#118ab2";
        case 5:
          return "#06d6a0";
        default:
          return "inherit";
      }
    }
    return "secondary";
  };

  const renderStars = (itemId, rating) => {
    return Array.from({ length: 5 }, (_, i) => (
      <IconButton
        key={i + 1}
        onClick={() => vote(itemId, i + 1)}
        style={{ color: getColor(i, rating) }}
      >
        {i < rating ? <StarIcon /> : <StarBorder />}
      </IconButton>
    ));
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Please Wait...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container className="mt-4">
      <Box
        sx={{
          background: "linear-gradient(45deg, #472fcb, #00a4a5)",
          p: 2,
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: "900",color:"#fcfcfc" }}
        >
          Institutes Nearby Your Area
        </Typography>
        <Typography
          variant="span"
          sx={{ fontWeight: "500", letterSpacing: "1px", lineHeight: "1.8",color:"#CCC4C4" }}
          mb={4}
        >
          <span className="fw-bold fs-24">Welcome!</span> This page simplifies
          your search for the best institutes in your area. Whether you're a
          student seeking educational opportunities or a professional looking to
          enhance your skills, our platform offers a comprehensive listing of
          nearby institutions tailored to your needs. Finding the right
          institution can be a daunting task, but we've made it easier for you.
          Start your journey towards success with us!
        </Typography>
      </Box>
      {data?.length === 0 || data === undefined ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "50vh",
          }}
        >
          <Typography variant="h6">
            No institutes Found in your area.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {data?.map((institute, index) => (
            <Grid
              item
              key={institute.id}
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  width: { xs: "65%", md: "100%" },
                  maxWidth: 300,
                  borderRadius: "15px",
                  background: "#e0e0e0",
                  boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  overflow: "hidden",
                  "&:hover": {
                    boxShadow: "0 15px 30px rgba(0,0,0,0.2)",
                  },
                  ".MuiCardActions-root": {
                    padding: 0,
                  },
                  "&:hover .MuiCardActions-root": {
                    height: "65px",
                    borderTop: "1px solid #e0e0e0",
                  },
                  position: "relative",
                  margin: 1,
                  paddingBlock: 1,
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Avatar
                      sx={{
                        width: { xs: 50, md: 80 },
                        height: { xs: 50, md: 80 },
                        marginBottom: 1,
                      }}
                      src={institute?.logo}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 15,
                        color: "#272932",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                        fontWeight: 700,
                        fontFamily: fontFamily,
                      }}
                    >
                      {institute.name &&
                      !institute.name.includes("institute" || "institutes")
                        ? institute.name.concat(" institute")
                        : institute.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Phone sx={{ color: "#969297", fontSize: 14, mr: 0.5 }} />
                      <Typography sx={{ fontSize: 12, color: "#272932" }}>
                        {institute.mobile}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <Email sx={{ color: "#969297", fontSize: 14, mr: 0.5 }} />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "#272932",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                          fontSize: 12,
                          fontFamily: fontFamily,
                        }}
                      >
                        {institute.email}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <StarIcon
                        sx={{ color: "#969297", fontSize: 14, mr: 0.5 }}
                      />
                      <Typography sx={{ fontSize: 12, color: "#272932" }}>
                        {institute?.rating?.toFixed(1)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <LocationOn
                        sx={{ color: "#969297", fontSize: 14, mr: 0.5 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "#272932",
                          fontFamily: fontFamily,
                        }}
                      >
                        {institute.state}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        sx={{ color: "#1877F2", fontSize: 14 }}
                        href={`${institute?.facebook}`}
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <Facebook />
                      </IconButton>
                      <IconButton
                        sx={{ color: "#1DA1F2", fontSize: 14 }}
                        href={`${institute?.twitter}`}
                        target="_blank"
                        aria-label="Twitter"
                      >
                        <Twitter />
                      </IconButton>
                      <IconButton
                        sx={{ color: "#0077B5", fontSize: 14 }}
                        href={`${institute.linkedin}`}
                        target="_blank"
                        aria-label="LinkedIn"
                      >
                        <LinkedIn />
                      </IconButton>
                      <IconButton
                        sx={{ color: "#0073e6", fontSize: 14 }}
                        href={`mailto:${institute.email}`}
                        target="_blank"
                        aria-label="Email"
                      >
                        <Email />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderTop: "1px solid transparent",
                    height: 0,
                    overflow: "hidden",
                    transition: "height 0.3s",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      fontWeight: 700,
                      color: "#272932",
                      textTransform: "uppercase",
                      fontSize: 12,
                      fontFamily: fontFamily,
                    }}
                  >
                    Rate the Institute
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {renderStars(institute.id, Math.floor(institute.rating))}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Institutepoll;
