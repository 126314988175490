import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "code"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "code",
];

export default function TextEditor({textEditorValue, setTextEditorValue}) {
  return (
    <ReactQuill
      style={{ height: 400 }}
      theme="snow"
      value={textEditorValue}
      onChange={setTextEditorValue}
      modules={modules}
      formats={formats}
    />
  );
}
