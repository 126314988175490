const msgData = [
    {
        id: 0,
        avatar_url: "",
        first_name: "Shivam Kumar",
        message: "Hi, How are you?",
        sender_id: 2,
        reciever_id: 1,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 1,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 2,
        avatar_url: "",
        first_name: "Shivam Kumar",
        message: "Hi, How are you?",
        sender_id: 2,
        reciever_id: 1,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 3,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 4,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 5,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 6,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
    {
        id: 7,
        avatar_url: "",
        first_name: "Shivam Kumar",
        message: "Hi, How are you?",
        sender_id: 2,
        reciever_id: 1,
        createdAt: "2023-05-26T09:15:05.000Z"
    }, {
        id: 8,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    }, {
        id: 9,
        avatar_url: "",
        first_name: "Santosh Kumar",
        message: "Hi, How are you?",
        sender_id: 1,
        reciever_id: 2,
        createdAt: "2023-05-26T09:15:05.000Z"
    },
];

module.exports = {msgData}