import { useGlobalContext } from "global/context";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = ({ Component,allowedRoles }) => {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("isLogin");
  const { token, userData } = useGlobalContext();
  useEffect(() => {
    if (!isLogin || !token) {
      navigate("/auth/login");
    }
  }, []);
//   useEffect(() => {
//     // Role-based access control
//     if (userData && !allowedRoles.includes(userData?.role)) {
//       // Redirect to access denied page or home page for unauthorized roles
//       navigate("/");
//     }
//   }, [userData, allowedRoles, navigate]);

  return <>{isLogin ? <Component /> : null}</>;
};

export default Protected;
