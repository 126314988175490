import { Avatar, Card, CardHeader, IconButton, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useGlobalContext } from "global/context";
import { log } from "util";

const Header = (pd) => {
  const { userData, setAuth } = useGlobalContext();
  console.log("logo :",pd?.pd?.pd?.avatar_url)
  if(userData?.role !== 'institute'){
    console.log("UserProfile : ",pd)
  }else{
    console.log("InstituteProfile : ",pd)
  }
  return (
    <>
  <Card
    sx={{
      borderTopLeftRadius: "16px", // Top left corner rounded
      borderTopRightRadius: "16px", // Top right corner rounded
      color: "primary.contrastText",
      borderBottom: "1px solid #198754",
       backgroundColor: '#22c55e'
    }}
  >
    <CardHeader
      avatar={<Avatar src={pd?.pd?.pd?.avatar_url} />}
      action={
        <IconButton aria-label="settings" sx={{ color: "primary.contrastText" }}>
          <MoreVertIcon />
        </IconButton>
      }
      title={pd?.pd?.pd?.profile?.display_name}
      subheader={
        <Typography variant="caption" color="inherit">
          {userData?.role}
        </Typography>
      }
    />
  </Card>
</>

  );
};

export default Header;
