import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";

const fontFamily = "'Quicksand', sans-serif";

const AllAdmissions = () => {
  const { api, userData } = useGlobalContext();
  const [admissions, setAdmissions] = useState([]);

  useEffect(() => {
    fetchData();
  }, [userData, api]);

  const fetchData = async () => {
    try {
      const res = await api.get(`/v1/getadmission/${userData?.id}`);
      setAdmissions(res.data);
    } catch (err) {
      toast.error("Error fetching data");
    }
  };

  return (
    <Container>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mb: 3,
          background: "linear-gradient(45deg, #472fcb, #00a4a5)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            color="white"
            fontFamily={fontFamily}
            marginTop={1}
          >
            Courses
          </Typography>
          <Link to="/institute/postadmissions" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: 2,
                whiteSpace: "nowrap",
                backgroundColor: "#272932",
                fontFamily: fontFamily,
              }}
            >
              Add Course
            </Button>
          </Link>
        </Box>
      </Paper>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "rgb(133 0 255 / 17%)" }}>
              <TableRow>
                <TableCell><strong>Course Name</strong></TableCell>
                <TableCell><strong>Duration</strong></TableCell>
                <TableCell><strong>Batch Starting From</strong></TableCell>
                <TableCell><strong>Course Duration</strong></TableCell>
                <TableCell><strong>Seats</strong></TableCell>
                <TableCell><strong>Fees</strong></TableCell>
                <TableCell><strong>Last Date of Application</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admissions.map((admission, index) => (
                <TableRow key={index}>
                  <TableCell>{admission?.course_name}</TableCell>
                  <TableCell>{admission?.duration}</TableCell>
                  <TableCell>{new Date(admission?.batch_start).toLocaleDateString()}</TableCell>
                  <TableCell>{admission?.course_duration}</TableCell>
                  <TableCell>{admission?.seats}</TableCell>
                  <TableCell>{admission?.fees}</TableCell>
                  <TableCell>{new Date(admission?.last_date).toLocaleDateString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default AllAdmissions;
