import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Swal from "sweetalert2";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/joy/Typography";
import Select, { ReactSelectProps } from 'react-select'
import {
  Checkbox,
  Divider,
  FormHelperText,
  ModalClose,
  Option,
  Textarea,
} from "@mui/joy";
// import Select, { selectClasses } from "@mui/joy/Select";
import { KeyboardArrowDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormGroup } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useGlobalContext } from "global/context";
import { toast } from "react-hot-toast";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({

  research_name: Yup.string()
    .required("Research Name is required")
    .matches(/^[a-zA-Z\s]*$/, "Name should not contain numbers"),
  description: Yup.string()
    .required("Describe about Your Research."),
  // status: Yup.string()
  //   .required("Status is required"),
  start_date: Yup.date()
    .required("Start date is required")
    .max(new Date(), "Start date must be today or before"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date must be after the start date")
    .max(new Date(), "End date must be today or before"),
  research_url: Yup.string()
    .required("Url is required"),
});

function AddResearch({
  open = false,
  setOpen,
  action,
  edit = false,
  research,
  data,
  getResearch,
}) {
  const [filteredResearch, setFilteredResearch] = useState(research);

  const { userData, api } = useGlobalContext();
  // console.log(data)
  // const validateDates = (values) => {
  //   const errors = {};
  //   if (new Date(values.start_date) >= new Date(values.end_date)) {
  //     errors.start_date = "Issue date must be earlier than expiration date";
  //   }
  //   if (
  //     typeof values.research_name !== "string" ||
  //     !isNaN(values.research_name)
  //   ) {
  //     errors.research_name = "Name should not be number";
  //   }

  //   // if (
  //   //   typeof values.issuing_organization !== "string" ||
  //   //   !isNaN(values.issuing_organization) 
  //   // ) {
  //   //   errors.issuing_organization = "Organization name should not be number";
  //   // }

  //   return errors;
  // };
  const formik = useFormik({
    initialValues: {
      research_name: data?.research_name || "",
      description: data?.description || "",
      status: data?.status || "",
      start_date: data?.start_date || new Date().getDate(),
      end_date: data?.end_date || new Date().getDate(),
      research_url: data?.research_url || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      values = { ...values, user_id: userData?.id };
      console.log("Add research: ", values);
      let notify = toast.loading("Adding Research...");
      try {
        if (edit) {
          const res = await api.put(
            `/app/candidates/research/${data?.id}`,
            values
          );
          if (res?.status === 200) {
            console.log("Successfully Updated");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Saved");
            getResearch()
          }
        } else {
          const res = await api.post(
            `/app/candidates/research/${userData?.id}`,
            values
          );
          if (res?.status === 201) {
            console.log("Successfully Added");
            setOpen(false);
            toast.dismiss(notify);
            toast.success("Research Added");
            formik.resetForm(); // Reset form values
            getResearch();
          }
        }
      } catch (error) {
        console.log(error);
        toast.dismiss(notify);
        toast.error("Something went wrong!");
      }
    },
  });

  const handleDelete = async (id, research_name) => {
    setOpen(false)

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then ( async(result) => {
        if (result.isConfirmed) {
          try {

            const res = await api.delete(`/app/candidates/research/${id}`);
            if (res?.status === 200) {

              toast.success(`${research_name} successfully deleted!`);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              getResearch();
        }
          } catch (error) {
            console.error("Error In Deletion : ",error)
          }
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to delete");
    }
  };

  useEffect(() => {
    setFilteredResearch(research);
  }, [research]);

  const statusOptions = [
    { value: 'failed', label: 'Failed' },
    { value: 'registered', label: 'Registered' },
    { value: 'inprocess', label: 'In Process' },
  ];

  return (
    <>
      <React.Fragment>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            layout="center"

            // sx={{ maxWidth: 500 }}
            // variant="plain"
          >
            <ModalClose />
            <div className="px-3 my-3">
              <Typography
                id="basic-modal-dialog-title"
                component="h2"
                fontWeight={"lg"}
              >
                {edit ? "Edit Research Paper" : "Add new Research Paper"}
              </Typography>
              <Typography
                id="basic-modal-dialog-description"
                textColor="text.tertiary"
                level="body3"
              >
                Share your Research on this profile
              </Typography>
            </div>
            <FormGroup
              onSubmit={formik.handleSubmit}
              className="scroll-bar-hidden"
              sx={{
                overflow: "scroll",
                mx: "calc(-1 * var(--ModalDialog-padding))",
                px: "var(--ModalDialog-padding)",
              }}
              >
              <Stack spacing={2}>
                <div className="row px-3">
                  <FormControl className="col">
                    <FormLabel>Name*</FormLabel>
                    <Input
                      autoFocus
                      required
                      placeholder="Eg - Quantum Computing Algorithms  "
                      name="research_name"
                      value={formik.values.research_name}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                    {formik.errors.research_name && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.research_name &&
                          formik.errors.research_name}
                      </FormHelperText>
                    )}
                  </div>
                  </FormControl>
                </div>
                <FormControl className="row ">
                  <div className="col">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      minRows={3}
                      placeholder="Describe About your Research Paper"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {
                        formik.errors.description && (
                          <FormHelperText error className="text-danger">
                            {formik.touched.description && 
                              formik.errors.description}
                          </FormHelperText>
                        )
                      }
                    </div>
                    {/* <FormHelperText>100 Charecters left</FormHelperText> */}
                  </div>
                </FormControl>
                <div className="col">
                <FormLabel>Status</FormLabel>
              <Select
                name="status"
                options={statusOptions}
                value={statusOptions.find(option => option.value === formik.values.status)}
                onChange={option => formik.setFieldValue("status", option.value)}
                onBlur={formik.handleBlur}
              />
              {formik.errors.status && formik.touched.status && (
                <FormHelperText error>{formik.errors.status}</FormHelperText>
              )}
                </div>
                <div className="row d-flex flex-wrap">
                  <FormControl className="col">
                    <FormLabel>Start date*</FormLabel>
                    <Input
                      type="date"
                      required
                      name="start_date"
                      placeholder="Issued Date"
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                    {formik.errors.start_date && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.start_date && formik.errors.start_date}
                      </FormHelperText>
                    )}
                    </div>
                  </FormControl>
                  <FormControl className="col ">
                    <FormLabel>End date*</FormLabel>
                    <Input
                      type="date"
                      required
                      name="end_date"
                      placeholder="Expiry Date"
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                    {formik.errors.end_date && (
                      <FormHelperText error className="text-danger">
                        {formik.touched.end_date && formik.errors.end_date}
                      </FormHelperText>
                    )}
                    </div>
                  </FormControl>
                </div>
                <div className="row">
                  <FormControl className="col ">
                    <FormLabel>Research URL*</FormLabel>
                    <Input
                      startDecorator={
                        <>
                          <Typography>https:// </Typography>
                        </>
                      }
                      required
                      name="research_url"
                      value={formik.values.research_url}
                      onChange={formik.handleChange}
                      onError={formik.handleError}
                    />
                    <div className="ml-3">
                      {formik.errors.research_url && (
                        <FormHelperText error className="text-danger">
                          {formik.touched.research_url && formik.errors.research_url}
                        </FormHelperText>
                      )}
                    </div>
                  </FormControl>
                </div>
              </Stack>
            </FormGroup>
                <div className="row g-2 d-flex p-3 justify-content-between">
              {/* {edit && ( <Button > Delete </Button> )} */}
              {edit && (
                        <Button onClick={() => handleDelete(data?.id, data?.research_name)}>Delete</Button>
                    )}                   
              <Button
              type="submit"
              onClick={()=>{
                formik.handleSubmit()
                console.log("clicked", formik.errors)
              }}
            >
              {edit ? "Save changes" : "Add Research"}
            </Button>
                </div>
          </ModalDialog>
        </Modal>
      </React.Fragment>
    </>
  );
}

export default AddResearch;
