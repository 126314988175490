import React from 'react'
import { useRef } from 'react';
import Header from './Component/Header';
import { useState } from 'react';
import Summary from './Component/Summary';
import Experience from './Component/Experience';
import Education from './Component/Education';
import Certification from './Component/Certification';
import EkSathiRefrence from './Component/EkSathiRefrence';
import Social from './Component/Social';
import Skills from 'pages/user/setting/components/Skills';
import { useEffect } from 'react';

function ResumeContainer({profile,color ,edit ,editExp ,contentRef,ComponentPdf, editEdu , editCert , qualifications}) {
    // const contentRef = useRef();
    // const [contentHeight, setContentHeight] = useState(0);
    // const ComponentPdf = useRef();
    // useEffect(() => {
    //     setContentHeight(contentRef.current.clientHeight);
    //   }, []);
    const [sections, setSections] = useState({
        summary: true,
        experience: true,
        education: true,
        certification: true,
        projects: true,
        socialLinks: true,
        skills: true,
        achievement: true,
      });
  return (
    <div>
       <div style={{ width: "98%" }}>
              <div
                className=" shadow rounded-2"
                ref={contentRef}
                style={{ marginTop: "25px", marginBottom: "30px" }}
              >
                <div className=" " ref={ComponentPdf} style={{ width: "100%" }}>
                  <Header
                    profile={profile}
                    profileData={profile?.profile}
                    color={color}
                  />

                  <div className="container">
                    <div className="row  ">
                      <div className="col ">
                        <div className="">
                          <div>
                            {" "}
                            {sections.summary && (
                              <Summary showSummary={edit}  profile={profile} />
                            )}{" "}
                          </div>
                          <div>
                            {sections.experience && (
                              <Experience
                                profile={profile?.id}
                                EditExperince={editExp}
                              />
                            )}
                          </div>
                          <div>
                            {sections.education && (
                              <Education
                                profile={profile?.id}
                                EditEducation={editEdu}
                              />
                            )}
                          </div>
                          <div className="">
                            {sections.certification && (
                              <Certification
                                profile={profile?.id}
                                EditCertification={editCert}
                              />
                            )}
                          </div>
                          {/* <div>
                            {sections.projects && (
                              <Projects EditProject={editpro} />
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="col-4  border border-bottom-0 border-right-0 border-top-0">
                        <div>{<EkSathiRefrence profile={profile} />}</div>
                        <div>
                          {sections.socialLinks && (
                            <Social
                              URL={profile?.profile?.social_links?.facebook}
                            />
                          )}
                        </div>

                        <div className="mt-2">
                          {sections.skills && (
                            <Skills
                              skillsData={qualifications}
                              profile={profile?.id}
                            />
                          )}
                        </div>
                        <div className="mt-2">
                          {/* {sections.achievement && (
                            <Achievement EditAchivement={editAchiev} />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default ResumeContainer
